
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useRouteMatch,
    useParams
} from "react-router-dom";

export default function SettingsSidebar(props) {

    return (
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    {3 === 3 ? null :
                    <>
                    <h4 className="subheader">Subscription</h4>
                    {!props.activeSub ?
                        <ul class="list-unstyled space-y-1">
                            <Link to="/settings/subscription">
                                <span className="badge bg-red text-red-fg"><li>Subscription Plan</li></span> <br />
                            </Link>
                        </ul>
                        :
                        <ul class="list-unstyled space-y-1">
                            <Link to="/settings/subscription">
                                <b><li>Subscription Plan</li></b>
                            </Link>
                        </ul>
                    }
                    </>
                    }
                    <h4 className="subheader">User Settings</h4>
                    <ul class="list-unstyled space-y-1">
                        <Link to="/settings/change-password">
                            <li>Change Password</li>
                        </Link>
                    </ul>
                    <h4 className="subheader">Company Settings</h4>
                    <ul class="list-unstyled space-y-1">
                        <Link to="/settings/company-information">
                            <li>Company Information</li>
                        </Link>
                        <Link to="/settings/manage-users">
                            <li>Manage Users</li>
                        </Link>

                        {!props.company_contract ?
                            <Link to="/settings/contract-settings">
                                <span class="badge bg-red text-red-fg"><li>Carrier Contract Settings</li></span> <br />
                            </Link>
                            :
                            <Link to="/settings/contract-settings">
                                <li>Contract Settings</li>
                            </Link>
                        }
                        {!props.company_tc ?
                            <Link to="/settings/confirmation-settings">
                                <span className="badge bg-red text-red-fg"><li>Rate Confirmation Settings </li></span><br />
                            </Link>

                            : <Link to="/settings/confirmation-settings">
                                <li>Rate Confirmation Settings</li>
                            </Link>
                        }

                    </ul>
                    <h4 className="subheader">Load Settings</h4>
                    <ul class="list-unstyled space-y-1">
                        <Link to="/settings/load-number-settings">
                            <li>Load Number Settings</li>
                        </Link>

                    </ul>
                    {3 === 3 ?
                        null
                        :
                        <>
                            <h4 className="subheader">Upload Data</h4>
                            <ul class="list-unstyled space-y-1">
                                <Link to="/settings/upload-customers">
                                    <li>Upload Customers</li>
                                </Link>

                                {props.type == "carrier" ? null :
                                    <Link to="/settings/upload-carriers">
                                        <li>Upload Carriers</li>
                                    </Link>
                                }
                                <Link to="/settings/upload-locations">
                                    <li>Upload Locations</li>
                                </Link>
                            </ul>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}