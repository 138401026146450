import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react"
import StepZilla from 'react-stepzilla'
import SignUpCompany from "./SignUpCompany"
import SignUpUser from "./SignUpUser"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
  } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import SignUpCompanyType from "./SignUpCompanyType";
import SignUpConpanyInformation from "./SignUpCompanyInformation";
import LoadingModal from "../Modals/LoadingModal";


export default function SignUpWizard(props) {
    const [state, setState] = useState({})
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);

    const handleCompanyInformation = (companyInformation) => {
        setState({ ...state, companyName: companyInformation.carrier_name,
                             companyMC: companyInformation.carrier_mc,
                             companyAddress: companyInformation.billing_address,
                             phoneNumber: companyInformation.carrier_phone,
                             existing: companyInformation.existing })
    }
    const handleCompanyName = (companyName) => {
        setState({ ...state, companyName: companyName })
    }
    const handleCompanyDOT = (companyDOT) => {
        setState({ ...state, companyDOT: companyDOT })
    }
    const handleCompanyMC = (companyMC) => {
        setState({ ...state, companyMC: companyMC })
    }
    const handleEmail = (userEmail) => {
        setState({ ...state, email: userEmail })
    }
    const handlePassword = (userPassword) => {
        setState({ ...state, password: userPassword })
    }
    const handlePhoneNumber = (phoneNumber) => {
        setState({ ...state, phoneNumber: phoneNumber })
    }
    const handleAddress = (streetAddress) => {
        setState({ ...state, companyAddress: streetAddress })
    }
    const handleFirstName = (userFirstName) => {
        setState({ ...state, firstName: userFirstName })
    }
    const handleLastName = (userLastName) => {
        setState({ ...state, lastName: userLastName })
    }
    const handleCompanyType = (companyType) => {
        setState({...state, type: companyType})
    }
    const handleDOTError = (Error) => {
        setError(Error.error)
    }
    const handleLoading = (status) => {
        setLoading(status)
        setShow(status)
    }

    const steps = [
       
        {
            name: 'Company Sign Up', component: <SignUpCompany
                state={state}
                loading={loading}
                parentLoading={handleLoading}
                parentCompanyDOT={handleCompanyDOT}
                parentDOTError={handleDOTError}
                error={error}
            />
        },
        {
            name: 'Company Information', component: <SignUpConpanyInformation
                state={state}
                loading={loading}
                parentLoading={handleLoading}
                parentCompanyInformation={handleCompanyInformation}
                parentPhoneNumber={handlePhoneNumber}
                parentAddress={handleAddress}
                parentCompanyName={handleCompanyName}
                parentCompanyMC={handleCompanyMC}
                parentDOTError={handleDOTError}
            />
        },
        {
            name: 'Admin User Sign Up', component: <SignUpUser
                state={state}
                loading={loading}
                parentLoading={handleLoading}
                parentEmail={handleEmail}
                parentPassword={handlePassword}
                parentFirstName={handleFirstName}
                parentLastName={handleLastName}

            />
        },

    ]
    return (
        <div class="card card-md better-box-shadow">
            {loading && (
            <Modal show={show} size="sm" centered>
                <LoadingModal />
            </Modal>
        )}
            <div class="card-body">
                <StepZilla
                    steps={steps} showSteps={false} nextButtonCls='btn btn-primary float-right' backButtonCls="btn btn-prev btn-warning"
                />
                <br />
                <hr />
                <p><span className="colorwhite">Aleady have an account?</span> <Link to="/login"><a href="#"><b>Login</b></a></Link></p>
            </div>
            
        </div>
    )

}