import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import freightstradamus from "../../assets/img/freightstradamus.png"


function IncrementalGame(props) {
  const MULTIPLIER = 3;
  const initialLoads = (props.loads && props.loads.length * MULTIPLIER) || 0;

  const incrementValues = {
    loads: 3,
    carriers: 5,
    customers: 10,
    leadsEngagement: 2.5
  };

  const [progress, setProgress] = useState({
    loads: initialLoads,
    carriers: props.carriers || 0,
    customers: props.customers || 0,
    leadsEngagement: 0
  });

  const [points, setPoints] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        const updatedProgress = {
          loads: (prevProgress.loads + incrementValues.loads) % 110,
          carriers: (prevProgress.carriers + incrementValues.carriers) % 110,
          customers: (prevProgress.customers + incrementValues.customers) % 110,
          leadsEngagement: (prevProgress.leadsEngagement + incrementValues.leadsEngagement) % 110
        };

        if (updatedProgress.loads >= 100) {
          updatedProgress.loads = 0;
          setPoints(currentPoints => currentPoints + 100 * 0.3);
        }
        if (updatedProgress.carriers >= 100) {
          updatedProgress.carriers = 0;
          setPoints(currentPoints => currentPoints + 100 * 0.5);
        }
        if (updatedProgress.customers >= 100) {
          updatedProgress.customers = 0;
          setPoints(currentPoints => currentPoints + 100 * 1);
        }
        if (updatedProgress.leadsEngagement >= 100) {
          updatedProgress.leadsEngagement = 0;
          setPoints(currentPoints => currentPoints + 100 * 0.25);
        }

        return updatedProgress;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [points]); // Added points to useEffect dependency array

  return (
    <>
     <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
        <div className="card">
        <div className="card-header">
          <h3 className="colorpalevioletred">Freightstradamus - 5/16/2024</h3>
          <div className="edit-button">
            <h4 className='colorwheat'>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="5" y1="12" x2="19" y2="12" /><line x1="15" y1="16" x2="19" y2="12" /><line x1="15" y1="8" x2="19" y2="12" /></svg> Tomorrow's Insights</h4>
          </div>
        </div>
        <div className='card-body'>
          <center>
          <img src={freightstradamus} />
          </center>

        </div>
        
        </div>


     </div>

    {3 === 2 ? null : 
    <>
    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">

      <div className="card">
        <div className="card-header">
          <h3 className="colorpalevioletred">2024 Season</h3>
          <div className="edit-button">
            <h4 className='colorwheat'>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="5" y1="12" x2="19" y2="12" /><line x1="15" y1="16" x2="19" y2="12" /><line x1="15" y1="8" x2="19" y2="12" /></svg> Go to the Truckstop</h4>
          </div>
        </div>
        <div className="card-body">


          <div class="container">
            <div class="row">
              <div class="col">
                <h1>Total Freight Points: {points.toFixed()}</h1>
              </div>
              <div class="col">
                <h1>Items</h1>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="96" height="96" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="green" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 21c.5 -4.5 2.5 -8 7 -10" />
                  <path d="M9 18c6.218 0 10.5 -3.288 11 -12v-2h-4.014c-9 0 -11.986 4 -12 9c0 1 0 3 2 5h3z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="96" height="96" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="blue" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" /></svg>

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="256" height="256" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="white" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 4h-2l-3 10" /><path d="M16 4h2l3 10" /><line x1="10" y1="16" x2="14" y2="16" /><path d="M21 16.5a3.5 3.5 0 0 1 -7 0v-2.5h7v2.5" /><path d="M10 16.5a3.5 3.5 0 0 1 -7 0v-2.5h7v2.5" /></svg>
              </div>
            </div>
          </div>

          <div>
            <h4 className="colorwhite">Loads (0.3x): {progress.loads}%</h4>
            <ProgressBar now={progress.loads} label={`${progress.loads}%`} />
          </div>
          <div>
            <h4 className="colorwhite">Carriers (0.5x): {progress.carriers}%</h4>
            <ProgressBar now={progress.carriers} label={`${progress.carriers}%`} />
          </div>
          <div>
            <h4 className="colorwhite">Customers (1x): {progress.customers}%</h4>
            <ProgressBar now={progress.customers} label={`${progress.customers}%`} />
          </div>
          <div>
            <h4 className="colorwhite">Leads Engagement (0.25x): {progress.leadsEngagement}%</h4>
            <ProgressBar now={progress.leadsEngagement} label={`${progress.leadsEngagement}%`} />
          </div>
        </div>

      </div>
    </div>
    </>
    }
    </>
  );
}

export default IncrementalGame;