import React, { useState, useEffect } from 'react';
import { authFetch } from '../../auth';

function ContactForm(props) {
  const [formData, setFormData] = useState({
    contact_id: '',
    title: '',
    contact_name: '',
    phone_number: '',
    email: '',
    type: props.type, // default selection
    type_id: props.type_id
  });

  const handleChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(() => {
    if (formData.contact_id !== '') {
      props.addContact(formData);
    }
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await authFetch('https://fltbd.herokuapp.com/api/contacts', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const result = await response.json();
        
        // Update formData with result.contact_id
        setFormData(prevFormData => ({
          ...prevFormData,
          contact_id: result.contact_id
        }));
        props.toggleShow()
        // Reset formData to initial state.
        setFormData({
          contact_id: '',
          title: '',
          contact_name: '',
          phone_number: '',
          email: '',
          type: props.type, // default selection
          type_id: props.type_id
        });
      } else {
        const errorText = await response.text();
        console.error('Failed to create contact:', response.status, errorText);
        alert('Failed to create contact: ' + errorText);
      }
    } catch (error) {
      console.error('Failed to submit contact:', error);
      alert('Failed to submit contact due to exception: ' + error);
    }
  };

  return (
    <>
    <form onSubmit={handleSubmit}>
      <input
        className="form-control mt-2"
        type="text"
        name="title"
        value={formData.title}
        onChange={handleChange}
        placeholder="Title"
        required
      />
      <input
        className="form-control mt-2"
        type="text"
        name="contact_name"
        value={formData.contact_name}
        onChange={handleChange}
        placeholder="Contact Name"
        required
      />
      <input
        className="form-control mt-2"
        type="text"
        name="phone_number"
        value={formData.phone_number}
        onChange={handleChange}
        placeholder="Phone Number"
      />
      <input
        className="form-control mt-2"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
      />
      
      <button className="btn btn-success float-right mb-5 mt-3" type="submit">Add Contact</button>
     
    </form>
  
    </>
  );
}

export default ContactForm;