import React, { useState } from 'react'
import { authFetch } from '../../auth'
import moment from 'moment';
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal'

function NoteList(props) {
  const { notes } = props
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedNoteId, setSelectedNoteId] = useState(null)
  const [show, setShow] = useState(false)

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase())
  }

  const filteredNotes = notes.filter(note => {
    return note.content.toLowerCase().includes(searchTerm)
  })

  const handleRemoveNote = async () => {
    const response = await authFetch(`https://fltbd.herokuapp.com/api/note/${selectedNoteId}`, {
      method: 'DELETE'
    });

    const responseData = await response.json()

    if (response.status === 200) {
      props.removeNote(selectedNoteId)
      setShow(false)
    } else {
      console.error(responseData.message)
    }
    setSelectedNoteId(null)
  }

  const handleShow = () => setShow(true)

  if (notes.length === 0 && !props.showForm) {
    return (
      <div>
        <p className="colorwhite">You don't have any notes for this {props.type}.</p>
      </div>
    )
  }

  return (
    <div>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Search notes"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      <div className="card">

        <div className="list-group list-group-flush list-group-hoverable">
          {filteredNotes.map((note, index) => {
            let badgeClass = 'badge bg-info'; // Default to 'info'
            if (note.category === 'positive') {
              badgeClass = 'badge bg-success';
            } else if (note.category === 'negative') {
              badgeClass = 'badge bg-danger';
            }

            const taggedContacts = Array.isArray(note.tagged_contacts) ? note.tagged_contacts : [];

            return (
              <div key={`${note.id}-${index}`} className="list-group-item">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <span className={badgeClass}></span>
                  </div>
                  <div className="col text-truncate">
                    <div className="d-block font-weight-medium colorwhite">{note.content}</div>
                    {note.user && note.user.first_name && note.user.last_name && (
                      <div className="d-block text-secondary text-truncate mt-n1 font-weight-light">
                        <div className="colorwheat">{moment(note.created_at).format("MM/DD/YY")} by <span className="colorpalevioletred">{note.user.first_name} {note.user.last_name}</span></div>
                      </div>
                    )}
                    {taggedContacts.length > 0 && (
                      <div className="d-block mt-2">
                        <span className="colorwheat">Tagged Contacts:</span>
                        <div className="badges-list">

                          {taggedContacts.map((contact, contactIndex) => (

                            <span key={contactIndex} className="badge bg-azure text-azure-fg m-1">

                              {contact.label}

                            </span>

                          ))}

                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-danger list-group-item-actions"
                      type="button"
                      onClick={() => { setSelectedNoteId(note.note_id); handleShow() }}a
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {show && (
        <DeleteConfirmationModal
          title="Confirm Delete"
          message={"Are you sure you want to delete this note?"}
          onConfirm={handleRemoveNote}
          setShow={setShow}
          show={show}
        />
      )}
    </div>
  )
}

export default NoteList