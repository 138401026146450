import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce
} from "react-table";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";

import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
} from "react-router-dom";
import { useAuth, authFetch, logout } from '../../auth'
import io from "socket.io-client"

import CarrierTable from "./CarriersTable";
import CarriersHeader from "./CarriersHeader";
import PendingTable from "./PendingTable";
import CarriersField from "./CarriersField";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";


export default function CarriersContainer(props) {
  
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(true);
  const [pendingCarriers, setPendingCarriers] = useState([])
  const [data, setData] = useState()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleNewPendingCarrier = (PendingCarrier) => {
    const dataCopy = [...pendingCarriers]

    setPendingCarriers([...dataCopy, PendingCarrier])
    props.updatePendingList([...dataCopy, PendingCarrier])
  }
  const handleDeletePending = (G) => {

    setPendingCarriers(G)
    props.deletePending(G)
  }

  useEffect(() => {
    if (props && props.carriers && props.contracts) {
      setData(props.carriers);
      const pending = props.contracts.filter(contract => contract.status === "Not Started" || contract.status === "Started");
  
      setPendingCarriers(pending);
      setShow(false);
      setLoading(false);
    }
  }, [props.carriers, props.contracts]);

  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  const ENDPOINT = "https://fltbd.herokuapp.com";

  useEffect(() => {
    const socket = io.connect(ENDPOINT);
    socket.on("handle_message", (data) => props.pendingWebhook(data))
    return (() => {
      socket.disconnect()
    })
  }, [])




  return (
    <>
      <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">

<CarriersHeader />
<CarriersField parentNewPendingCarrier={handleNewPendingCarrier} {...props} />
<CarrierTable data={data} />

<PendingTable
  {...props}
  updatePendingList={props.updatePendingList}
  pendingCarriersData={pendingCarriers}
  handleDeletePending={handleDeletePending}
/>

</div>
{loading && (
            <Modal show={show} size="sm" centered>
                <LoadingModal />
            </Modal>
        )}
    </>
  )
}