

const SignUpCompany = (props) => {
  const changeCompanyDOT = (e) => {
    const value = e.target.value.replace(/[^\d]/g, ''); // Remove all non-digit characters
    props.parentCompanyDOT(value);
  };

  return (
    <>
      <h2 className="colorwhite">Enter DOT Number</h2>
      <small className="form-hint form-label colorwhite">
        This will help you auto-fill information on the next page.
      </small>
      <span className='colorpalevioletred'>We are only accepting brokers at the moment.</span>
      
      <div className="mb-3 mt-3">
        <input 
          type="text"
          name="company_dot" 
          className="form-control" 
          placeholder="DOT Number" 
          value={props.state.companyDOT} 
          onChange={changeCompanyDOT} 
          required 
        />
        <div className="error-msg-red mt-3"><b>{props.error}</b></div>
      </div>
    </>
  );
};

export default SignUpCompany;