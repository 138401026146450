import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from 'axios'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { v4 as uuidv4 } from "uuid";
import { authFetch } from '../../auth';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Creatable from 'react-select/creatable';



const LoadsShippers = props => {
    const history = useHistory();

    const [state, setState] = useState({ shippers: [] });
    const [locations, setLocations] = useState();
    const [focus, setFocus] = useState(false);



    const dimensionUnit = [
        { value: 'IN', label: 'IN' },
        { value: 'FT', label: 'FT' },
        { value: 'CM', label: 'CM' },
        { value: 'MM', label: 'MM' },
        { value: 'M', label: 'M' },

    ]

    const weightUnit = [
        { value: 'OZ', label: 'OZ' },
        { value: 'LB', label: 'LB' },
        { value: 'GAL', label: 'GAL' },
        { value: 'MG', label: 'MG' },
        { value: 'G', label: 'G' },
        { value: 'KG', label: 'KG' },

    ]




    const getOrder = () => {
        if (props && props.actions) {
            const actionsInfo = props.actions.filter(action => action.action == "s")
            if (actionsInfo === null || actionsInfo.length === 0) {
                return 1
            }
            else {
                let sortActions = actionsInfo.sort((a, b) => a.order - b.order)
                const getLast = sortActions.slice(-1)
                const orderNumber = parseInt(getLast[0].order) + 1
                return orderNumber
            }
        }
        else {
            return
        }
    }

    const handleOrder = (idx) => (e) => {
        const orderChange = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx)
                return shipper;

            props.parentActionOrder(shipper, e)
            return { ...shipper, order: parseInt(shipper.order) + 1 }
        })


    }

    const handleAddShipper = async () => {
        props.handleLoading(true)

        const getUniqueID = uuidv4();
        const newDate = moment(new Date()).toDate();
        const newShipper = {
            location_name: 'Choose Location',
            action: 's',
            load_match: props.loadID,
            action_id: getUniqueID,
            dimension_unit: "FT",
            weight_unit: "LB",
            date: moment(newDate).format("MM-DD-YYYY"),
            order: getOrder()
        };
    
        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/actions`, {
                method: 'PUT',
                body: JSON.stringify({
                    location_name: '',
                    action: 's',
                    load_match: props.loadID,
                    action_id: getUniqueID,
                    dimension_unit: "FT",
                    weight_unit: "LB",
                    date: moment(newDate).format("MM-DD-YYYY"),
                    order: getOrder()
                }),
                headers: { 'Content-Type': 'application/json' },
            });
    
            if (!response.ok) {
                props.handleLoading(false)

                const errorData = await response.json();
                throw new Error(errorData.message || 'Server error');
            }
    
            const responseData = await response.json();
            if (responseData.status === 200) {
                setState({
                    shippers: [...state.shippers, newShipper]
                });
                props.handleLoading(false)

                props.parentAddAction(newShipper);
            } else {
                props.handleLoading(false)

                throw new Error(responseData.message || 'Unexpected error');
            }
        } catch (error) {
            props.handleLoading(false)

            console.error('Error adding shipper:', error);
        }
    };

    const handleRemoveShipper = (idx) => () => {
        props.handleLoading(true)
        const shipperToDelete = state.shippers[idx];
    
        authFetch(`https://fltbd.herokuapp.com/actions/${shipperToDelete.action_id}`, {
            method: 'DELETE',
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                // Remove the shipper from the state
                const updatedShippers = state.shippers.filter((_, sidx) => idx !== sidx);
                setState({ ...state, shippers: updatedShippers });
                props.parentRemoveAction(shipperToDelete);
                props.handleLoading(false)
            } else {
                console.error('Error:', data);
                props.handleLoading(false)
            }
        })
        .catch((error) => {
            console.error('Unexpected error:', error);
            props.handleLoading(false)
        });
    };

    const handleShipperChange = (idx) => (e) => {
        console.log(e.__isNew__);

        const shipperChange = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx) return shipper;
            
            authFetch(`https://fltbd.herokuapp.com/actions/${shipper.action_id}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    location_name: e.label,
                    load_match: props.load_id,
                    street_address: e.street_address,
                    city: e.city,
                    state: e.state,
                    contact_name: e.contact_name,
                    zipcode: e.zipcode,
                    pickup_number: e.pickup_number,
                    cargo_description: e.cargo_description,
                    phone_number: e.phone_number,
                    action: 's',
                    action_id: shipper.action_id,
                    order: idx + 1
                })
            })
            .then(response => response.json())
            .then(data => {

                if (data.status === 200) {
                    // Optionally update state or show a success notification
                    setState(prevState => ({
                        ...prevState,
                        shippers: prevState.shippers.map((s, sidx) =>
                            sidx === idx ? {
                                ...s,
                                location_name: e.label,
                                street_address: e.street_address,
                                city: e.city,
                                state: e.state,
                                zipcode: e.zipcode,
                                contact_name: e.contact_name,
                                phone_number: e.phone_number,
                                pickup_number: e.pickup_number,
                                cargo_description: e.cargo_description,
                                extra_notes: e.extra_notes,
                            } : s
                        )
                    }));
                    props.parentActionChange(e, shipper.action_id);
                } else {
                    console.error('Error:', data);
                }
            })
            .catch((error) => {

                console.error('Unexpected error:', error);
            });
    
            return {
                ...shipper,
                location_name: e.label,
                street_address: e.street_address,
                city: e.city,
                state: e.state,
                zipcode: e.zipcode,
                contact_name: e.contact_name,
                phone_number: e.phone_number,
                pickup_number: e.pickup_number,
                cargo_description: e.cargo_description,
                extra_notes: e.extra_notes,
            };
        });
    
    };

    useEffect(() => {
        if (props && props.actions) {
            const actionsInfo = props.actions.filter(action => action.action == "s")
            const options = actionsInfo.map(d => ({
                "location_name": d.location_name,
                "load_match": props.load_id,
                "street_address": d.street_address,
                "city": d.city,
                "state": d.state,
                "contact_name": d.contact_name,
                "zipcode": d.zipcode,
                "phone_number": d.phone_number,
                "action_id": d.action_id,
                "pickup_number": d.pickup_number,
                "length": d.length,
                "width": d.width,
                "height": d.height,
                "weight": d.weight,
                "cargo_description": d.cargo_description,
                "dimension_unit":d.dimension_unit,
                "weight_unit": d.weight_unit,
                "extra_notes": d.extra_notes,
                "order": d.order,
                "date": d.date

            }))
            //This sorts by date - which works, but not ideal to actions that perform on the same day, order will work better.
            //let sortActions = options.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse()));

            let sortActions = options.sort((a, b) => a.order - b.order)
            setState({ ...state, shippers: sortActions })

            //Fazing out..
            //props.parentShippers(sortActions)
        }
    }, [props.actions])

    useEffect(() => {
        if (props && props.locations) {
            const options = props.locations.map(d => ({
                "location_name": d.location_name,
                "value": d.location_id,
                "label": d.location_name,
                "street_address": d.street_address,
                "city": d.city,
                "state": d.state,
                "zipcode": d.zipcode,
                "contact_name": d.contact_name,
                "phone_number": d.phone_number,
            }))
            setLocations(options)

        }
    }, [props], [props.locations])
    //https://eight-bites.blog/en/2021/06/wait-user-typing/
    const [timer, setTimer] = useState(null)
    const handleChange = (idx) => (e) => {
        clearTimeout(timer)

        const updateShipper = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx)
                return shipper;

            props.parentActions(e, shipper.action_id)
            return { ...shipper, [e.target.name]: e.target.value }

        })

        setState({ ...state, shippers: updateShipper })
        //props.parentShippers({ ...state, shippers: updateShipper })

    }
    const handleDateChange = (idx) => (date) => {
        const newDate = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx) return shipper;
    
            authFetch(`https://fltbd.herokuapp.com/actions/${shipper.action_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    date: moment(date).format("MM-DD-YYYY")
                }),
                headers: { 'Content-Type': 'application/json' },
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    // Optionally update state or show a success notification
                    setState(prevState => ({
                        ...prevState,
                        shippers: prevState.shippers.map((s, sidx) =>
                            sidx === idx ? { ...s, date: date } : s
                        )
                    }));
                    const e = {
                        target: {
                            name: "date",
                            value: moment(date).format("MM-DD-YYYY")
                        }
                    };
                    props.parentActions(e, shipper.action_id);
                } else {
                    console.error('Error:', data);
                }
            })
            .catch((error) => {
                console.error('Unexpected error:', error);
            });
    
            return { ...shipper, date: date };
        });
    
        setState({ ...state, shippers: newDate });
    };
const handleFocus = (idx) => (e) => {
    setFocus(false);

    if (!props.archived) {
        const updateShipper = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx) return shipper;

            authFetch(`https://fltbd.herokuapp.com/actions/${shipper.action_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    [e.target.name]: e.target.value
                }),
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.status === 200) {
                    // Optionally update state or show a success notification
                    setState(prevState => ({
                        ...prevState,
                        shippers: prevState.shippers.map((s, idx) => 
                            idx === sidx ? { ...s, [e.target.name]: e.target.value } : s)
                    }));
                } else {
                    console.error('Error:', data);
                }
            })
            .catch((error) => {
                console.error('Unexpected error:', error);
            });

            return { ...shipper, [e.target.name]: e.target.value };
        });

        setState({ ...state, shippers: updateShipper });
    }
};

    return (
        <>
            <div>


                <div class="card">
                    <div class="card-body">
                        <form>
                            <button type="button" onClick={handleAddShipper} className="small" class="btn btn-lime float-right" disabled={props.archived}>Add Pick Up</button>
                        </form>
                        <h3 class="card-title">Shippers </h3>
                    </div>
                </div>
                {state && state.shippers?.map((shipper, idx) => (
                        <div key={shipper.action_id} className="card"> 
                        <div class="card-body">
                            <div className="shipper alert alert-lime">
                                {shipper.location_name === ""
                                    ?
                                    <label><b>{`Pick Up #${idx + 1}`}</b> - Select Location Below</label>
                                    :
                                    <label><b>{`Pick Up #${idx + 1}`}</b> - Select Location Below</label>
                                }
                                <Creatable
                                    options={locations}
                                    placeholder={shipper.location_name}
                                    onChange={handleShipperChange(idx)}
                                    isDisabled={props.archived}
                                />


                                <br />


                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-sm">Street Address</span>
                                    </div>
                                    <input
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        type="text"
                                        value={shipper.street_address}
                                        name="street_address" class="form-control cFeatures"
                                        onChange={handleChange(idx)}
                                        disabled={props.archived}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">City</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={shipper.city}
                                                name="city"
                                                class="form-control"
                                                onChange={handleChange(idx)}
                                                disabled={props.archived}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">State</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={shipper.state}
                                                name="state" class="form-control"
                                                onChange={handleChange(idx)}
                                                disabled={props.archived}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">Zipcode</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                name="zipcode"
                                                value={shipper.zipcode}
                                                class="form-control"
                                                onChange={handleChange(idx)}
                                                disabled={props.archived}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-default">Contact</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={shipper.contact_name}
                                                class="form-control"
                                                name="contact_name"
                                                onChange={handleChange(idx)}
                                                disabled={props.archived}
                                            />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Phone Number</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                name="phone_number"
                                                value={shipper.phone_number}
                                                class="form-control"
                                                onChange={handleChange(idx)}
                                                disabled={props.archived}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group input-group-default mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Pick Up Number</span>
                                    </div>
                                    <input
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        type="text"
                                        value={shipper.pickup_number}
                                        name="pickup_number"
                                        class="form-control"
                                        onChange={handleChange(idx)}
                                        disabled={props.archived}
                                    />
                                </div>
                                

                                <div class="input-group mb-3">
                                    
                                    <div class="input-group-prepend">
                                        
                                        <span class="input-group-text">Cargo Description </span>
                                        
                                    </div>
                                    <input
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        class="form-control"
                                        value={shipper.cargo_description}
                                        name="cargo_description"
                                        onChange={handleChange(idx)}
                                        disabled={props.archived}
                                    />
                                </div>
                         
                                        <div class="row">
                                            <div class="col-12 col-md-3 mb-3">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="inputGroup-sizing-default">L</span>
                                                    </div>
                                                    <input  
                                                            name="length" 
                                                            className='form-control'
                                                            value={shipper.length}
                                                            onChange={handleChange(idx)} 
                                                            disabled={props.archived}
                                                            onBlur={handleFocus(idx)}
                                                            onFocus={() => setFocus(true)} />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 mb-3">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="inputGroup-sizing-default">W</span>
                                                    </div>
                                                    <input name="width" 
                                                            className='form-control'
                                                            value={shipper.width}
                                                            onChange={handleChange(idx)} 
                                                            disabled={props.archived}
                                                            onBlur={handleFocus(idx)}
                                                            onFocus={() => setFocus(true)} />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 mb-3">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="inputGroup-sizing-default">H</span>
                                                    </div>
                                                    <input name="height" 
                                                            className='form-control'
                                                            value={shipper.height}
                                                            onChange={handleChange(idx)} 
                                                            disabled={props.archived}
                                                            onBlur={handleFocus(idx)}
                                                            onFocus={() => setFocus(true)} />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 mb-3">
                                                <div class="input-group">
                                                    <select class="form-select form-control" 
                                                            placeholder={shipper.dimension_unit} 
                                                            name="dimension_unit" 
                                                            value={shipper.dimension_unit}
                                                            onChange={handleChange(idx)} 
                                                            disabled={props.archived}
                                                            onBlur={handleFocus(idx)}
                                                            onFocus={() => setFocus(true)}
                                                            
                                                            >

                                                        {dimensionUnit.map(unit => (
                                                            <option key={unit.value} value={unit.value}>
                                                                {unit.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-8 mb-3">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="inputGroup-sizing-default">Weight</span>
                                                    </div>
                                                    <input name="weight" 
                                                            className='form-control'
                                                            value={shipper.weight}
                                                            onChange={handleChange(idx)} 
                                                            disabled={props.archived}
                                                            onBlur={handleFocus(idx)}
                                                            onFocus={() => setFocus(true)} />
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-4 mb-3">
                                                <div class="input-group">
                                                    <select 
                                                         class="form-select form-control" 
                                                         placeholder={shipper.weight_unit} 
                                                         name="weight_unit" 
                                                         value={shipper.weight_unit}
                                                         onChange={handleChange(idx)} 
                                                         disabled={props.archived}
                                                         onBlur={handleFocus(idx)}
                                                         onFocus={() => setFocus(true)}
                                                    >
                                                        {weightUnit.map(unit => (
                                                            <option key={unit.value} value={unit.value}>
                                                                {unit.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                 
                        



                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Extra Notes </span>
                                    </div>
                                    <textarea
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        class="form-control"
                                        value={shipper.extra_notes}
                                        name="extra_notes"
                                        onChange={handleChange(idx)}
                                        disabled={props.archived}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-6">

                                        <DatePicker
                                            selected={moment(shipper.date).isValid() ? moment(shipper.date).toDate() : new Date()}
                                            name="date"
                                            className="form-control"
                                            onChange={handleDateChange(idx)}
                                            disabled={props.archived}
                                        />
                                    </div>
                                    <div className="col-4">
                                    </div>
                                    <div className="col-2">
                                        <input type="text" className="form-control" name="order" value={shipper.order} onChange={handleChange(idx)} disabled={props.archived} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-10">
                                    </div>
                                    <div className="col-2 mt-3">
                                        <button
                                            type="button"
                                            onClick={handleRemoveShipper(idx)}
                                            className="small"
                                            class="btn btn-danger float-right"
                                            disabled={props.archived}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                ))}



            </div>

        </>
    )


}

export default LoadsShippers