import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NotFound from "../Home/NotFound";
import Modal from 'react-bootstrap/Modal'
import LoadingModal from "../Modals/LoadingModal";
import styled from "styled-components"


const Styles = styled.div`
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive');
@page {
    size: Letter;
    margin: 4em 2em;
    
    margin-top: 2em;
   

    @bottom-right {
      content: "fltbd.com";
      font-size: 0.8em;
      font-style: italic;
      font-family: Arial, Helvetica, sans-serif;
    }
    /* 
    @top-left {
        content: "setupPack.com";
        font-size: 0.8em;
    }
    */
  }
  @page :first {

  }
  
  @page ppe_page {

  }



  html {
    color: #000;
    font-family: sans;
    font-size: 10pt;
    line-height: 1.4;
  }
  
  
  h1 {
      color: #000;
    font-weight: 400;
  }
  
  h2 {
    color: #27b;
    font-size: 1em;
    margin: 2em 0 0 0;
    text-transform: uppercase;
  }
  .h8 {
    color: #27b;
    font-size: 1.25em;
    margin: 2em 0 0 0;
    text-transform: uppercase;
  }
  
  p {
    margin: 0.5em 0;
    text-align: justify;
  }
  
  
  aside {
    background: #eee;
    float: right;
    margin-left: 1em;
    width: 30%;
  }
  
  aside embed {
    margin-bottom: 1em;
    width: 100%;
  }
  
  aside article {
    margin: 1em;
  }
  
  aside article:not(article:last-child) {
    border-bottom: 1px solid #aaa;
  }
  
  
  #secondary {
    
    text-align: right;
    width: 100%;
  }
  
  #secondary article {
    display: table-cell;
    padding-right: 1em;
    border-bottom:1px solid black;
    padding: 20px;
  }
  
  #secondary p {
    font-family: 'Cedarville Cursive', cursive;
    font-size: 1.8em;
    font-size: 3.5vw;
    padding: 1em;
    border-bottom: 1px solid black;
    
  }
  
    #secondary p {
 
    padding-bottom: 0em;
    
  }
  embed {
    width: 60%;
  }
  /* 
  li
{
    border-bottom: 2px solid black;
    width: 725px;
    list-style-type: none;
    padding-top: 20px
}
  */

label
{
    border-bottom: 2px solid white;
    padding-right: 5px;
}

* {
    box-sizing: border-box;
  }
  
  .box {
    float: left;
    width: 33.33%;
    padding: 0px;
  }
  .box2 {
    float: left;
    width: 50%;
    
  }
  
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .box p {
    margin: 0.5em 0;
    text-align: justify;
    font-size: 10pt;
    text-align:right;
  }
  table, th, td {
  
    border-collapse: collapse;
    
  }
  th, td {
    padding: 5px;
    text-align: left;
    font-size: 16px;
  }
  tr {
    padding-bottom:20px;
  }
  .ppe {
    page: ppe_page;
    page-break-before: always;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin: 0.65in;
    margin-top: .30in;
  }

  .ppe-title {
    margin-bottom: 30px;
    color: red;
    font-size: 25px;
    font-weight: bold;
  }

  .ppe-alert  {
    color: red;
    font-size: 18px;
  }
  
  .ppe li{
    border: none;
    list-style-type: disc;
  }

  .contract {
    page-break-before: always;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-right: 0.65in;
    margin-left: 0.65in;
  }

  .contract-bold {
    font-weight: bold;
  }
  
  .contract-underline {
    border-bottom: 1px solid black;
  }

  .contract li {
    list-style-type: none;
  }

  .contract tr {
    width:50%; 
    text-align: center;
  }

  .contract td {
    font-weight: bold;
    text-align: center;
  }

  .contract th {
    text-align: center;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }

  .ppe .footer{
    display: none;
  }

  .broker{

    width: 50%;
}
.carrier{

    width: 50%;
}

.container2 {
  display:flex;
}
.profile_signed {
    border-bottom: 1px solid black;    
    width: 70%;

}
.profile_date {
    border-bottom: 1px solid black;
    width: 30%;

}

.container2 span {
  font-size: 30px;
}

.profile_signed {
  border-bottom: 1px solid black;    
  width: 70%;

}
.profile_date {
  border-bottom: 1px solid black;
  width: 30%;

}

.container2 span {
font-size: 30px;
}


table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}
th {
  border-bottom: 1px solid black;
  
  font-size: 10pt;
  font-weight: 400;
  padding-bottom: .25cm;
  text-transform: uppercase;
}
td {
  padding-top: 0;
}
/* 
td:last-of-type {
  color: #1ee494;
  font-weight: bold;
  text-align: right;
}
*/
th, td {
  text-align: center;
}
th:first-of-type, td:first-of-type {
  text-align: left;
}
th:last-of-type, td:last-of-type {
  text-align: right;
}
table#total {
  background: #f6f6f6;
  border-color: #f6f6f6;
  border-style: solid;
  border-width: 2cm 3cm;
  bottom: 0;
  font-size: 20pt;
  margin: 0 -3cm;
  position: absolute;
  width: 18cm;
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.sec-column {
  float: left;
  width: 33.3%;
}

/* Clear floats after the columns */
.sec-row:after {
  content: "";
  display: table;
  clear: both;
}

.align-right {
  text-align: right;
}

.float-right {
  float:right;
}
.float-left {
  float: left;
}

.rate-confirmation-title {
  font-weight: bold;
  font-size: large
}

.bottom-border {
  border-bottom: 1px solid black;
}
.rate {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
}
hr {
  margin: 0px
}


.verify-container {
    margin: 15px auto;
    padding: 70px;
    max-width: 850px;
    background-color: #fff;
    border: 1px solid #ccc;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
  }
  
  @media (max-width: 767px) {
    .verify-container {
      padding: 35px 20px 70px 20px;
      margin-top: 0px;
      border: none;
      border-radius: 0px;
    }
  }`

  

  
export default function VerifyRate(props) {
    const params = useParams();
    const [state, setState] = useState();
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true)
    const [notFound, setNotFound] = useState(false)

    const [image, setImage] = useState(null);
    const [opacity, setOpacity] = useState(1);

    const onImageChange = event => {
        setImage(URL.createObjectURL(event.target.files[0]));
    };

    const onOpacityChange = event => {
        setOpacity(1 - (event.target.value / 100));
    };
    useEffect(() => {

        fetch(`https://fltbd.herokuapp.com/api/verify-rate/${params.rate_id}`)
            .then(response => response.json())
            .then(data => {
                if (data.status_code === 689) {
                    setNotFound(true)
                }
                else {
                    setShow(false)
                    setLoading(false)
                    setState(data)
                }
            }
            );

    }, [])


    if (notFound) {
        return (
            <NotFound />
        )
    }

    return (

        <>

            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                <div class="card" >
                    <div class="card-body colorwhite">
                      <h1>Rate Verification</h1>
                        {state && state.Carrier && state.Confirmation && state.Broker &&
                            <>
                                <h2 className="card-title">Carrier Details</h2>
                                <h3>Carrier: {state.Confirmation.carrier_name}</h3>
                                <h3>DOT: {state.Carrier.carrier_dot}</h3>
                                {state.Carrier.factoring_company ?
                                <>
                                <h3>Factoring Company: {state.Carrier.factoring_company}</h3>
                                <br />
                                </>
                                : null
                                }
                                <h2 className="card-title">Load Details</h2>
                                <h3>Load Number: {state.Confirmation.load_number}</h3>
                                <h3>Rate ${state.Confirmation.carrier_pay}</h3>
                                <h3>Direct Link: <a className="colorpalevioletred" href={`https://rateconfirmation.com/documents/rateconfirmation/${state.Confirmation.load_id}`}>Confirmation Page</a>  </h3>
                                <h2 className="card-title">Broker Details</h2>
                                <h3>Broker: {state.Broker.company_name}</h3>
                                

                            </>

                        }
                    </div>

                </div>
                 


            </div>


           
            <Modal show={show} size="sm" centered>
                <LoadingModal />
            </Modal>


        
        </>
    )
}