import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"; 
import { authFetch } from '../../auth'; 
import { Form, FormControl, Container, Row, Col, Alert } from 'react-bootstrap'; 
import UseAlert from '../Alerts/UseAlerts'; 
export default function CarriersLevel(props) { 
    const params = useParams();
    const [state, setState] = useState({}) 
    const [data, setData] = useState({}) 
    const [level, setLevel] = useState(0); 
    const levels = [ 
        'DO NOT USE', 
        'USE WITH CAUTION', 
        'NEUTRAL', 
        'PREFERRED', 
        'SUPER PREFERRED',   
    ]; 
    useEffect(() => { 
        if (props && props.do_not_load) { 
            setData({ do_not_load: props.do_not_load }) 
        } 
    }, [props.do_not_load]) 
    const { 
        alertState, 
        showSuccessAlert, 
        showFailAlert, 
        showNoChangeAlert 
    } = UseAlert(); 
    const [edit, setEdit] = useState("false") 
    const changeEdit = () => { 
        setEdit(!edit); 
    } 
    const doNotLoadChange = async (e) => { 
        try { 
            const response = await authFetch(`https://fltbd.herokuapp.com/api/carriers/${params.carrier_id}`, { 
                method: 'PATCH', 
                body: JSON.stringify({ 
                    do_not_load: !data.do_not_load 
                }), 
                headers: { 'Content-Type': 'application/json' }, 
            }); 
            if (response.status === 304 || response.status === 405) { 
                return; 
            } 
            if (!response.ok) { 
                if (response.status === 500) { 
                    setEdit(!edit); 
                    showFailAlert('Server Error: Please try again later or contact support.'); 
                } else { 
                    throw new Error(`HTTP error! status: ${response.status}`); 
                } 
            } 
            if (response.status === 200) { 
                showSuccessAlert('Success: Carrier Information Updated') 
                setEdit(!edit); 
                setData(prevData => ({ ...prevData, do_not_load: !prevData.do_not_load })); 
                props.updateCarrier(({ ...data, do_not_load: !data.do_not_load })); 
            } 
        } catch (error) { 
            console.error('Failed to update carrier status:', error); 
        } 
    } 
    return ( 
        <> 
            <div className="card"> 
                <div className="card-header" style={{ border: "none" }}> 
                    <h3 className="card-title">Carriers Preferred Level</h3> 
                    <div className="edit-button"> 
                        <a href="#edit" id="edit" onClick={changeEdit}> [edit]</a> 
                    </div> 
                </div> 
                <div className="card-body"> 
                    {alertState.show && ( 
                        <Alert variant={`${alertState.type}`}> 
                            {alertState.message} 
                        </Alert> 
                    )} 
                    <Form> 
                        <Form.Group controlId="usageRange"> 
                            <FormControl 
                                type="range" 
                                min="0" 
                                max="4" 
                                value={level}
                                id="range"
                                onChange={(e) => setLevel(parseInt(e.target.value))} 
                                style={{ height: '2rem', width: '75%' }} 
                                disabled={edit}
                            /> 
                            
                            <Form.Label> 
                               
                            </Form.Label> 
                        </Form.Group> 
                    </Form>    
                    <span className='colorwheat mt-2'>Preferred Level: </span><strong className='colorwhite'>{levels[level]}</strong> 
                </div> 
            </div> 
        </> 
    ) 
}