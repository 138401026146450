import React, { useEffect, useState } from "react";


const TermsConditions = () => {

    return (
        <>  
        <div className="colorwhite">
            <h2>1. Introduction</h2>
            <p>The RateConfirmation.com Transportation Management System (TMS) is a software provided by RateConfirmation.com for managing transportation and logistics processes. By accessing or using the RateConfirmation.com TMS, you agree to be bound by the terms and conditions outlined in this agreement. If you do not agree to these terms, you should not use the RateConfirmation.com TMS.
            </p>
            <h2>2. Use of RateConfirmation.com TMS</h2>
            <p>The TMS is intended for the use of registered users & recipients of regular users only. You are solely responsible for the security of your account and password, and for all activities that occur under your account. You agree to immediately notify RateConfirmation.com of any unauthorized use of your account or password.
            </p>
            <h2>3. Intellectual Property</h2>
            <p>The TMS, including all software, content, graphics, design, and other materials, is protected by intellectual property laws, including copyright, trademark, and patent laws. RateConfirmation.com retains all rights, title, and interest in the TMS, and you do not acquire any rights, express or implied, in the TMS except as specifically set forth in this agreement.
            </p>
            <h2>4. Limited License</h2>
            <p>RateConfirmation.com grants you a limited, non-exclusive, non-transferable license to access and use the RateConfirmation.com TMS, subject to the terms and conditions of this agreement. You may not modify, translate, reverse-engineer, decompile, or disassemble the RateConfirmation.com TMS, or create derivative works based on the RateConfirmation.com TMS.
            </p>
            <h2>5. Disclaimer of Warranties</h2>
            <p>The RateConfirmation.com TMS is provided on an "as is" and "as available" basis, without warranties of any kind, either express or implied. RateConfirmation.com makes no representations or warranties, express or implied, including without limitation, warranties of merchantability, fitness for a particular purpose, title, non-infringement, or that the RateConfirmation.com TMS will meet your requirements or be available on an uninterrupted, secure, or error-free basis.
            </p>
            <h2>6. Limitation of Liability</h2>
            <p>RateConfirmation.com will not be liable to you for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the RateConfirmation.com TMS. RateConfirmation.com's total liability to you arising out of or in connection with this agreement or the RateConfirmation.com TMS will not exceed the fees paid by you to RateConfirmation.com for use of the RateConfirmation.com TMS.
            </p>
            <h2>7. Changes to the RateConfirmation.com TMS and Terms</h2>
            <p>RateConfirmation.com reserves the right to modify or discontinue the RateConfirmation.com TMS or these terms and conditions at any time, without notice. Your continued use of the RateConfirmation.com TMS after any such change constitutes your acceptance of the new terms and conditions.
            </p>
            <h2>8. Termination</h2>
            <p>RateConfirmation.com may terminate this agreement and your use of the RateConfirmation.com TMS at any time, for any reason, with or without notice.
            </p>
            <h2>9. Governing Law</h2>
            <p>This agreement will be governed by and construed in accordance with the laws of California, and you consent to the exclusive jurisdiction of California courts in all disputes arising out of or relating to this agreement or the RateConfirmation.com TMS.
            </p>
            <h2>10. California Users</h2>
            <p>
            Under the California Consumer Privacy Act (“CCPA”), RateConfirmation.com is a “service provider”, not a “business” or “third party”, with respect to your use of the Services. That means we process any data you share with us only for the purpose you signed up for and as described in these Terms, the Privacy policy, and other policies. We do not retain, use, disclose, or sell any of that information for any other commercial purposes unless we have your explicit permission. And on the flip-side, you agree to comply with your requirements under the CCPA and not use RateConfirmation.com in a way that violates the regulations.
            </p>
            <h2>11. Entire Agreement</h2>
            <p>
            This agreement constitutes the entire agreement between you and RateConfirmation.com and supersedes all prior agreements and understandings, whether written or oral, relating to the RateConfirmation.com TMS.
            </p>
            <p>
            Last Updated: 2-1-2023.
            </p>
            </div>
        </>
    )
}

export default TermsConditions