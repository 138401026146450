import React, { useState, useEffect, Component } from 'react'
import { useHistory, withRouter, useParams } from "react-router-dom";
import { authFetch } from '../../auth';
import { Alert } from "react-bootstrap";
import UseAlert from '../Alerts/UseAlerts';

export default function CarriersItemDNU(props) {
    ////WORK IN PROGRESS
    const params = useParams();

    const [state, setState] = useState({})
    const [data, setData] = useState({})

    useEffect(() => {
        if (props && props.do_not_load) {
            setData({ do_not_load: props.do_not_load })
        }
    }, [props.do_not_load])
    const {
        alertState,
        showSuccessAlert,
        showFailAlert,
        showNoChangeAlert
    } = UseAlert();
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }


    const doNotLoadChange = async (e) => {
        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/api/carriers/${params.carrier_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    do_not_load: !data.do_not_load
                }),
                headers: { 'Content-Type': 'application/json' },
            });

            // Check if the response indicates no changes made
            if (response.status === 304 || response.status === 405) {
                // Optionally, add an alert to inform the user that no changes were made
                // e.g., showNoChangeAlert('No changes were made as the data was unchanged or update not allowed.');
                return;
            }

            // Handle non-ok responses that may indicate errors
            if (!response.ok) {
                if (response.status === 500) {
                    setEdit(!edit);
                    showFailAlert('Server Error: Please try again later or contact support.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            }
            if (response.status === 200) {
                showSuccessAlert('Success: Carrier Information Updated')
                setEdit(!edit);
                // Assuming a successful response, update local state
                setData(prevData => ({ ...prevData, do_not_load: !prevData.do_not_load }));
                props.updateCarrier(({ ...data, do_not_load: !data.do_not_load }));
            }



            // Optionally, add an alert or some success indicator
            // e.g., showSuccessAlert('Carrier update successful.');
        } catch (error) {
            console.error('Failed to update carrier status:', error);
            // Optionally, handle the specific UI alerting/logic here for failure
        }
    }



    return (
        <>
            <div class="card">
                <div class="card-header" style={{ border: "none" }}>
                    <h3 class="card-title">Do Not Load</h3>
                    <div class="edit-button">
                        <a href="#edit" id="edit" onClick={changeEdit}> [edit]</a>
                    </div>
                </div>
                <div class="card-body">
                    {alertState.show && (
                        <Alert variant={`${alertState.type}`}>
                            {alertState.message}
                        </Alert>
                    )}

                    <label class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" onChange={doNotLoadChange} checked={data.do_not_load} disabled={edit} />
                        <span class="form-label colorwhite">Load or Do Not Load</span>

                    </label>
                </div>
            </div>


        </>

    )
}