import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../../auth"
import {
    useHistory,
    useParams
} from "react-router-dom";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";


const ItemsInfoEdit = (props) => {
    const params = useParams();
    const history = useHistory();
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);
    const [data, setData] = useState()
    const [totalAmount, setTotalAmount] = useState()


    const handleChange = (idx) => (e) => {
        const update = data.rows.map((item, sidx) => {
            if (idx !== sidx)
                return item;
            return {
                ...item,
                [e.target.name]: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value
            }
        })
        setData({ ...data, rows: update })
    }
    const handleAddRow = () => {
        const newItem = {
            description: "",
            quantity: 1,
            amount: 0,
        };
        setData({
            rows: [...data.rows, newItem]
        });
    };
    const handleRemoveSpecificRow = (idx) => () => {
        const rows = [...data.rows]
        rows.splice(idx, 1)
        setData({ rows })
    }

    useEffect(() => {
        if (props && props.items) {

            setData(prevState => {
                return { ...prevState, rows: props.items };
            });



        }
        else {
            setData({
                rows: [{
                    description: "",
                    quantity: 1,
                    amount: 0
                }]
            })
        }
    }, [props.items])

    useEffect(() => {
        if (data && data.rows) {
            setTotalAmount(data.rows.reduce((a, v) => a = a + v.quantity * v.amount, 0))
        }
    }, [data])



    const handleSubmit = async (e) => {
        e.preventDefault();
        props.updateLoading(true);

        const newInvoice = {
            invoice_id: props.invoice_id,
            invoice_number: props.invoice_number,
            date: moment(props.date).format('l'),
            due_date: moment(props.due_date).format('l'),
            customer_id: props.customer_id,
            customer_name: props.customer_name,
            customer_billing_street_address: props.customer_billing_street_address,
            customer_billing_city: props.customer_billing_city,
            customer_billing_state: props.customer_billing_state,
            customer_billing_zipcode: props.customer_billing_zipcode,
            customer_billing_phone_number: props.customer_billing_phone_number,
            customer_billing_email: props.customer_billing_email,
            customer_terms: props.terms,
            items: [data.rows],
            total: totalAmount,
            status: props.status,
            invoice_note: props.invoice_note,
            ref_number: props.ref_number,
            terms: props.terms,
            load_id: props.invoice_id,
        }
        try {


            const response = await authFetch(`https://fltbd.herokuapp.com/api/invoice/${props.invoice_id}`, {
                method: 'PATCH',
                body: JSON.stringify(newInvoice),
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) throw Error(`HTTP error! status: ${response.status}`);

            const json = await response.json();
            if (json.status !== 200) throw Error('Update failed for unknown reasons.')
            if (json.status === 200) {
                console.log(json)
                props.onShowSuccessAlert("Invoice Updated")

                newInvoice.items = data.rows
                props.updateLoading(false);
                props.handleUpdateInvoice(newInvoice, json.action);
            }
            else {
                props.onShowFailAlert("Error: Nothing Saved In The Database.")
            }

        } catch (error) {
            props.updateLoading(false);
            //props.updateError(true);
            props.onShowFailAlert("Error: Nothing Saved In The Database.")
        }


    }
    const handleDelete = async () => {
        try {
            props.updateLoading(true);
            const response = await authFetch(`https://fltbd.herokuapp.com/api/invoice/${props.invoice_id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) throw Error(`HTTP error! status: ${response.status}`);

            const json = await response.json();
            if (json.status !== 200) throw Error('Delete failed for unknown reasons.');
            if (json.status === 200) {
                props.onShowSuccessAlert("Invoice Deleted");
                props.updateLoading(false);
                const dataCopy = [...props.invoices];
                const newList = dataCopy.filter(x => x.invoice_id !== props.invoice_id);
                props.updateInvoiceList(newList);

                // Redirect or update state as necessary
                history.push('/path-to-redirect');
            } else {
                props.onShowFailAlert("Error: Invoice Could Not Be Deleted.");
            }
        } catch (error) {
            props.updateLoading(false);
            props.onShowFailAlert("Error: Invoice Could Not Be Deleted.");
        }
    }
    return (
        <>

            <h3 className="colorwhite">Items</h3>

            <div class="table-responsive">

                <table>
                    <thead>
                        <tr style={{ borderTop: "1px solid wheat", backgroundColor: "wheat" }}>

                            <th className="reactTable-th w-75" style={{ borderBottom: "1px solid wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Description</th>
                            <th style={{ borderBottom: "1px solid wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Quantity</th>
                            <th style={{ borderBottom: "1px solid wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Amount</th>
                            <th style={{ borderBottom: "1px solid wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}></th>

                        </tr>
                    </thead>
                    {data && data.rows.map((item, idx) =>
                        <tr key={idx}>
                            <td className="w-75">
                                <input className="form-control" name="description" value={item.description} onChange={handleChange(idx)} readOnly={!props.edit} />
                            </td>
                            <td>
                                <input className="form-control" type="num" name="quantity" value={item.quantity} onChange={handleChange(idx)} readOnly={!props.edit} />
                            </td>

                            <td>

                                <input className="form-control" type="text" name="amount" value={item.amount} onChange={handleChange(idx)} readOnly={!props.edit} />
                            </td>
                            <td>
                                {!props.edit ?
                                    null
                                    :
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={handleRemoveSpecificRow(idx)}
                                    >
                                        Remove
                                    </button>
                                }
                            </td>
                            <br />
                            <br />
                            <br />


                        </tr>



                    )}

                </table>
                <h4 className="float-right colorwhite">Subtotal: ${totalAmount} </h4> <br />
                {!props.edit ? null :
                    <button onClick={handleAddRow} className="btn btn-primary"> Add Item </button>
                }

            </div>
            {!props.edit ? null :
                <>
                    <div className="row">
                        <div className="col">
                        <button className="btn btn-danger" onClick={handleDelete}>
                                Delete Invoice
                            </button>
                        </div>
                        <div className="col">
                            <form onSubmit={handleSubmit}>
                                <button class="btn btn-success btn-lg float-right">Save Invoice</button>

                            </form>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default ItemsInfoEdit