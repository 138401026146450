import React, { Component, setState } from 'react';


export default class ContractCarrierDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  carrierNameChange = e => {
    this.props.ParentCarrierName(e.target.value)
  }
  carrierDBAChange = e => {
    this.props.ParentCarrierDBA(e.target.value)
  }
  carrierAddressChange = e => {
    this.props.ParentCarrierAddress(e.target.value)
  }
  carrierPhoneChange = e => {
    this.props.ParentCarrierPhone(e.target.value)
  }

  isValidated() {
    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator
    let isDataValid = false;

    // if full validation passes then save to store and pass as valid
    if (Object.keys(validateNewInput).every((k) => {
      return validateNewInput[k] === true
    })) {
      if (this.props.carrier_name !== userInput.carrier_name || this.props.carrier_phone !== userInput.carrier_phone || this.props.physical_address != userInput.physical_address) { // only update store of something changed
        return 'success'  // Update store here (this is just an example, in reality you will do it via redux or flux)
      }

      isDataValid = true;
    }
    else {
      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }

    return isDataValid;
  }
  validationCheck() {
    if (!this._validateOnDemand)
      return;

    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator

    this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
  }
  _validateData(data) {
    return {
      carrier_nameVal: (data.carrier_name != 0), // required: anything besides N/A
      carrier_phoneVal: (data.carrier_phoneVal != 0),
      physical_addressVal: (data.physical_address != 0), // required: anything besides N/A

    }
  }

  _validationErrors(val) {
    const errMsgs = {
      carrier_nameValMsg: val.carrier_nameVal ? '' : 'Carrier Name Required',
      carrier_phoneValMsg: val.carrier_phoneVal ? '' : 'Phone Number Required',
      physical_addressValMsg: val.physical_addressVal ? '' : 'Physical Address Required',

    }
    return errMsgs;
  }

  _grabUserInput() {
    return {
      carrier_name: this.refs.carrier_name.value,
      physical_address: this.refs.physical_address.value,


    };
  }
  render() {
    let notValidClasses = {};

    if (typeof this.props.physical_addressVal == 'undefined' || this.props.physical_address) {
      notValidClasses.physical_addressCls = 'no-error col-md-8';
    }
    else {
      notValidClasses.physical_addressCls = 'has-error col-md-8';
      notValidClasses.physical_addressValGrpCls = 'val-err-tooltip';
    }
    if (typeof this.props.carrier_nameVal == 'undefined' || this.props.carrier_name) {
      notValidClasses.carrier_nameCls = 'no-error col-md-8';
    }
    else {
      notValidClasses.carrier_nameCls = 'has-error col-md-8';
      notValidClasses.carrier_nameValGrpCls = 'val-err-tooltip';
    }
    if (typeof this.props.carrier_phoneVal == 'undefined' || this.props.carrier_phone) {
      notValidClasses.carrier_phoneCls = 'no-error col-md-8';
    }
    else {
      notValidClasses.carrier_phoneCls = 'has-error col-md-8';
      notValidClasses.carrier_phoneValGrpCls = 'val-err-tooltip';
    }
    return (
      <div class="contract-margin">
        <div className="card-header">
          <h3 class="card-title">Carrier Details</h3>
          <div className="order-right colorwheat">
            {this.props.details.company_name}
          </div>
        </div>

        <div class="card-body">




          <label class="form-label" form="inputCarrierName">Carrier Name</label>
          <input ref="carrier_name" type="text" class="form-control" name="carrier_name" value={this.props.details.carrier_name} onChange={this.carrierNameChange} required />
          <div className="error-msg-red">{this.state.carrier_nameValMsg}</div>

          <label class="form-label mt-3" form="inputCarrierName">Carrier DBA</label>
          <input type="text" class="form-control" name="carrier_dba" value={this.props.details.carrier_dba} onChange={this.carrierDBAChange} />

          <div class="form-row">
            <div class="col-md-6 mt-3">
              <label class="form-label">MC Number</label>
              <input type="text" class="form-control" name="carrier_mc" value={this.props.details.carrier_mc} readOnly />

            </div>
            <div class="col-md-6 mt-3">
              <label class="form-label">DOT Number</label>
              <input type="text" class="form-control" name="carrier_dot" value={this.props.details.carrier_dot} readOnly />
            </div>
          </div>
          <label class="form-label mt-3" form="inputCarrierPhone">Phone Number</label>
          <input type="text" class="form-control" name="carrier_phone" value={this.props.details.carrier_phone} onChange={this.carrierPhoneChange} />
          <div className="error-msg-red">{this.state.carrier_phoneValMsg}</div>

          <label class="form-label mt-3" form="inputPhysicalAddress">Carrier Physical Address </label>
          <textarea ref="physical_address" type="text" class="form-control" name="physical_address" value={this.props.details.physical_address} onChange={this.carrierAddressChange} />
          <div className="error-msg-red">{this.state.physical_addressValMsg}</div>

        </div>

      </div>
    )
  }
}