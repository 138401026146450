import React, { useEffect, useState } from "react";
import { authFetch } from "../../auth";
import { Alert } from "react-bootstrap";
import UseAlert from '../Alerts/UseAlerts';

const CompanyInformationLoading = () => {
    return (
        <>
            <div className="card">
                <div class="card-header">
                    <h3 class="card-title">Company Information</h3>

                </div>
                <div class="card-body">

                    <div class="form-row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Name</label>
                            <div class="placeholder-glow"><span class="placeholder col-6 placeholder"></span></div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Phone</label>
                            <div class="placeholder-glow"><span class="placeholder col-6 placeholder"></span></div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label">MC</label>
                            <div class="placeholder-glow"><span class="placeholder col-6 placeholder"></span></div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">DOT</label>
                            <div class="placeholder-glow"><span class="placeholder col-6 placeholder"></span></div>
                        </div>
                    </div>
                    <div class="form-row">
                    <div class="col-md-12 mb-3">
                        <label class="form-label">Address</label>
                        <div class="placeholder-glow"><span class="placeholder col-12 placeholder"></span></div>
                    </div>
                    </div>

                </div>

            </div>
        </>

    )
}

export default function SettingsCompanyInformation(props) {

    const [state, setState] = useState('')
    const {
        alertState,
        showSuccessAlert,
        showFailAlert,
        showNoChangeAlert
    } = UseAlert();

    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/api/companys/${props.id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    remit_company: state.remit_company,
                    remit_street_address: state.remit_street_address,
                    remit_city: state.remit_city,
                    remit_state: state.remit_state,
                    remit_zipcode: state.remit_zipcode,
                    remit_phone_number: state.remit_phone_number,
                    remit_email: state.remit_email
                }),
                headers: { 'Content-Type': 'application/json' },
            })
            
            if (response.status === 304) {
                setEdit(!edit);
            } 
            if (!response.ok) {
                setEdit(!edit);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if(data.status === 200) {
                showSuccessAlert("Success: Company Remit Information Updated")

                props.updateCompany({
                    remit_company: state.remit_company,
                    remit_street_address: state.remit_street_address,
                    remit_city: state.remit_city,
                    remit_state: state.remit_state,
                    remit_zipcode: state.remit_zipcode,
                    remit_phone_number: state.remit_phone_number,
                    remit_email: state.remit_email
                })
            } else {
                showFailAlert("Error: An Error Occured..")
                throw new Error(`Update failed! reason: ${data.reason}`);
            }
    
            setLoading(false);
            setEdit(!edit);
        } catch (error) {
            console.error('An error occurred during the fetch:', error);
        }
    }

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })

    }
    const changeEdit = () => {
        setEdit(!edit);
    }
    useEffect(() => {
        if (props && props.company_name) {
            setState(props)
            setLoading(false)
        }

    }, [props.company_name])

    

    if (loading) {
        return (
            <CompanyInformationLoading />
        )
    }

    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Company Information</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }

    return (
        <>
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Company Information</h3>

            </div>
            <div class="card-body">

                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label colorwhite">Name</label>
                        <input type="text" class="form-control" value={props.company_name} name="company_name" disabled={true} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label colorwhite">Phone</label>
                        <input type="text" class="form-control" value={props.phone_number} name="phone_number" disabled={true} />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label colorwhite">MC</label>
                        <input type="text" class="form-control" value={props.mc_number} name="mc_number" disabled={true} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label colorwhite">DOT</label>
                        <input type="text" class="form-control" value={props.dot_number} name="mc_number" disabled={true} />
                    </div>
                </div>
                <div class="form-row">
                    <label class="form-label colorwhite">Address</label>
                    <textarea class="form-control" value={props.company_address} name="carrier_address" disabled={true} />

                </div>

            </div>

        </div>

        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Remit Information</h3>
                <div class="edit-button">
                    <a id="edit" href="#" onClick={changeEdit}> [edit]</a>
                </div>

            </div>
            <div class="card-body">
            {alertState.show && (
                    <Alert variant={`${alertState.type}`}>
                        {alertState.message}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>

                <div class="form-row">
                    <div class="col-md-12 mb-3">
                        <label class="form-label colorwhite">Remit Name</label>
                        <input type="text" class="form-control" value={state.remit_company} name="remit_company" readOnly={edit} onChange={handleChange}/>
                    </div>
                    
                </div>
                <div class="form-row">
                    <div class="col-md-12 mb-3 colorwhite">
                        <label class="form-label">Remit Street Address</label>
                        <input type="text" class="form-control" value={state.remit_street_address} name="remit_street_address" readOnly={edit} onChange={handleChange} />
                    </div>
                    
                </div>
                <div class="form-row">
                <div class="col-md-4 mb-3">
                        <label class="form-label colorwhite">Remit City</label>
                        <input type="text" class="form-control" value={state.remit_city} name="remit_city" readOnly={edit} onChange={handleChange} />
                    </div>
                    <div className="form-group col-md-4 mb-3">
                            <label className="form-label colorwhite" for="inputState">State</label>
                            <select className="form-control" value={state.remit_state} name="remit_state" readOnly={edit} onChange={handleChange} >
                                <option value="">Choose...</option>
                            
                                    <optgroup label="U.S. States/Territories">
                                    <option value="AK">AK - Alaska</option>
                                    <option value="AL">AL - Alabama</option>
                                    <option value="AR">AR - Arkansas</option>
                                    <option value="AZ">AZ - Arizona</option>
                                    <option value="CA">CA - California</option>
                                    <option value="CO">CO - Colorado</option>
                                    <option value="CT">CT - Connecticut</option>
                                    <option value="DC">DC - District of Columbia</option>
                                    <option value="DE">DE - Delaware</option>
                                    <option value="FL">FL - Florida</option>
                                    <option value="GA">GA - Georgia</option>
                                    <option value="HI">HI - Hawaii</option>
                                    <option value="IA">IA - Iowa</option>
                                    <option value="ID">ID - Idaho</option>
                                    <option value="IL">IL - Illinois</option>
                                    <option value="IN">IN - Indiana</option>
                                    <option value="KS">KS - Kansas</option>
                                    <option value="KY">KY - Kentucky</option>
                                    <option value="LA">LA - Louisiana</option>
                                    <option value="MA">MA - Massachusetts</option>
                                    <option value="MD">MD - Maryland</option>
                                    <option value="ME">ME - Maine</option>
                                    <option value="MI">MI - Michigan</option>
                                    <option value="MN">MN - Minnesota</option>
                                    <option value="MO">MO - Missouri</option>
                                    <option value="MS">MS - Mississippi</option>
                                    <option value="MT">MT - Montana</option>
                                    <option value="NC">NC - North Carolina</option>
                                    <option value="ND">ND - North Dakota</option>
                                    <option value="NE">NE - Nebraska</option>
                                    <option value="NH">NH - New Hampshire</option>
                                    <option value="NJ">NJ - New Jersey</option>
                                    <option value="NM">NM - New Mexico</option>
                                    <option value="NV">NV - Nevada</option>
                                    <option value="NY">NY - New York</option>
                                    <option value="OH">OH - Ohio</option>
                                    <option value="OK">OK - Oklahoma</option>
                                    <option value="OR">OR - Oregon</option>
                                    <option value="PA">PA - Pennsylvania</option>
                                    <option value="PR">PR - Puerto Rico</option>
                                    <option value="RI">RI - Rhode Island</option>
                                    <option value="SC">SC - South Carolina</option>
                                    <option value="SD">SD - South Dakota</option>
                                    <option value="TN">TN - Tennessee</option>
                                    <option value="TX">TX - Texas</option>
                                    <option value="UT">UT - Utah</option>
                                    <option value="VA">VA - Virginia</option>
                                    <option value="VT">VT - Vermont</option>
                                    <option value="WA">WA - Washington</option>
                                    <option value="WI">WI - Wisconsin</option>
                                    <option value="WV">WV - West Virginia</option>
                                    <option value="WY">WY - Wyoming</option>
                                </optgroup>
                                <optgroup label="Canadian Provinces">
                                    <option value="AB">AB - Alberta</option>
                                    <option value="BC">BC - British Columbia</option>
                                    <option value="MB">MB - Manitoba</option>
                                    <option value="NB">NB - New Brunswick</option>
                                    <option value="NF">NF - Newfoundland</option>
                                    <option value="NT">NT - Northwest Territories</option>
                                    <option value="NS">NS - Nova Scotia</option>
                                    <option value="NU">NU - Nunavut</option>
                                    <option value="ON">ON - Ontario</option>
                                    <option value="PE">PE - Prince Edward Island</option>
                                    <option value="QC">QC - Quebec</option>
                                    <option value="SK">SK - Saskatchewan</option>
                                    <option value="YT">YT - Yukon Territory</option>
                                
                                </optgroup>
                            </select>
                        </div>


                    <div class="col-md-4 mb-3">
                        <label class="form-label colorwhite">Remit Zipcode</label>
                        <input type="text" class="form-control" value={state.remit_zipcode} name="remit_zipcode" readOnly={edit} onChange={handleChange} />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label colorwhite">Remit Phone Number</label>
                        <input type="text" class="form-control" value={state.remit_phone_number} name="remit_phone_number" readOnly={edit} onChange={handleChange} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label colorwhite">Remit Email</label>
                        <input type="text" class="form-control" value={state.remit_email} name="remit_email" readOnly={edit} onChange={handleChange} />
                    </div>
                    
                </div>
                {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
               
            </form>
            </div>

        </div>

        </>
    )

}

