import React, { useEffect, useState } from "react";
import { useAuth, login } from "../../auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect
} from "react-router-dom";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import SignUpWizard from "../SignUp/SignUpWizard";



const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    If your company already has an existing account, you will need to get this invite code from one of the company administators.
  </Tooltip>
);

export default function SignUp(props) {
  const [openRegistration, setOpenRegistration] = useState(false)

  const [existingCompany, setExistingCompany] = useState(false)

  const history = useHistory();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [inviteCode, setInviteCode] = useState('')

  const [state, setState] = useState('')

  const [dotNumber, setDOTNumber] = useState()
  const [companyDetails, setCompanyDetails] = useState({})

  const [errorEmail, setErrorEmail] = useState('')
  const [errorInviteCode, setErrorInviteCode] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('')
  const [logged] = useAuth();
  const onSubmitClick = (e) => {
    e.preventDefault()

    if (checkPassword(password)) {
      if (password !== confirmPassword) {
        setErrorConfirmPassword('Error dont match')
      }
      else {
        let opts = {
          "email": email.toLowerCase(),
          "hashed_password": password,
          "first_name": firstName,
          "last_name": lastName,
          "invite_code": inviteCode,
        }
        fetch('https://fltbd.herokuapp.com/registration/signup', {
          method: 'PUT',
          body: JSON.stringify(opts),
          headers: { 'Content-Type': 'application/json' },
        }).then(r => r.json())
          .then(data => {
            if (data.error_code === '5021') {
              setErrorEmail(data.error)
            }
            if (data.error_code === '5015') {
              setErrorInviteCode(data.error)
            }

          })
      }
    }
    else {
      setErrorPassword('The password format is not correct..')
    }


  }


  const handleExistingCompany = () => {
    setExistingCompany(!existingCompany)
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }
  const handleFirstName = (e) => {
    setFirstName(e.target.value)
  }
  const handleLastName = (e) => {
    setLastName(e.target.value)
  }
  const handleInviteCode = (e) => {
    setInviteCode(e.target.value)
  }
  const changegExistingCompany = () => {
    setExistingCompany(!existingCompany);
  }

  const changeDOTNumber = (e) => {
    setDOTNumber(e.target.value)
  }

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,32}$/;
    return re.test(str);
  }
  function viewPassword(e) {
    var x = document.getElementById("passwordField");

    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  function viewConfirmPassword(e) {
    var x = document.getElementById("confirmPasswordField");

    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  } useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (

    <div class="container-xl">

      <div class="container-tight py-4">
        <div class="text-center mb-4">

          <h1>Sign Up</h1>
          <SignUpWizard />

        </div>
        
      </div>
    </div>
  )
}