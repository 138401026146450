import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../auth";
import SettingsConfirmation from "./SettingsConfirmation";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link,
    useHistory,
    useRouteMatch,
    useParams
} from "react-router-dom";
import CompanyContract from "./SettingsContract";
import SettingsSidebar from "./SettingsSidebar";
import ChangePassword from "./SettingsPassword";
import ManageUsers from "./SettingsManageUsers";
import SettingsContract from "./SettingsContract";
import SettingsPassword from "./SettingsPassword";
import SettingsManageUsers from "./SettingsManageUsers";
import SettingsUserItem from "./SettingsUserItem";
import SettingsAddUser from "./SettingsAddUser";
import SettingsUploadLocations from "./SettingsUploadLocations";
import SettingsUploadCustomers from "./SettingsUploadCustomers";
import SettingsUploadCarriers from "./SettingsUploadCarriers";
import SettingsLoadNumber from "./SettingsLoadNumber";
import SettingsSubscription from "./SettingsSubscription";
import io from "socket.io-client"
import SettingsCompanyInformation from "./SettingsCompanyInformation";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";

function SettingsHeader() {
    return (
        <div class="d-sm-flex">
            <h1>Settings</h1>
        </div>
    )
}



export default function SettingsContainer(props) {

    const ENDPOINT = "https://fltbd.herokuapp.com";
    const socket = io(ENDPOINT);

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(true);
    const [userRole, setUserRole] = useState('');
    const [employees, setEmployees] = useState([]);
    const [stripe, setStripe] = useState(null);
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [room, setRoom] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    useEffect(() => {
      // Listen for the 'joined' event
      socket.on("joined", (data) => {
        console.log(data);
      });
  
      // Listen for the 'handle_stripe' event
      socket.on("handle_stripe", (data) => {
        setMessages((messages) => [...messages, data]);
      });
  
      // Cleanup on component unmount
      return () => {
        socket.disconnect();
      };
    }, []);

    useEffect(() => {
        socket.on('message', (msg) => {
          setMessages([...messages, msg]);
          console.log(msg)
        });
 
        return () => {
          socket.off('message');
        };
      }, [messages]);
 
      const handleSend = () => {
        socket.send(message);  // Send a simple message
        // socket.emit('custom_event', { data: message }); // Send a custom event
        setMessage('');
      };
  
    // Function to emit the 'handle_stripe' event
    const emitHandleStripe = () => {
      const data = {
        data: {
          object: {
            customer: room // Set this to the room or customer ID appropriate to your use case
          }
        }
      };
  
      // Emit the event to the server
      socket.emit("handle_stripe", data);
    };
    const handleStatus = (data) => {
        if(data.Metadata.company_id !== props.id) {
            //props.id is coming undefined.

            setStatus(data.Metadata.contract_id)
        }
        else {

            setStatus(data.Metadata.contract_id)
        }
    }



    const updateList = (List) => {
        const dataCopy = [...props.employees]
        props.updateEmployeeList([...dataCopy, List])
    }
    const deleteEmployee = (Employee) => {
        const dataCopy = [...props.employees]
        const removeEmployee = dataCopy.filter((a) => Employee !== a.user_id)
        props.updateEmployeeList(removeEmployee)
    }

    const handleUpdateEmployee = (Details) => {
        props.updateEmployee(Details)
    }

    useEffect(() => {
        if (props && props.roles) {
            setUserRole(props.roles)
        }
    }, [props.roles])
    useEffect(() => {
        if (props && props.employees) {
            setEmployees(props.employees)
            setShow(false)
            setLoading(false)
        }
    }, [props.employees])
    useEffect(() => {
        if (props && props.stripe) {
            if(props && props.stripe.length !== 0) {
                setStripe(props.stripe)
                setRoom(props.stripe[0].stripe_customer_id)
                socket.emit('join', { username: props.stripe[0].stripe_customer_id, room: props.stripe[0].stripe_customer_id });
    
            }
            else {
                setRoom("Not Set")

            }
           
            
            
        }
    }, [props.stripe])
    useEffect(() => {
        if (props && props.email) {
            setEmail(props.email)
        }
    }, [props.email])

    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    return (
        <>
       
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
        {3===3 ? null :
            <>
        
        <h1>Socket.IO Chat</h1>
         <input
           value={message}
           onChange={(e) => setMessage(e.target.value)}
           placeholder="Enter a message"
         />
         <button onClick={handleSend}>Send</button>
         </>
        }
         <ul>
           {messages.map((msg, index) => (
             <li key={index}>{msg}</li>
           ))}
         </ul>
            <Router history={history}>
                <SettingsHeader />
                <div class="row row-cards" style={{ paddingTop: '0px' }}>
                    <SettingsSidebar  {...props} />
                    <div class="col-lg-9">
                            {status}
                        <Switch>
                            <Route path="/settings/subscription"
                                render={(props) => <SettingsSubscription email={email} stripe={stripe} {...props} userRole={userRole} employees={employees} title="RateConfirmation - Subscription" />} />

                            <Route path="/settings/manage-users"
                                render={() => <SettingsManageUsers  {...props} userRole={userRole} employees={employees} title="RateConfirmation - Manage Users" />} />

                            <Route path="/settings/manage-user/add"
                                render={(props) => <SettingsAddUser updateList={updateList} {...props} userRole={userRole} employees={employees} title="RateConfirmation - Add User" />} />

                            <Route path="/settings/manage-user/:user_id"
                                render={(props) => <SettingsUserItem {...props} updateEmployee={handleUpdateEmployee} deleteEmployee={deleteEmployee} userRole={userRole} employees={employees} />} />

                            <Route path="/settings/change-password"
                                render={(props) => <SettingsPassword {...props} userRole={userRole} employees={employees} title="RateConfirmation - Change Password" />} />


                            <Route path="/settings/company-information">
                                <SettingsCompanyInformation {...props} userRole={userRole} title="RateConfirmation - Company Information" />
                            </Route>
                            <Route path="/settings/contract-settings">
                                <SettingsContract {...props} userRole={userRole} title="RateConfirmation - Contract Settings" />
                            </Route>


                            <Route path="/settings/confirmation-settings">
                                <SettingsConfirmation {...props} userRole={userRole} title="RateConfirmation - Confirmation Settings" />
                            </Route>

                            <Route path="/settings/load-number-settings">
                                <SettingsLoadNumber {...props} userRole={userRole} title="RateConfirmation - Load Number Settings" />
                            </Route>

                            <Route path="/settings/upload-locations"
                                render={(props) => <SettingsUploadLocations {...props} userRole={userRole} />} />

                            <Route path="/settings/upload-carriers">
                                <SettingsUploadCarriers {...props} userRole={userRole} />
                            </Route>

                            <Route path="/settings/upload-customers">
                                <SettingsUploadCustomers {...props} userRole={userRole} />
                            </Route>

                            <Route path="*">

                                <Redirect to='/settings/subscription' />
                            </Route>

                        </Switch>



                    </div>
                </div>
            </Router>

        </div>
        {loading && (
            <Modal show={show} size="sm" centered>
                <LoadingModal />
            </Modal>
        )}
        </>

    )
}