
import React, { useMemo, useState, useEffect } from "react";
import { useHistory, withRouter, useParams } from "react-router-dom";
import CustomersItemCustomerDetails from './CustomersItemCustomerDetails';
import CustomersItemBillingDetails from './CustomersItemBillingDetails';
import CustomerNotes from './CustomerNotes';
import CustomerDelete from './Modals/CustomerDelete';
import NotFound from "../Home/NotFound";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";
import CustomersContact from "./CustomersContact";
import CustomersNotes from "./CustomersNotes";


export default function CustomerItem(props) {
    const params = useParams();
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true)
    const [data, setData] = useState()
    const [notFound, setNotFound] = useState(false)


    useEffect(() => {
        if (props && props.customers) {
            const customerInfo = props.customers.filter(customer => customer.customer_id == params.customer_id)
            setData(customerInfo[0])
            if (customerInfo[0] === undefined) {
                setNotFound(true)
            }
            setShow(false)
            setLoading(false)
        }
    }, [props.customers])

    const handleCustomerDetails = (Details) => {
        setData(prevData => ({ ...prevData, ...Details }));
    }
    const handleNotesCallback = (NotesData) => {
        setData({ ...data, customer_notes: NotesData })
    }


    if (notFound) {
        return (
            <NotFound />
        )
    }

    return (
        <>
            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                <div class="d-sm-flex">
                    <h1>Manage Customer</h1>
                </div>


                <div class="row">
                    <div class="col-md-8">
                        <CustomersItemCustomerDetails
                            {...data}
                            updateSingle={props.updateSingle}
                            updateCustomer={props.updateCustomer}
                            parentCustomerDetails={handleCustomerDetails}
                        />
                        <CustomersItemBillingDetails
                            {...data}
                            updateSingle={props.updateSingle}
                            updateCustomer={props.updateCustomer}
                            parentCustomerDetails={handleCustomerDetails}
                        />
                         {data && <CustomersNotes
                            addNote={props.addNote}  
                            removeNote={props.removeNote}
                            type_id={params.customer_id}
                            notes={data.notes}
                            contacts={data.contacts}
                        /> }
                    </div>
                    <div class="col-md-4">
                    {data && <CustomersContact
                    addContact={props.addContact}
                    removeContact={props.removeContact} 
                    updateCustomer={props.updateCustomer}
                    contacts={data.contacts} type_id={params.customer_id} />}
    
                     
                        <div>
                            {props.roles == "agent" ?
                                <button className="btn btn-danger btn-uppercase mt-3 float-right" disabled>Delete Customer</button>
                                :
                                <CustomerDelete


                                    {...props}
                                    {...data}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loading && (
                <Modal show={show} size="sm" centered>
                    <LoadingModal />
                </Modal>
            )}
        </>
    )

}