import React, { useEffect, useState } from "react";
import { authFetch } from "../../auth";
import { Alert, Modal } from "react-bootstrap";
import UseAlert from '../Alerts/UseAlerts';


export default function SettingsLoadNumber(props) {
    const {
        alertState,
        showSuccessAlert,
        showFailAlert,
        showNoChangeAlert
    } = UseAlert();

    const [loadNumber, setLoadNumber] = useState()
    const [show, setShow] = useState(true);
    const [edit, setEdit] = useState(true)
    const [loaded, setLoaded] = useState(false)
    


    const handleSubmit = (e) => {
        e.preventDefault()
        authFetch(`https://fltbd.herokuapp.com/api/companys/${props.id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                current_load_number: loadNumber,
            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(r => r.json())
        .then(data => {
            if (data.status === 200) {
                showSuccessAlert("Success: Load Number Updated Successfully.")
                changeEdit()
                props.updateCompany({current_load_number:parseInt(loadNumber)})
            }
        })

    }
    const changeEdit = () => {
        setEdit(!edit);
    }
   

    const handleChange = e => {
        setLoadNumber(e.target.value)
        
    }

    useEffect(() => {
        if (props && props.current_load_number === null) {
            setLoadNumber(props.starting_load_number)

        }
        else {
            setLoadNumber(props.current_load_number)

        }
    }, [props.current_load_number])

    useEffect(() => {
        if (loadNumber !== undefined) {
           
        }
    }, [loadNumber])
    useEffect(() => {
        if (props && props.userRole) {
            setLoaded(true)
        }
    }, [props.userRole])


    if (loaded && !props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Load Number Settings</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }

    return (
        <>
        
            <div className="card">
                <div class="card-header">
                    <h3 class="card-title">Load Number Settings</h3>
                    <div class="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>
                </div>
                </div>
                <div className="card-body">
                {alertState.show && (
                    <Alert variant={`${alertState.type}`}>
                        {alertState.message}
                    </Alert>
                )}
             
                    <p className="colorwhite">Your current load number is:</p>

                    <div className="d-flex justify-content-center">
                        <input className="form-control" type="num" value={loadNumber} name="current_load_number" onChange={handleChange} readOnly={edit} style={{width:"50%"}} />
                    </div>

                    <form onSubmit={handleSubmit} className="container">

                        <div className="mt-4">
                        {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-primary float-right">Make Changes</button>
                        </div>
                        : null}
                        </div>
                    </form>
                </div>
            </div>
         
        </>
    )

}

