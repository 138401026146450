import React, { useEffect, useState } from "react";
import Select from 'react-select'
import DatePicker from "react-datepicker"
import moment from 'moment';


const CustomerInfoEdit = (props) => {
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState)

    const termsOptions = [
        { value: 'Net 60', label: 'Net 60' },
        { value: 'Net 45', label: 'Net 45' },
        { value: 'Net 30', label: 'Net 30' },
        { value: 'Net 15', label: 'Net 15' },
        { value: 'Net 10', label: 'Net 10' },
        { value: 'Due On Receipt', label: 'Due On Receipt' },
    ]
    const statusOptions = [
        { value: 'Invoiced', label: 'Invoiced' },
        { value: 'Paid', label: 'Paid' },
        { value: 'Past Due', label: 'Past Due' },
    ]

    function customerChange(e) {
        // Check if billing_street_address is null or undefined, set to "123 St" if so
        if (e.billing_street_address == null) {
            e = {
                ...e, customer_billing_street_address: e.customer_street_address,
                customer_billing_city: e.customer_city,
                customer_billing_state: e.customer_state,
                customer_billing_zipcode: e.customer_zipcode,
                customer_billing_phone_number: e.phone_number,
                customer_billing_email: e.email
            };
        }
        else {
            e = {
                ...e, customer_billing_street_address: e.billing_street_address,
                customer_billing_city: e.billing_city,
                customer_billing_state: e.billing_state,
                customer_billing_zipcode: e.billing_zipcode,
                customer_billing_phone_number: e.phone_number,
                customer_billing_email: e.email
            };
        }

        // Update the state with possibly modified event e
        setState(prevState => {
            return { ...prevState, ...e };
        });

        // Call the parent component's handle function with possibly modified event e
        props.handleCustomerChange(e);
    }
    const handleChange = (e) => {
        
        props.handleDetailChange(e)
    }

    function handleChange2(e) {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        props.updateCustomer({ ...state, [name]: value })

    }

    useEffect(() => {
        if (props && props.customers) {
            const options = props.customers.map(d => ({
                "id": d.id,
                "customer_name": d.customer_name,
                "customer_id": d.customer_id,
                "customer_street_address": d.customer_street_address,
                "customer_city": d.customer_city,
                "customer_state": d.customer_state,
                "customer_zipcode": d.customer_zipcode,
                "phone_number": d.phone_number,
                "email": d.email,
                "billing_street_address": d.billing_street_address,
                "billing_city": d.billing_city,
                "billing_state": d.billing_state,
                "billing_zipcode": d.billing_zipcode,
                "billing_email": d.billing_email,
                "billing_phone_number": d.billing_phone_number,
                "terms": d.terms,
            }))
            setState({ customers: options })
        }

    }, [props.customers])

    useEffect(() => {
        if (props && props.customer_name && props.customers) {
            const customer = props.customers.filter(customer => customer.customer_name == props.customer_name)
            if (customer && customer.length) {
            // Check if billing_street_address is null or undefined, set to customer's details if so
            if (customer[0].billing_street_address == null) {
                customer[0] = {
                    ...customer[0],
                    billing_street_address: customer[0].customer_street_address,
                    billing_city: customer[0].customer_city,
                    billing_state: customer[0].customer_state,
                    billing_zipcode: customer[0].customer_zipcode,
                    billing_phone_number: customer[0].phone_number,
                    billing_email: customer[0].email
                };
            }
        }

            setState(prevState => {
                return { ...prevState, ...customer[0] };
            });

        }

    }, [props.customer_name, props.customers])

    useEffect(() => {
        if (props && props.date) {


        }
    }, [props.date])


    function handleTerms(e) {
        setState({ ...state, terms: e.value })

        props.updateCustomer({ ...state, terms: e.value })
    }
    function handleDateChange(date, name) {

        props.handleDateChange(date, name)
    }

    const updateInvoiceNote = (e) => {
        setState({ ...state, invoice_note: e.target.value });
        props.handleChange(e)

    }
    const updateRefNumber = (e) => {
        setState({ ...state, ref_number: e.target.value });
        props.handleChange(e)
    }

    return (
        <>
            <div className="row">
                <div class="col-6">
                    <div class="mb-3">
                        <label class="form-label colorwheat">Customer Name</label>
                        <Select
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.customer_name}
                            isDisabled={!props.edit}
                            options={state.customers}
                            placeholder={props.customer_name}
                            name="customer_name" onChange={(e) => customerChange(e)} />
                    </div>
                    <div class="mb-3">
                        <label class="form-label colorwheat">Invoice Note</label>
                        <textarea className="form-control" value={props.invoice_note} name="invoice_note" type="text" placeholder="This will show up on the invoice" readOnly={!props.edit} onChange={(e) => updateInvoiceNote(e)} />

                    </div>
                    <div class="mb-3">
                        <label class="form-label colorwheat">Terms</label>
                        <Select options={termsOptions}
                            placeholder={props.terms}
                            value={props.terms}
                            name="terms"
                            onChange={(e) => handleTerms(e)}
                            isDisabled={!props.edit}
                        />
                    </div>
                    <div className="row">
                    <div class="col">
                    <div class="mb-3">
                        <label class="form-label colorwheat">Invoice Date</label>

                        <DatePicker
                            selected={moment(props.date).toDate()}
                            name="date"
                            className="form-control"
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => handleDateChange(date, "date")}
                            readOnly={!props.edit}
                        />

                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <label class="form-label colorwheat">Due Date</label>

                        <DatePicker
                            selected={moment(props.due_date).toDate()}
                            name="due_date"
                            className="form-control"
                            dateFormat="MM/dd/yyyy "
                            onChange={(date) => handleDateChange(date, "due_date")}
                            readOnly={true}
                        />
                    </div>
                </div>
                    </div>



                </div>
            
                <div className="col-6">
                    <label className="form-label colorwheat">Billing Details</label>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Address</span>
                        <input type="text" className="form-control" placeholder="Address" name="customer_billing_street_address" value={props.customer_billing_street_address} readOnly={!props.edit} onChange={handleChange} />
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text">City</span>
                        <input type="text" className="form-control" placeholder="City" name ="customer_billing_city" value={props.customer_billing_city} readOnly={!props.edit} onChange={handleChange} />
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text">State</span>
                        <input type="text" className="form-control" placeholder="State" name="customer_billing_state" value={props.customer_billing_state} readOnly={!props.edit} onChange={handleChange}/>
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Zipcode</span>
                        <input type="text" className="form-control" placeholder="Zipcode" name="customer_billing_zipcode" value={props.customer_billing_zipcode} readOnly={!props.edit} onChange={handleChange}/>
                    </div>

                    <label className="form-label mt-3 colorwheat">Billing Email</label>

                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Email" name="customer_billing_email" value={props.customer_billing_email} readOnly={!props.edit} onChange={handleChange}/>
                    </div>

                </div>
            </div>
            <div className="row mt-3">
                
                <div class="col-6">
                    <div class="mb-3">
                        <label class="form-label colorwheat">Status</label>

                        <Select
                            placeholder={props.status}
                            name="status"
                            isDisabled={!props.edit}
                            value={props.status}
                            options={statusOptions}
                            onChange={handleChange}

                        />
                    </div>
                </div>
                <div class="col-6">
                    <label className="form-label colorwheat">Reference Number</label>
                    <input className="form-control" type="text" value={props.ref_number} name="ref_number" placeholder="Not Required" onChange={(e) => updateRefNumber(e)} />
                </div>


            </div>
        </>
    )
}

export default CustomerInfoEdit