/* eslint-disable */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
  useSortBy
} from "react-table";
import { matchSorter } from "match-sorter";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
  useHistory
} from "react-router-dom"
import Select from 'react-select'



const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      /* NOT  NEEDED 
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 0px solid black;
      */

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  input {
      width: 50%;
      
  }
  btn {
    padding: .5rem .5rem;
  }

`
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])


    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ statusOptions, parentDeleteList, parentPatchList, columns, data, parentHideCompleted }) {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );







  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds, },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter
  } = useTable(
    {
      columns,
      data,// Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        hiddenColumns: ['Customer'],
        selectedRowIds: [],
        pageIndex: 0,
        sortBy: [
          { id: 'loadnumber', desc: true }
        ]
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,


  );




  // We don't want to render all of the rows for this example, so cap
  // it for this use case

  return (
    <>
      <div className="card">
        <div className="table-responsive">
          <table className="table-responsive card-table table-vcenter" style={{width: "100%", borderSpacing: "0"}} {...getTableProps()}>
            <thead>


              <tr>
                <th
                  className="reactTable-th"
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "left"
                  }}
                >

                  <span className="colorwhite">Search: </span>
                  <input
                    type="text"
                    className="secondarycolorbg colorwhite"
                    value={globalFilter || ""}
                    placeholder="Search By Carrier, Load #, Locations, etc"
                    onChange={e => setGlobalFilter(e.target.value)}
                    style={{
                      fontSize: "1.1rem",
                      border: "0",
                      width: "100%"
                    }}
                  />
                </th>
              </tr>
              {headerGroups.map((headerGroup) => (
                <tr style={{ backgroundColor: "#f6e2b3" }} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="reactTable-th" {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>

              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <>

                    <tr style={{ cursor: "pointer" }}  {...row.getRowProps()}>

                      {row.cells.map((cell) => {
                        return (
                          <td className="reactTable-td" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        );
                      })}
                    </tr>

                  </>
                );

              })}
              <div style={{ paddingTop: "Can be changed to a value, will allow last row status to show" }}>
                {''}
              </div>
            </tbody>
            <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
          </table>
        </div>
        <div class="row m-3">
          <div class="col">
            <div className="pagination">
              <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'< Previous'}
              </button>
              <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={() => nextPage()} disabled={!canNextPage}>
                {'Next >'}
              </button>
            </div>
          </div>
          <div class="col d-none d-sm-block colorwhite">
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
          </div>
          <div class="col d-none d-sm-block">
            <select
              class="form-select"

              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50,100,200,500].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>


        </div>
        <br />



      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";


const Actions = ({ actions }) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <>
      {actions.map((action, idx) => {
        return (
          <span key={idx} className="badge">
            {action}
          </span>
        );
      })}
    </>
  );
};

function ReportsTable(props) {
  const [data, setData] = useState([]);
  const [selectedLoad, setSelectedLoad] = useState();

  const columns = React.useMemo(
    () => {
      const baseColumns = [
        {
          Header: "Load #",
          id: "loadnumber",
          accessor: "load_number",
          Footer: info => {
            return (
              <>
              {info.data.length > 0 ?
                <b className="colorwheat">
                Total
                </b>
                :
                null}
              </>
            )
          },
        },
        {
          Header: "Carrier",
          accessor: "carrier_name",
          filter: "fuzzyText"
        },
        {
          Header: "Trailer",
          id: "trailer",
          accessor: "trailer",
        },
        {
          Header: "Customer",
          id: 'Customer',
          accessor: "customer_name"
        },
        {
          Header: "Shippers",
          id: "shippers",
          accessor: row => {
            if (row && row.actions) {
              let sortActions = row.actions.sort((a, b) => a.order - b.order)
              return sortActions.map((a) => {
                if (a.action == 's') {
                  if (a.city == null || a.state == null) {
                    return
                  }
                  const lineBreak = "\n"
                  const cityState = a.city + " " + a.state + " " + lineBreak
                  return (cityState)
                }
                else {
                  return
                }
              })
            }
          },
          Cell: (row) => (
            <div style={{ whiteSpace: "pre-line" }}>
              {row.cell.value === null ? null : row.cell.value}
            </div>
          ),
        },
        {
          Header: "Receivers",
          id: "receivers",
          accessor: row => {
            if (row && row.actions) {
              let sortActions = row.actions.sort((a, b) => a.order - b.order)
              return sortActions.map((a) => {
                if (a.action == 'r') {
                  if (a.city == null || a.state == null) {
                    return
                  }
                  const lineBreak = "\n"
                  const cityState = a.city + " " + a.state + " " + lineBreak
                  return (cityState)
                }
                else {
                  return
                }
              })
            }
            return
          },
          Cell: (row) => (
            <div style={{ whiteSpace: "pre-line" }}>
              {row.cell.value === null ? null : row.cell.value}
            </div>
          )
        },
        {
          Header: "Carrier Pay",
          disableSortBy: true,
          accessor: "carrier_pay",
          Cell: (row) => (
            <div>
              {row.cell.value === null || row.cell.value === undefined ? null : '$' + row.cell.value}
            </div>
          ),
          Footer: info => {
            const total = React.useMemo(
              () =>
                info.page.reduce((sum, row) => row.values.carrier_pay + sum, 0),
              [info.page]
            )
            return (
              <>
              {info.data.length > 0 ?
                <b className="colorwheat">
                ${total}
                </b>
                :
                null}
              </>
            )
          },
        },
      ];

      const payColumns = [
        {
          Header: "Gross Pay",
          disableSortBy: true,
          accessor: "gross_pay",
          Cell: (row) => (
            <div>
              {row.cell.value === null || row.cell.value === undefined ? null : '$' + row.cell.value}
            </div>
          ),
          Footer: info => {
            const total = React.useMemo(
              () =>
                info.page.reduce((sum, row) => row.values.gross_pay + sum, 0),
              [info.page]
            )
            return (
              <>
              {info.data.length > 0 ?
                <b className="colorwheat">
                ${total}
                </b>
                :
                null}
              </>
            )
          },
        },
        
      ];

      const actionColumn = {
        Header: "",
        id: "load_id",
        disableSortBy: true,
        accessor: "load_id",
        Cell: (row) => (
          <div>
            <Link to={`load_documents/${row.cell.value}`}>
              <button className="btn btn-icon btn-info">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" /><line x1="8" y1="8" x2="12" y2="8" /><line x1="8" y1="12" x2="12" y2="12" /><line x1="8" y1="16" x2="12" y2="16" /></svg>
              </button>
            </Link>
          </div>
        )
      };

      return props.showPayColumns 
        ? [...baseColumns, ...payColumns, actionColumn]
        : [...baseColumns, actionColumn];
    },
    [data, props.showPayColumns]
  );

  const history = useHistory();

  useEffect(() => {
    if (props && props.data) {
      setData(props.data)
    }
  }, [props.data])

  return (
    <Styles>
      <Table
        columns={columns}
        data={data}
      />
    </Styles>
  );
}

export default ReportsTable;