import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../../auth"
import {
    useHistory,
    useParams
} from "react-router-dom";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../../Modals/LoadingModal";
import ErrorModal from "../../Modals/ErrorModal";



const ItemsInfo = (props) => {
    const [loading, setLoading] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [show, setShow] = useState(false);
    

    const params = useParams();
    const history = useHistory();
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);
    const [data, setData] = useState()
    const [totalAmount, setTotalAmount] = useState()

    const createDescription = (shippers, receivers) => {

        const makeShippers = shippers && shippers.map((shipper) => {
            return shipper.city + " " + shipper.state
        }
        )
        const makeReceivers = receivers && receivers.map((receiver) => {
            return receiver.city + " " + receiver.state
        }
        )
        return (makeShippers + " to " + makeReceivers)

    }

    const handleChange = (idx) => (e) => {
        const update = data.rows.map((item, sidx) => {
            if (idx !== sidx)
                return item;
            return {
                ...item,
                [e.target.name]: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value
            }
        })
        setData({ ...data, rows: update })
    }
    const handleAddRow = () => {
        const newItem = {
            description: "",
            quantity: 1,
            amount: 0,
        };
        setData({
            rows: [...data.rows, newItem]
        });
    };
    const handleRemoveSpecificRow = (idx) => () => {
        const rows = [...data.rows]
        rows.splice(idx, 1)
        setData({ rows })
    }

    useEffect(() => {
        if (props && props.actions) {
            const shippers = props.actions.filter(action => action.action == 's')
            const receivers = props.actions.filter(action => action.action == 'r')

            setState(prevState => {
                return { ...prevState, shippers: shippers, receivers: receivers };
            });

            setData({
                rows: [{
                    description: "Linehaul",
                    quantity: 1,
                    amount: props.gross_pay
                }]
            })
        }
        else {
            setData({
                rows: [{
                    description: "",
                    quantity: 1,
                    amount: 0
                }]
            })
        }
    }, [props.actions])

    useEffect(() => {
        if (props && props.ref_number) {
            setState(prevState => {
                return { ...prevState, ref_number: props.ref_number };
            });
        }
    }, [props.ref_number])

    useEffect(() => {
        if (data && data.rows) {
            setTotalAmount(data.rows.reduce((a, v) => a = a + v.quantity * v.amount, 0))
        }
    }, [data])



    const handleSubmit = async (e) => {
        e.preventDefault();
        props.updateLoading(true);
    
        const uniqID = uuidv4();
        const newInvoice = {
            invoice_id: props.load_id,
            invoice_number: props.invoice_number,
            date: moment(props.date).format('l'),
            due_date: moment(props.due_date).format('l'),
            customer_id: props.customer?.id,
            customer_name: props.customer?.customer_name,
            customer_billing_street_address: props.customer?.billing_street_address,
            customer_billing_city: props.customer?.billing_city,
            customer_billing_state: props.customer?.billing_state,
            customer_billing_zipcode: props.customer?.billing_zipcode,
            customer_billing_phone_number: props.customer?.billing_phone_number,
            customer_billing_email: props.customer?.billing_email,
            customer_terms: "Net 30",
            items: [data.rows],
            total: totalAmount,
            invoice_note: props.invoice_note,
            ref_number: state.ref_number,
            status: "Invoiced",
            terms: props.customer?.terms,
            load_id: params.invoice_id,
        };
    
        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/api/invoice`, {
                method: 'PUT',
                body: JSON.stringify(newInvoice),
                headers: { 
                    'Content-Type': 'application/json', 
                    
                },
            });
    
            if (!response.ok) {
                // here we specifically check if the error code is 401 (Authorization error)
                
                if(response.status === 304) {
                    console.log(response)
                }
                if(response.status === 401) {
                    throw new Error('Authorization error: JWT token not found in headers');
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                const json = await response.json();
                if(json.status === 200){
                    if(props.invoice_id !== json.action) {
                        newInvoice.invoice_id = json.action
                    }
                    newInvoice.items = data.rows

                    props.updateLoading(false);
                    history.push({
                        pathname: `/accounting/invoice/${props.load_id}`,
                      })
                    props.handleUpdate(newInvoice, json.action);
                }else{

                    props.updateLoading(false);
                    props.updateError(true)
                    setErrorModal(true);
                }
            }
        } catch (error) {
            props.updateLoading(false);
            props.updateError(true)
            //setErrorModal(true);
        }
    }
    const updateRefNumber = (e) => {
        setState({ ...state, ref_number: e.target.value });
    }
    const updateInvoiceNote = (e) => {
        setState({ ...state, invoice_note: e.target.value });
    }

    return (
        <>

   

            

           
            <div className="row">
                <div class="col-6">
                   

                </div>
                <div class="col-6">
                    <label className="form-label">Reference Number</label>
                    <input className="form-control" type="text" value={state.ref_number} placeholder="Not Required" onChange={(e) => updateRefNumber(e)} />
                </div>
            </div>

            <h3 className="colorwhite">Items</h3>
            {3 == 3 ?
                null
                : ""
                //{createTableInfo(state.shippers, state.receivers)}
            }
            <div class="table-responsive">

                <table>
                    <thead>
                        <tr style={{ borderTop: "1px solid wheat", backgroundColor:"wheat" }}>

                            <th className="reactTable-th w-75" style={{ borderBottom: "1px solid wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Description</th>
                            <th style={{ borderBottom: "1px solid wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Quantity</th>
                            <th style={{ borderBottom: "1px solid wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Amount</th>
                            <th style={{ borderBottom: "1px solid wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}></th>

                        </tr>
                    </thead>
                    {data && data.rows.map((item, idx) =>
                        <tr key={idx}>
                            <td className="w-75">
                                <input className="form-control" name="description" value={item.description} onChange={handleChange(idx)} />
                            </td>
                            <td>
                                <input className="form-control" type="num" name="quantity" value={item.quantity} onChange={handleChange(idx)} />
                            </td>

                            <td>
                                <input className="form-control" type="number" name="amount" value={item.amount} onChange={handleChange(idx)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={handleRemoveSpecificRow(idx)}
                                >
                                    Remove
                                </button>
                            </td>
                            <br />
                            <br />
                            <br />


                        </tr>



                    )}

                </table>
                <h4 className="float-right colorwhite">Subtotal: ${totalAmount} </h4> <br />
                <button onClick={handleAddRow} className="btn btn-primary"> Add Item </button>

            </div>
            <form onSubmit={handleSubmit}>
                <button class="btn btn-success btn-lg float-right">Create Invoice</button>

            </form>
        </>
    )
}

export default ItemsInfo