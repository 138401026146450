import React, { useEffect, useState } from "react";
import {
    useParams,
    Redirect
} from "react-router-dom";
import moment from 'moment';
import CustomerInfo from "./CreateInvoice/CustomerInfo";
import ItemsInfo from "./CreateInvoice/ItemsInfo";
import NotFound from "../Home/NotFound";
import AccountingInvoiceNotFound from "./AccountingInvoiceNotFound";



export default function AccountingCreateInvoice(props) {
    const params = useParams();

    const [data, setData] = useState({ date: moment().toDate() })
    const [notFound, setNotFound] = useState(false)

    const handleCustomerChange = (Details) => {
        console.log(Details)
        setData(prevState => {
            return { ...prevState, customer: { ...Details } };
        });
        if (Details.terms && Details.terms.length > 0) {
            getDueDate(Details.terms, data.date)
        }
    }

    const updateCustomer = (Details) => {
        setData({ ...data, customer: { ...Details } })
        if (Details && Details.terms && Details.terms.length > 0) {
            getDueDate(Details.terms, data.date)
        }
    }
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handleDateChange = (date, name) => {
        const terms = data.customer.terms

        if (name === "date") {
            setData({ ...data, [name]: date })
            getDueDate(terms, date)
        }
        if (name === "due_date") {
            setData({ ...data, [name]: date })
            //getDueDate(terms, date)
        }
    }
    function getDueDate(e, date) {
        if (e == "Net 60") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(60, 'days').toDate() };
            })
        }
        if (e == "Net 45") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(45, 'days').toDate() };
            })
        }
        if (e == "Net 30") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(30, 'days').toDate() };
            })
        }
        if (e == "Net 15") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(15, 'days').toDate() };
            })
        }
        if (e == "Net 10") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(10, 'days').toDate() };
            })
        }
        if (e == "Due On Receipt") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(0, 'days').toDate() };
            })
        }

    }
    useEffect(() => {
        if (props && props.loads) {
            const loadInfo = props.loads.filter(load => load.load_id == params.invoice_id)
            if (loadInfo === null || loadInfo.length === 0) {
                console.log(loadInfo.length)
                setNotFound(true)
            }
            else {
                setData(loadInfo[0])
if (loadInfo[0].customer_name) {
    const customer = props.customers.filter(load => load.customer_name == loadInfo[0].customer_name)
    setData(prevState => {
        return { ...prevState, customer: { ...customer[0] } };
    });
    if (customer[0].terms && customer[0].terms.length > 0) {
        getDueDate(customer[0].terms, data.date)
    }
}
            }
        }

    }, [props.loads])
    useEffect(() => {

        if (props && props.invoices) {
            const getInvoiceNumber = props.invoices.slice(-1)
            if (getInvoiceNumber === null || getInvoiceNumber.length === 0) {
                setData(prevState => {

                    return { ...prevState, date: moment().toDate(), invoice_number: 1 };
                })
            }
            else {
                let sortInvoices = props.invoices.sort((a, b) => b.invoice_number - a.invoice_number)
                
                const invoice_number = parseInt(sortInvoices[0].invoice_number) + 1
                setData(prevState => {

                    return { ...prevState, date: moment().toDate(), invoice_number: invoice_number };
                })
            }

        }
    }, [props], [props.invoices])

    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    if(notFound) {
        return (
        <Redirect to="/accounting/create-invoice" />
        )
    }

    return (
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Create Invoice # <input name="invoice_number" value={data.invoice_number} onChange={(e) => handleChange(e)} /></h3>

            </div>
            <div className="card-body">
                <CustomerInfo
                    {...props} {...data}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    handleCustomerChange={handleCustomerChange}
                    updateCustomer={updateCustomer} />
                <ItemsInfo 
                    updateLoading={props.updateLoading}
                    updateError={props.updateError} 
                    {...props} 
                    {...data} />
            </div>
        </div>
    )
}