import React, { useState } from 'react';
import { authFetch } from '../../auth';
import NoteForm from '../Resources/NoteForm';
import NoteList from '../Resources/NoteList';

function LocationsNotes(props) {
    // State to control the visibility of the contact form
    const [showForm, setShowForm] = useState(false);

    // Function to toggle the contact form visibility
    const toggleForm = (e) => {
        e.preventDefault(); // Prevent the default anchor behavior
        setShowForm(!showForm); // Toggle the visibility state
    };
    const toggleShow = () => {
        setShowForm(!showForm); // Toggle the visibility state
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Notes</h3>
                    <div className="edit-button">
                        {/* Update here to use onClick to toggle the form display */}
                        <a id="edit" href="#" onClick={toggleForm}>
                            {showForm ? '[Cancel]' : '[New Note]'}
                        </a>
                    </div>
                </div>
                <div className="card-body">
                {showForm &&<NoteForm
                    addNote={props.addNote} 
                    toggleShow={toggleShow}
                    contacts={props.contacts}
                    type={'location'}
                    type_id={props.type_id}/>
                }
                    {props.notes && <NoteList
                                        showForm={showForm}
                                        notes={props.notes} 
                                        removeNote={props.removeNote} 
                                        type={'location'} />}
                </div>
            </div>
        </>
    );
}

export default LocationsNotes;