import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';
import { Alert } from "react-bootstrap";
import UseAlert from '../Alerts/UseAlerts';

export default function LocationsItemLocationDetails(props) {
    const [state, setState] = useState('')
    const [edit, setEdit] = useState("false")
    const {
        alertState,
        showSuccessAlert,
        showFailAlert,
        showNoChangeAlert
    } = UseAlert();

    const changeEdit = () => {
        setEdit(!edit);
    }
    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        props.parentLocationDetails({ [e.target.name]: e.target.value })

    }
    useEffect(() => {
        setState(props)
    })
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const response = await authFetch(`https://fltbd.herokuapp.com/api/locations/${props.location_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                location_name: state.location_name,
                branch_number: state.branch_number,
                contact_name: state.contact_name,
                phone_number: state.phone_number,
            }),
            headers: { 'Content-Type': 'application/json' },
        });
    
        if (response.status === 304 || response.status === 405) {
            setEdit(!edit);
            // You can implement showNoChangeAlert if needed, or similar function
            //showNoChangeAlert('No changes were made as the data was the same.');
            return; // Stop further processing since no change was made
        }
    
        if (!response.ok) {
            if (response.status === 500) {
                // You can implement showFailAlert if needed, or similar function
                showFailAlert('Server Error: Please try again later or contact support.');
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }
    
        const data = await response.json();
    
        if (data.status === 200) {
            // You can implement showSuccessAlert if needed, or similar function
            showSuccessAlert('Success: Location Information Updated');
            setEdit(!edit);
    
            props.updateLocation({
                location_id: state.location_id,
                location_name: state.location_name,
                branch_number: state.branch_number,
                contact_name: state.contact_name,
                phone_number: state.phone_number,
            });
        } else {
            console.log(`Update failed! reason: ${data.reason}`);
        }
        
        setEdit(!edit);
    }
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Location Details</h3>
                <div className="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>
                </div>
            </div>
            <div className="card-body">
            {alertState.show && (
                    <Alert variant={`${alertState.type}`}>
                        {alertState.message}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <div class="form-row">
                        <div class="col mb-3">
                            <label className="form-label">Location Name</label>
                            <input type="text" className="form-control" value={state.location_name} name="location_name" onChange={handleChange} required readOnly={edit} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label">Contact Name</label>
                            <input type="text" className="form-control" value={state.contact_name} name="contact_name" onChange={handleChange} readOnly={edit} />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="form-label">Phone Number</label>
                            <input type="tel" className="form-control" value={state.phone_number} name="phone_number" onChange={handleChange} readOnly={edit} />

                        </div>
                    </div>
                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>
        </div>

    )
}