import React, { useEffect, useState } from "react";


const PrivacyPolicy = () => {

    return (
        <>
             <div className="colorwhite">
            <h2>1. Introduction</h2>
            <p>
            RateConfirmation.com is committed to protecting the privacy of users of the Transportation Management System (TMS). This privacy policy sets forth the types of information we collect, how the information is used, and with whom the information may be shared.
            </p>
            <h2>2. Information Collection and Use</h2>
            <p>
                When you use the RateConfirmation.com TMS, we may collect personal information such as your name, address, phone number, and email address. We may also collect information about your use of the TMS, including your transportation and logistics processes.
            </p>
            <h2>3. Use of Information</h2>
            <p>
                The information collected through the RateConfirmation.com TMS will be used to provide and improve the RateConfirmation.com TMS, to communicate with you, and to comply with legal requirements. We may use your personal information to send you marketing and promotional materials, but you may opt-out of receiving such materials at any time.
            </p>
            <h2><u>4. Data Privacy and No Data Sharing</u></h2>
            <p>
            We are committed to protecting your personal information and ensuring it is not shared with anyone. Your data is NOT for sale, and we will never misuse your information. We strictly limit access to your personal information and do not share it with affiliates, service providers, contractors, or any third parties. We may also disclose your personal information if required to do so by law or in response to a valid request by a government or law enforcement authority.             </p>
            <h2>5. Data Security</h2>
            <p>
                We have implemented reasonable and appropriate security measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. However, no method of transmission over the internet, or method of electronic storage, is 100% secure, and we cannot guarantee its absolute security.
            </p>
            <h2>6. Changes to this Privacy Policy</h2>
            <p>
                We reserve the right to modify this privacy policy at any time. We will notify you of any material changes to this policy by posting the revised policy on the RateConfirmation.com TMS or by sending you an email. Your continued use of the RateConfirmation.com TMS after any such change constitutes your acceptance of the revised policy.
            </p>
            <h2>7. Contact Us</h2>
            <p>
                If you have any questions about this privacy policy or our use of your personal information, please contact us at info@RateConfirmation.com
            </p>
            <p>
            Last Updated: 5-20-2024.
            </p>
            </div>
        </>
    )
}
export default PrivacyPolicy