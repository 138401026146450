import React, { useEffect, useState, Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { authFetch } from "../../auth";
import { Spinner, Button, Accordion } from "react-bootstrap";
import { Alert } from "react-bootstrap";


export default function CarriersField(props) {

    const [newCarrier, setNewCarrier] = useState('')
    const [collapse, setCollapse] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [checked, setChecked] = useState(false)
    const [showingSuccessAlert, setShowingSuccessAlert] = useState(false);
    const [showingFailAlert, setShowingFailAlert] = useState(false);
    const onShowSuccessAlert = () => {
      setShowingSuccessAlert(true,
          setTimeout(() => {
              setShowingSuccessAlert(false)
          }, 2000)
      );
  }
  const onShowFailAlert = () => {
      setShowingFailAlert(true,
          setTimeout(() => {
              setShowingFailAlert(false)
          }, 2000)
      );
  }

    const handleChange = e => {
        setNewCarrier({
            ...newCarrier,
            [e.target.name]: e.target.value
        })
    }
    const handleChecked = () => {
        setChecked(!checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if (re.test(newCarrier.carrier_email)) {
            const uniqueID = uuidv4();
            const newCarrierData = {
                contract_id: uniqueID,
                carrier_email: newCarrier.carrier_email,
                auto_send: checked.toString()
            };
    
            try {
                const response = await authFetch(`https://fltbd.herokuapp.com/api/create-new-contract`, {
                    method: 'PUT',
                    body: JSON.stringify(newCarrierData),
                    headers: { 'Content-Type': 'application/json' },
                });
    
                if (!response.ok) {
                    onShowFailAlert()
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    const json = await response.json();
                    if (json.status === 200) {
                        onShowSuccessAlert();
                        props.parentNewPendingCarrier({
                            contract_id: uniqueID,
                            carrier_email: newCarrier.carrier_email,
                            status: 'Not Started'
                        });
                        setLoading(true);
                        setTimeout(() => {
                            setNewCarrier({
                                contract_id: '',
                                carrier_email: '',
                                status: '',
                            });
                            setLoading(false);
                        }, 500);
                    } else {
                        onShowFailAlert()
                       throw new Error('Request failed!');
                    }
                }
            } catch (error) {
                // Log the error or display it to the user
                onShowFailAlert()
                console.log(error);
                setError('Request failed. Please try again.');
            }
        }
        else {
            onShowFailAlert()
            setError('Not A Valid Email.');
        }
    }
    
    return (
        <div class="card" >
            
            <div class="card-header" data-toggle="collapse"
                data-target="#addCarrier" onClick={() => setCollapse(!collapse)} style={{ cursor: "pointer" }}>
                <h3 class="card-title">Add New Carrier</h3>
                <div class="edit-button">
                {collapse ?
                    	<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="white" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="12" y1="5" x2="12" y2="19" /><line x1="18" y1="13" x2="12" y2="19" /><line x1="6" y1="13" x2="12" y2="19" /></svg>

                        :
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="white" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="12" y1="5" x2="12" y2="19" /><line x1="18" y1="11" x2="12" y2="5" /><line x1="6" y1="11" x2="12" y2="5" /></svg>

                    }
                </div>
            </div>
            <div class="card-body collapse" id="addCarrier">
            <Alert variant="success" show={showingSuccessAlert} >
                    New Carrier Contract Created
                </Alert>
                <Alert variant="danger" show={showingFailAlert} >
                    No Carrier Contract Created
                </Alert>
                <form onSubmit={handleSubmit}>
                    <div class="form-group">
                        <div class="row">
                        
                            <label for="inputCustomerName" class="form-label">Carrier Email</label>
                            <div class="col-md-12 d-flex">
                 
                                <input type="email" class="form-control" placeholder="Enter Email Address" value={newCarrier.carrier_email} name="carrier_email" onChange={handleChange} required />
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="checkbox" id="sendToCarrier" checked={checked} name="autosend" onChange={handleChecked} />
                                        <label class="form-check-label" for="sendToCarrier" style={{marginBottom: 0}}>&nbsp;Auto-Send</label>

                                    </div>
                                </div>
                                {loading ?
                                    <button class="btn btn-success" disabled>Sending...</button>
                                    :
                                    <button class="btn btn-success" disabled={!props.activeSub}>Submit</button>
                                }
                            </div>
                            <div className="d-flex justify-content-center error-msg-red mt-2">{error}</div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer collapse" id="addCarrier">

                <span>Want to manually add a carrier? </span><Link className='link-fix' to="/carriers/add"> <b>Click Here</b></Link>
            </div>
        </div>

    )
}