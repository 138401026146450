import React, { Component, setState, useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";



const AreasServiced = (props) => {
  const [areas, setAreas] = useState({
    western: [
      { name: "AZ", selected: false },
      { name: "CA", selected: false },
      { name: "ID", selected: false },
      { name: "NV", selected: false },
      { name: "OR", selected: false },
      { name: "UT", selected: false },
      { name: "WA", selected: false }
    ],
    plains: [
      { name: "AR", selected: false },
      { name: "CO", selected: false },
      { name: "KS", selected: false },
      { name: "MT", selected: false },
      { name: "NE", selected: false },
      { name: "NM", selected: false },
      { name: "ND", selected: false },
      { name: "OK", selected: false },
      { name: "SD", selected: false },
      { name: "TX", selected: false },
      { name: "WY", selected: false },
    ],
    midwest: [
      { name: "IL", selected: false },
      { name: "IN", selected: false },
      { name: "IA", selected: false },
      { name: "KY", selected: false },
      { name: "MI", selected: false },
      { name: "MN", selected: false },
      { name: "MO", selected: false },
      { name: "OH", selected: false },
      { name: "WI", selected: false },
    ],
    southern: [
      { name: "AL", selected: false },
      { name: "FL", selected: false },
      { name: "GA", selected: false },
      { name: "LA", selected: false },
      { name: "MS", selected: false },
      { name: "NC", selected: false },
      { name: "SC", selected: false },
      { name: "TN", selected: false },
      { name: "VA", selected: false },
      { name: "WV", selected: false },
    ],
    northeastern: [
      { name: "CT", selected: false },
      { name: "DE", selected: false },
      { name: "DC", selected: false },
      { name: "ME", selected: false },
      { name: "MD", selected: false },
      { name: "MA", selected: false },
      { name: "NH", selected: false },
      { name: "NJ", selected: false },
      { name: "NY", selected: false },
      { name: "PA", selected: false },
      { name: "RI", selected: false },
      { name: "VT", selected: false },
    ]
  });

  const handleOnChange = (e, type) => {
    const { name, checked } = e.target;
    const newArea = [...areas[type]];
    const index = newArea.findIndex(h => h.name === name);
    if (index > -1) {
      newArea[index] = { name, selected: checked };
    }
    setAreas(h => ({ ...h, [type]: newArea }));

  };

  const handleOnSelectAll = (e, type) => {
    const { checked } = e.target;
    let newArea = [...areas[type]];
    if (!checked) {
      newArea = newArea.map(opt => ({ ...opt, selected: false }));
    } else {
      newArea = newArea.map(opt => ({ ...opt, selected: true }));
    }
    setAreas(h => ({ ...h, [type]: newArea }));

  };

  useEffect(() => {
    props.parentAreasCallback(areas)
  }, [areas])



  const renderCheckboxList = (options, type) =>
    options.map(opt => (
     
      <label class="form-check-inline colorwhite" key={uuidv4()}>
        <input
          class="form-check-input"
          type="checkbox"
          name={opt.name}
          onChange={e => handleOnChange(e, type)}
          checked={opt.selected}
        />
        &nbsp;&nbsp;{opt.name}
      </label>
      

    ));

  const renderSelectAllCheckbox = type => (

    <label class="checkbox-inline">
      <input
        type="checkbox"
        onChange={e => handleOnSelectAll(e, type)}
        checked={areas[type].every(opt => opt.selected)}
      />
      <b> {`Select All`} {type.toUpperCase()} States</b>
    </label>

  );

  return (
    <section style={{ display: "flex", }}>
      <div>
        <div class="mb-3 colorwhite">
          {renderSelectAllCheckbox("western")}
          <br />
          {renderCheckboxList(areas.western, "western")}
        </div>
        <div class="mb-3 colorwhite">
          {renderSelectAllCheckbox("plains")}
          <br />
          {renderCheckboxList(areas.plains, "plains")}
        </div>
        <div class="mb-3 colorwhite">
          {renderSelectAllCheckbox("midwest")}
          <br />
          {renderCheckboxList(areas.midwest, "midwest")}
        </div>
        <div class="mb-3 colorwhite">
          {renderSelectAllCheckbox("southern")}
          <br />
          {renderCheckboxList(areas.southern, "southern")}
        </div>
        <div class="mb-3 colorwhite">
          {renderSelectAllCheckbox("northeastern")}
          <br />
          {renderCheckboxList(areas.northeastern, "northeastern")}
        </div>
      </div>

    </section>
  );
};

export default class ContractCarrierInformaton extends Component {
  constructor(props) {
    super(props);
    this.trailerSelect = this.trailerSelect.bind(this)
    this.isSelected = this.isSelected.bind(this)
    this.state = {
      showComponent: false,
      trailers: [],
      selected: {
        hotshot: false,
        flatbed: false,
        stepdeck: false,
        doubledrop: false,
        doubles: false,
        rgn: false,
        lowboy: false,
        dryvan: false,
        reefer: false,
        sprinter: false,
        box: false,
        other: false
      },
      areas: {
        western: [
          { name: "AZ", selected: false },
          { name: "CA", selected: false },
          { name: "ID", selected: false },
          { name: "NV", selected: false },
          { name: "OR", selected: false },
          { name: "UT", selected: false },
          { name: "WA", selected: false }
        ],
        plains: [
          { name: "AR", selected: false },
          { name: "CO", selected: false },
          { name: "KS", selected: false },
          { name: "MT", selected: false },
          { name: "NE", selected: false },
          { name: "NM", selected: false },
          { name: "ND", selected: false },
          { name: "OK", selected: false },
          { name: "SD", selected: false },
          { name: "TX", selected: false },
          { name: "WY", selected: false },
        ],
        midwest: [
          { name: "IL", selected: false },
          { name: "IN", selected: false },
          { name: "IA", selected: false },
          { name: "KY", selected: false },
          { name: "MI", selected: false },
          { name: "MN", selected: false },
          { name: "MO", selected: false },
          { name: "OH", selected: false },
          { name: "WI", selected: false },
        ],
        southern: [
          { name: "AL", selected: false },
          { name: "FL", selected: false },
          { name: "GA", selected: false },
          { name: "LA", selected: false },
          { name: "MS", selected: false },
          { name: "NC", selected: false },
          { name: "SC", selected: false },
          { name: "TN", selected: false },
          { name: "VA", selected: false },
          { name: "WV", selected: false },
        ],
        northeastern: [
          { name: "CT", selected: false },
          { name: "DE", selected: false },
          { name: "DC", selected: false },
          { name: "ME", selected: false },
          { name: "MD", selected: false },
          { name: "MA", selected: false },
          { name: "NH", selected: false },
          { name: "NJ", selected: false },
          { name: "NY", selected: false },
          { name: "PA", selected: false },
          { name: "RI", selected: false },
          { name: "VT", selected: false },
        ]
      }
    }
  }

  handleAreasCallback = e => {
    this.setState({ areas: e })
    this.props.parentAreas(e)
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.details.trailers !== this.state.selected) {
      this.props.parentCarrierTrailers(this.state.selected);

    }
  }
  componentDidMount(props) {
    if (this.props.details.trailers == undefined) {
      this.props.parentCarrierTrailers(this.state.selected)
    }
    else {
      this.setState({ selected: this.props.details.trailers })
    }

  }


  trailerSelect = (e) => {
    console.log(e)
    if (!this.state.trailers.some(item => e.target.value === item)) {
      this.setState({
        trailers: [...this.state.trailers, e.target.value]
      })
    }
    if (this.state.trailers.some(item => e.target.value === item)) {

      this.setState({ trailers: this.state.trailers.filter(item => item !== e.target.value) })
    }
    this.props.parentCarrierTrailers(this.state.trailers)

  }

  trailerChange = (e) => {
    const key = e.target.value.toLowerCase();
    const value = !this.state.selected[key];
    const newSelected = Object.assign(this.state.selected, { [key]: value });
    this.setState({ selected: newSelected });
    this.props.parentCarrierTrailers({ selected: newSelected })
  }
  isSelected = (e) => {
    return true

  }


  render() {

    return (
      <div class="contract-margin">
        <div className="card-header">
          <h3 class="card-title">Carrier Information</h3>
          <div className="order-right colorwheat">
            {this.props.details.company_name}
          </div>
        </div>
        <div class="card-body">
          
          <div class="trailers">
            <label class="form-label">Select Equipment That Apply</label>
            <div class="form-selectgroup">
              <label class="form-selectgroup-item">
                <input type="checkbox" name="hotshot" value="Hotshot" class="form-selectgroup-input" checked={this.props.details.trailers.hotshot} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Hotshot</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="flatbed" value="Flatbed" class="form-selectgroup-input" checked={this.props.details.trailers.flatbed} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Flatbed</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="stepdeck" value="Stepdeck" class="form-selectgroup-input" checked={this.props.details.trailers.stepdeck} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Stepdeck</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="doubledrop" value="DoubleDrop" class="form-selectgroup-input" checked={this.props.details.trailers.doubledrop} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Double Drop</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="doubles" value="Doubles" class="form-selectgroup-input" checked={this.props.details.trailers.doubles} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Doubles</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="rgn" value="RGN" class="form-selectgroup-input" checked={this.props.details.trailers.rgn} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">RGN</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="lowboy" value="Lowboy" class="form-selectgroup-input" checked={this.props.details.trailers.lowboy} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Lowboy</span>
              </label>
              
            </div>
          </div>
          
          <div class="specialEquipment mt-3">
            <div class="form-selectgroup">
            <label class="form-selectgroup-item">
                <input type="checkbox" name="dryvan" value="Dryvan" class="form-selectgroup-input" checked={this.props.details.trailers.dryvan} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Dry Van</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="reefer" value="Reefer" class="form-selectgroup-input" checked={this.props.details.trailers.reefer} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Reefer</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="sprinter" value="Sprinter" class="form-selectgroup-input" checked={this.props.details.trailers.sprinter} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Sprinter Van</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="box" value="Box" class="form-selectgroup-input" checked={this.props.details.trailers.box} onChange={this.trailerChange} />
                <span class="form-selectgroup-label">Box Van</span>
              </label>
              <label class="form-selectgroup-item">
                <input type="checkbox" name="other" value="Other" class="form-selectgroup-input" checked={this.props.details.trailers.other} onChange={this.trailerChange} />
                <span class="form-selectgroup-label"> Other</span>
              </label>
             
            </div>
          </div>
          <hr />
          <section class="areas mt-3">
            <label class="form-label">Areas Serviced</label>

          </section>
          <AreasServiced
            {...this.state}
            parentAreasCallback={this.handleAreasCallback}
          />




        </div>
      </div>
    )
  }
}