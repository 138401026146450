import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const DeleteConfirmationModal = ({ title, message, onConfirm, show, setShow }) => {

  const handleConfirm = () => {
    onConfirm()
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>No</Button>
        <Button variant="danger" onClick={handleConfirm}>Yes</Button>
      </Modal.Footer>
    </Modal>
  )
}
  
export default DeleteConfirmationModal