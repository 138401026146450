import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { authFetch } from '../../auth';
import Select from 'react-select'
import { gd } from 'date-fns/locale';
import CreatableSelect from 'react-select/creatable';



const LoadsDetails = (props) => {
    const initialState = () => {
        return (props)
    }
    const [checked, setChecked] = useState([]);
    const [isChecked, setIsChecked] = useState(props.oversize);

    const [equipment, setEquipment] = useState({
        tarp: false,
        team: false,
        hazmat: false,
    })
    const checkList = ["Chains & Binders", "Straps", "Tarp", "Team"];
    //https://contactmentor.com/checkbox-list-react-js-example/
    //https://www.cluemediator.com/checkbox-list-example-in-react
    const handleCheck = (event) => {

        var updatedList = [...checked]
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
        props.parentEquipmentDetails(updatedList)
        //updateEquipment()
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                equipment: [updatedList]
            }),
            headers: { 'Content-Type': 'application/json' },
        })
    };


    useEffect(() => {
        //Not Working...
        if (props && props.equipment) {
            setChecked(props.equipment)


        }
    }, [props.equipment])

    useEffect(() => {
        if (props.oversize !== undefined && props.oversize !== isChecked) {
            setIsChecked(props.oversize);
        }
    }, [props.oversize]);




    const [state, setState] = useState(initialState);


    const statusList = [
        { value: 'Inactive', label: 'Inactive' },
        { value: 'Not Assigned', label: 'Not Assigned' },
        { value: 'Dispatched', label: 'Dispatched' },
        { value: 'In Transit', label: 'In Transit' },
        { value: 'Completed', label: 'Completed' },

    ]
    const trailerList = [
        { value: 'F - Flatbed', label: 'F - Flatbed' },
        { value: 'SD - Stepdeck', label: 'SD - Stepdeck' },
        { value: 'HS - Hotshot', label: 'HS - Hotshot' },
        { value: 'FSD - Flatbed, Stepdeck', label: 'FSD - Flatbed, Stepdeck' },
        { value: 'FSDHS - Flatbed, Stepdeck, Hotshot', label: 'FSDHS - Flatbed, Stepdeck, Hotshot' },
        { value: 'SDHS - Stepdeck, Hotshot', label: 'SDHS - Stepdeck, Hotshot' },
        { value: 'RGN - Removeable Gooseneck', label: 'RGN - Removeable Gooseneck' },
        { value: 'DD - Double Drop', label: 'DD - Double Drop' },
        { value: 'DBL - Doubles', label: 'DBL - Doubles' },
        { value: 'R - Reefer', label: 'R - Reefer' },
        { value: "40' - Container", label: "40' - Container" },
        { value: "20' - Container", label: "20' - Container" },
        { value: "40' - Container", label: "40' - Container" },

        { value: 'Other', label: 'Other' }

    ]

    const [multiple, setMultiple] = useState()
    const equipmentList = [
        { value: 'Chains & Binders', label: 'Chains & Binders' },
        { value: 'Straps', label: 'Straps' },
        { value: 'Tarp', label: 'Tarp' },
        { value: 'Team', label: 'Team' },
    ];


    async function trailerChange(e) {
        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    trailer: e.value
                }),
                headers: { 'Content-Type': 'application/json' },
            });

            // Check if the response status is in the range 200-299
            if (!response.ok) {
                let errorMessage = `Error: ${response.status} - ${response.statusText}`;

                // Try to extract an error message from the response body if available
                const errorData = await response.json();
                if (errorData && errorData.message) {
                    errorMessage = `Error: ${errorData.message}`;
                }

                throw new Error(errorMessage);
            }

            if (response.status === 200) {
                // If the request was successful, update the parent component with the new trailer value
                props.parentLoadDetails({ trailer: e.value });
            }

        } catch (error) {
            console.error('There was an error updating the load trailer:', error);

            // Optionally, you can call a custom error handler prop (if available)
            if (props.onError) {
                props.onError(error.message);
            }

            // If using a state to display error messages in the UI
            // setState({ error: error.message });
        }
    }

    function statusChange(e) {

        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                status: e.value
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentLoadDetails({ status: e.value })
    }

    const oversizeCheck = async (e) => {
        setIsChecked(e.target.checked); // Update local state immediately (optimistic update)

        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    oversize: e.target.checked
                }),
                headers: { 'Content-Type': 'application/json' },
            });

            // Check if the response status is in the range 200-299
            if (!response.ok) {
                let errorMessage = `Error: ${response.status} - ${response.statusText}`;

                // Try to extract an error message from the response body if available
                const errorData = await response.json();
                if (errorData && errorData.message) {
                    errorMessage = `Error: ${errorData.message}`;
                }

                throw new Error(errorMessage);
            }

            if (response.status === 200) {
                // If the request was successful, update the parent component with the new status value
                console.log("test")
                props.parentLoadDetails({ oversize: e.target.checked });
            }

        } catch (error) {
            console.error('There was an error updating the load status:', error);
            // Revert the optimistic update if an error occurs
            setIsChecked(!e.target.checked);
        }
    };


    const handleEquipmentChange = (e) => {
        setMultiple(e)
        props.parentEquipmentDetails(e)
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                equipment: [e]
            }),
            headers: { 'Content-Type': 'application/json' },
        })

    }
    useEffect(() => {
        if (props && props.equipment) {
            setMultiple(props.equipment)
        }

    }, [props.equipment])


    const selectStyles = {
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#31363F',
            color: 'white',
        }),
        multiValueLabel: (base) => ({
            ...base,
            backgroundColor: '#31363F',
            color: 'white',
        }),
    }
    const dropDownStyle = {
        control: (base) => ({
            ...base,
            height: 50,
            minHeight: 50,
            borderRadius: "5px"
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? '#EEE'
                    : isFocused
                        ? '#EEE'
                        : null,
                color: 'black',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
        placeholder: (styles) => ({
            ...styles,
            color: "black",
        }),
    };

    return (


        <div class="card">
            <div class="card-body">

                <h3 class="card-title">Load Details</h3>
                <div class="mb-3">
                    <label class="form-label colorwhite">Status</label>
                    <Select styles={dropDownStyle} options={statusList} placeholder={props.status} name="status" onChange={(e) => statusChange(e)} isDisabled={props.archived} />


                </div>
                <div class="mb-3">
                    <div class="d-flex justify-content-between">
                        <label class="form-label colorwhite">Trailer</label>
                        <div class="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isChecked}
                                name="oversize"
                                id="oversizeLoadCheckbox"
                                onChange={(e) => oversizeCheck(e)}
                            />                            <label class="form-check-label colorwhite" for="oversizeLoadCheckbox">Oversize</label>
                        </div>
                    </div>
                    <CreatableSelect styles={dropDownStyle} options={trailerList} placeholder={props.trailer} name="trailer" onChange={(e) => trailerChange(e)} isDisabled={props.archived} />
                </div>

                <div className="mb-3">

                    <label class="form-label colorwhite">Equipment</label>
                    <CreatableSelect
                        isMulti={true}
                        name="equipment"
                        value={multiple}
                        options={equipmentList}
                        onChange={handleEquipmentChange}
                        styles={selectStyles}
                        isDisabled={props.archived}
                    />
                </div>


            </div>
        </div>



    )


}

export default LoadsDetails