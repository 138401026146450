import React, { useState, useEffect, Component } from 'react'
import {
  Link
} from "react-router-dom"


const FinanceLoading = () => {

  return (
    <>
      <div class="col-sm-6 col-lg-3">
        <div class="card  ">
          <div class="card-body">
            <div class="align-items-center">
              <div class="placeholder-glow"><span class="placeholder col-4 placeholder-xs"></span></div>
            </div>

            <div>
              <div class="h4 placeholder-glow"><span class="placeholder col-6"></span></div>
              <div class="h4 placeholder-glow"><span class="placeholder col-6 "></span></div>
            </div>


          </div>
          <div class="card-footer">
            <div class="h4 placeholder-glow"><span class="placeholder col-6"></span></div>
          </div>
        </div>
      </div>
    </>

  )

}

function DashboardFinances(props) {
  const loads = props.loads
  const [sevenDaysInfo, setSevenDaysInfo] = useState()
  const [thirtyDaysInfo, setThirtyDaysInfo] = useState()
  const [ytdInfo, setYTDInfo] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (props && props.sevenDays && props.thirtyDays && props.ytd && props.loads) {
      //below seems to be working...
      if (props.loads.length > 0) {
        setLoading(false)
      }
    }

  }, [props], [props.sevenDays], [props.thirtyDays], [props.ytd]);
  const addCommas = (n) => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(() => {
    if (props && props.sevenDays && props.thirtyDays) {
      setSevenDaysInfo({
        ...sevenDaysInfo,
        revenue: addCommas(props.sevenDays.reduce((a, v) => a = a + v.gross_pay, 0)),
        expense: addCommas(props.sevenDays.reduce((a, v) => a = a + v.carrier_pay, 0)),
        profit: addCommas(props.sevenDays.reduce((a, v) => a = a + v.gross_pay, 0) - props.sevenDays.reduce((a, v) => a = a + v.carrier_pay, 0))
      })



    }


  }, [props.sevenDays])

  useEffect(() => {
    if (props && props.thirtyDays) {
      setThirtyDaysInfo({
        ...thirtyDaysInfo,
        revenue: addCommas(props.thirtyDays.reduce((a, v) => a = a + v.gross_pay, 0)),
        expense: addCommas(props.thirtyDays.reduce((a, v) => a = a + v.carrier_pay, 0)),
        profit: addCommas(props.thirtyDays.reduce((a, v) => a = a + v.gross_pay, 0) - props.thirtyDays.reduce((a, v) => a = a + v.carrier_pay, 0))
      })
    }



  }, [props.thirtyDays]);

  useEffect(() => {
    if (props && props.ytd) {
      setYTDInfo({
        ...ytdInfo,
        revenue: addCommas(props.ytd.reduce((a, v) => a = a + v.gross_pay, 0)),
        expense: addCommas(props.ytd.reduce((a, v) => a = a + v.carrier_pay, 0)),
        profit: addCommas(props.ytd.reduce((a, v) => a = a + v.gross_pay, 0) - props.ytd.reduce((a, v) => a = a + v.carrier_pay, 0))
      })
      setTimeout(() => {
        //setLoading(false)
      }, 2500);
    }

  }, [props.ytd])


  return (
    <div>

      <div class="row row-deck">
        {loading ?
          <FinanceLoading />
          :
          <div class="col-sm-6 col-lg-3">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="subheader">Last 7 Days</div>
                </div>
                <div>
                  <div class="h4">Revenue: ${sevenDaysInfo.revenue}</div>
                  <div class="h4">Expense: ${sevenDaysInfo.expense}</div>
                </div>
              </div>
              <div class="card-footer">
                <div class="h4">Profit: ${sevenDaysInfo.profit}</div>

              </div>
            </div>
          </div>
        }
        {loading ?
          <FinanceLoading />
          :
          <div class="col-sm-6 col-lg-3">
            <div class="card  ">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="subheader">Last 30 Days</div>
                </div>

                <div>
                  <div class="h4">Revenue: ${thirtyDaysInfo.revenue}</div>
                  <div class="h4">Expense: ${thirtyDaysInfo.expense}</div>

                </div>

              </div>
              <div class="card-footer">

                <div class="h4">Profit: ${thirtyDaysInfo.profit}</div>

              </div>
            </div>
          </div>
        }

        {loading ?
          <FinanceLoading />
          :
          <div class="col-sm-6 col-lg-3">
            <div class="card  ">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="subheader">Year-to-Date</div>
                </div>

                <div>
                  <div class="h4">Revenue: ${ytdInfo.revenue}</div>
                  <div class="h4">Expense: ${ytdInfo.expense}</div>

                </div>

              </div>
              <div class="card-footer">

                
                <div class="h4">Profit: ${ytdInfo.profit}</div>

              </div>
            </div>
          </div>
        }
        {loading ?
          <div class="col-sm-6 col-lg-3">
            <div class="card  ">
              <div class="card-body">
                <div class="align-items-center">
                  <div class="placeholder-glow"><span class="placeholder col-4 placeholder-xs"></span></div>
                </div>

                <div>
                  <div class="h4 placeholder-glow"><span class="placeholder col-6"></span></div>
                  <div class="h4 placeholder-glow"><span class="placeholder col-6 "></span></div>
                </div>


              </div>

            </div>
          </div>
          :
          <div class="col-sm-6 col-lg-3">
            <div class="card  ">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="subheader">Current Loads</div>
                </div>

                <div>
                  <div class="h4">Active Loads: {props.activeLoads.length}</div>
                  <div class="h4">Ready for Accounting: {props.completedLoads.length}</div>
                </div>
              </div>
              <div class="card-footer" style={{borderTop:"none"}}>
                <Link to="/loads">
                  
                <button class="btn btn-sm btn-azure float-right">Go To Loads</button>
                
                </Link>
              </div>
            </div>
          </div>
        }

      </div>




    </div>
  )

}

export default DashboardFinances