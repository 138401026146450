import React, { Component, Suspense, useState, useEffect, useCallback } from 'react';
import Leftside from './Leftside';
import Header from './Header'
import Footer from './Footer'
import DashboardContainer from './Dashboard/DashboardContainer'
import CustomersContainer from './Customers/CustomersContainer'
import CustomersAdd from './Customers/CustomersAdd'
import CustomersItem from './Customers/CustomersItem';
import CustomersField from './Customers/CustomersField';
import CarriersAdd from './Carriers/CarriersAdd'
import CarriersItem from './Carriers/CarriersItem'
import LocationsContainer from './Locations/LocationsContainer'
import LocationsAdd from './Locations/LocationsAdd';
import LocationsItem from './Locations/LocationsItem'


import {
  Route, Switch, Redirect, Router,
} from 'react-router-dom';

import CarriersContainer from './Carriers/CarriersContainer';
import LoadsContainer from './Loads/LoadsContainer';
import styled from "styled-components";
import ReportsContainer from './Reports/ReportsContainer';

import { useAuth, login, authFetch, logout } from '../auth';
import Login from './Login/Login';
import ProfileContainer from './Profile/ProfileContainer';
import LoadsItem from './Loads/LoadsItem';
import RateConfirmationContainer from './Documents/RateConfirmationContainer';
import SignUp from './Login/SignUp';
import ForgotPassword from './Login/ForgotPassword';

import InitiateContract from './Documents/Contracts/InitiateContract';
import Contract from './Documents/Contracts/Contract';
import ContractRedirect from './Documents/Contracts/ContractRedirect';
import HomeContainer from './Home/HomeContainer';
import SettingsContainer from './Settings/SettingsContainer';

import EquipmentContainer from './Equipment/EquipmentContainer';
import EquipmentTruckItem from './Equipment/EquipmentTruckItem';
import EquipmentTrailerItem from './Equipment/EquipmentTrailerItem';
import DriversContainer from './Drivers/DriversContainer';
import DriversItem from './Drivers/DriversItem';
import LoadsDocuments from './Loads/LoadsDocuments';
import ConfirmEmail from './Login/ConfirmEmail';
import ResetPassword from './Login/ResetPassword';
import DoubleBrokerContainer from './Resources/DoubleBrokerContainer';
import NotFound from './Home/NotFound';
import SettingsUploadLocations from './Settings/SettingsUploadLocations';
import AccountingContainer from './Accounting/AccountingContainer';
import OnboardModal from './Modals/OnBoardModal';
import LegalContainer from './Legal/LegalContainer';
import RateConfirmationDemo from './Documents/RateConfirmationDemo';
import VerifyRate from './Accounting/VerifyRate';
import QuotesContainer from './Quotes/QuotesContainer';
import IncrementalGame from './Resources/IncrementalGame';
import CookieConsent from './CookieConsent';
import OffersContainer from './Offers/OffersContainer';



const PrivateRoute = ({ component: Component, ...rest }) => {
  const [logged] = useAuth();
  const [state, setState] = useState()

  return <Route {...rest} render={(props) => (
    logged
      ? <Component {...props} {...state} />
      : <Redirect to='/login' />
  )} />
}

const LogInRoute = ({ component: Component, ...rest }) => {
  const [logged] = useAuth();

  return <Route {...rest} render={(props) => (
    !logged
      ? <Component {...props} />
      : <Redirect to='/dashboard' />
  )} />
}

export default function Layout() {
  const [logged] = useAuth();
  const [company, setCompany] = useState({ company_tc: '&nbsp;', company_contract: '&nbsp;', type: 'broker' })
  const [user, setUser] = useState()
  const [initials, setInitials] = useState([])
  const [contracts, setContracts] = useState()
  const [db, setDB] = useState()
  const [onBoard, setOnBoard] = useState(false)
  const [activeSub, setActiveSub] = useState(true)

  const handleCompanyTC = (companyTC) => {
    if (companyTC === "<p></p>") {
      setCompany({ ...company, company_tc: "" })

    }
    else {
      setCompany({ ...company, company_tc: companyTC })

    }
  }
  const handleCompanyContract = (CompanyContract) => {
    if (CompanyContract === "<p></p>") {
      setCompany({ ...company, company_contract: "" })
    }
    else {
      setCompany({ ...company, company_contract: CompanyContract })
    }
  }

  const updateEmployeeList = (updatedList) => {

    setCompany({ ...company, employees: updatedList })
  }

  const updateTruckList = (updatedList) => {

    setCompany({ ...company, trucks: updatedList })
  }
  const updateTrailerList = (updatedList) => {
    setCompany({ ...company, trailers: updatedList })
  }
  const updateCustomersList = (updatedList) => {
    setCompany({ ...company, customers: updatedList })
  }

  const updateLoadList = (updatedList) => {
    setCompany({ ...company, loads: updatedList })

  }

  const updateInvoiceList = (updatedList) => {
    setCompany({ ...company, invoices: updatedList })

  }
  const updateCarriersList = (updatedList) => {
    setCompany({ ...company, carriers: updatedList })

  }
  const createInvoice = (newInvoice, mappedData) => {
    setCompany({ ...company, invoices: newInvoice, loads: mappedData })

  }
  const createNewLocation = (newLocation) => {

    // Copy the existing locations
    const updatedLocations = [...company.locations, newLocation];


    // Update the company state with the new location list
    setCompany(prevCompany => ({ ...prevCompany, locations: updatedLocations }));
  };



  const updateCustomer = (updatedDetails) => {

    setCompany(prevState => {
      const newState = { ...prevState };
      newState.customers = newState.customers.map((item) => {
        if (item.customer_id === updatedDetails.customer_id) {
          return { ...item, ...updatedDetails };
        }
        return item;
      });
      return newState;
    });
  };

  const updateCompany = (Details) => {
    setCompany(prevState => {
      // Copy the current state
      let newState = { ...prevState };

      // Iterate over Details to update each key/value pair
      for (let key in Details) {
        if (newState.hasOwnProperty(key)) {
          newState[key] = Details[key];
        }
      }

      return newState;
    });

  }

  const updateEmployee = (updatedEmployee) => {
    const updatedEmployees = company.employees.map(employee =>
      employee.user_id === updatedEmployee.user_id ? { ...employee, ...updatedEmployee } : employee
    );

    setCompany(prevCompany => ({ ...prevCompany, employees: updatedEmployees }));

    if (user.user_id === updatedEmployee.user_id) {
      setUser(prevUser => ({ ...prevUser, ...updatedEmployee }));
    }
  }

  const updateLocation = (updatedSingle) => {
    const updateLocation = company.locations.map((item) => {
      if (item.location_id === updatedSingle.location_id) {
        const updatedItem = {
          ...item,
          location_name: updatedSingle.location_name,
          branch_number: updatedSingle.branch_number,
          contact_name: updatedSingle.contact_name,
          phone_number: updatedSingle.phone_number,
          street_address: updatedSingle.street_address,
          city: updatedSingle.city,
          state: updatedSingle.state,
          zipcode: updatedSingle.zipcode,
        };
        return updatedItem;
      }
      return item;
    });
    setCompany({ ...company, locations: updateLocation });
  }

  const updatedLocationsList = (updatedList) => {
    setCompany({ ...company, locations: updatedList })

  }

  const addContact = (newContact) => {
    setCompany(prevCompany => {

      let updatedCompany = {
        ...prevCompany,
        contacts: [...prevCompany.contacts, newContact]
      };

      switch (newContact.type) {
        case 'customer':
          updatedCompany.customers = updatedCompany.customers.map((customer) => {
            if (customer.customer_id === newContact.type_id) {
              return { ...customer, contacts: [...customer.contacts, newContact] };
            } else {
              return customer;
            }
          });
          break;
        case 'carrier':
          // Assuming each 'carrier' type also has a 'carriers' list
          updatedCompany.carriers = updatedCompany.carriers.map((carrier) => {
            if (carrier.carrier_id === newContact.type_id) {
              return { ...carrier, contacts: [...carrier.contacts, newContact] };
            } else {
              return carrier;
            }
          });
          break;
        case 'lead':
          // Assuming each 'lead' type also has a 'contacts' list
          updatedCompany.leads = updatedCompany.leads.map((lead) => {
            if (lead.lead_id === newContact.type_id) {
              return { ...lead, contacts: [...lead.contacts, newContact] };
            } else {
              return lead;
            }
          });
          break;
          case 'location':
            updatedCompany.locations = updatedCompany.locations.map((location) => {
            if (location.location_id === newContact.type_id) {
                let newContacts = location.contacts ? [...location.contacts, newContact] : [newContact];
                return { ...location, contacts: newContacts };
            } else {
                return location;
            }
        });
        break;
        default:
          console.error(`Unknown contact type: ${newContact.type}`);
      }

      return updatedCompany;
    });
  };

  const removeContact = (contactIdToRemove) => {
    setCompany(prevCompany => {
      let updatedCompany = { 
        ...prevCompany, 
        contacts: prevCompany.contacts.filter(contact => contact.contact_id !== contactIdToRemove)
      };
  
      ['customers', 'carriers', 'leads', 'locations'].forEach(listName => {
        if(updatedCompany[listName]) {
          updatedCompany[listName] = updatedCompany[listName].map((item) => {
            if (item.contacts) {
              return { ...item, contacts: item.contacts.filter(contact => contact.contact_id !== contactIdToRemove)};
            } else {
              return item;
            }
          });
        }
      });
        
      return updatedCompany;
    });
  }

  const addNote = (newNote) => {
    setCompany(prevCompany => {
      let updatedCompany = {
        ...prevCompany,
        notes: [...prevCompany.notes, newNote]
      };
  
      switch (newNote.type) {
        case 'customer':
          updatedCompany.customers = updatedCompany.customers.map((customer) => {
            if (customer.customer_id === newNote.type_id) {
              let newNotes = customer.notes ? [...customer.notes, newNote] : [newNote];
              return { ...customer, notes: newNotes };
            } else {
              return customer;
            }
          });
          break;
        case 'carrier':
          updatedCompany.carriers = updatedCompany.carriers.map((carrier) => {
            if (carrier.carrier_id === newNote.type_id) {
              let newNotes = carrier.notes ? [...carrier.notes, newNote] : [newNote];
              return { ...carrier, notes: newNotes };
            } else {
              return carrier;
            }
          });
          break;
        case 'lead':
          updatedCompany.leads = updatedCompany.leads.map((lead) => {
            if (lead.lead_id === newNote.type_id) {
              let newNotes = lead.notes ? [...lead.notes, newNote] : [newNote];
              return { ...lead, notes: newNotes };
            } else {
              return lead;
            }
          });
          break;
        case 'location':
          updatedCompany.locations = updatedCompany.locations.map((location) => {
            if (location.location_id === newNote.type_id) {
              let newNotes = location.notes ? [...location.notes, newNote] : [newNote];
              return { ...location, notes: newNotes };
            } else {
              return location;
            }
          });
          break;
        case 'load':
            updatedCompany.loads = updatedCompany.loads.map((load) => {
              if (load.load_id === newNote.type_id) {
                let newNotes = load.notes ? [...load.notes, newNote] : [newNote];
                return { ...load, notes: newNotes };
              } else {
                return load;
              }
            });
            break;

          //WILL NEED TO ADD ABOVE FOR CONTACTS
          //
        default:
          console.error(`Unknown note type: ${newNote.type}`);
      }
  
      return updatedCompany;
    });
  };

  const removeNote = (noteIdToRemove) => {
    setCompany(prevCompany => {
      let updatedCompany = { 
        ...prevCompany, 
        notes: prevCompany.notes.filter(note => note.note_id !== noteIdToRemove)
      };
  
      ['contacts','customers', 'carriers', 'leads', 'loads', 'locations'].forEach(listName => {
        if(updatedCompany[listName]) {
          updatedCompany[listName] = updatedCompany[listName].map((item) => {
            if (item.notes) {
              return { ...item, notes: item.notes.filter(note => note.note_id !== noteIdToRemove)};
            } else {
              return item;
            }
          });
        }
      });
        
      return updatedCompany;
    });
  }

  const updateLocationInfo = (updatedList) => {
    const locationInfo = { location_url: updatedList.params.location_id, ...updatedList }
    const mapLoc = {
      "location_url": locationInfo.location_url,
      "hours": locationInfo.hours,
      "dock_height": locationInfo.dockHeight,
    }

    setCompany((prevState) => {
      const mappedData = company.locations.map(g => (g.location_id == updatedList.params.location_id ? Object.assign({}, g, { location_info: [mapLoc] }) : g))
      return ({ ...prevState, locations: mappedData })
    })
  }


  const updatePendingList = (updatedList) => {

    setCompany({ ...company, contracts: updatedList })
  }
  const deletePending = (G) => {
    //This is the only way I could get deleting pending carrier to work.

    setCompany((prevState) => ({ ...prevState, contracts: G }));
  }
  const pendingWebhook = (data) => {

    setCompany((prevState) => {
      const mappedData = prevState.contracts.map(g => (g.contract_id === data.Metadata.contract_id ? Object.assign({}, g, { postmark_status: data.RecordType }) : g))
      return ({ ...prevState, contracts: mappedData })

    })
  }

  //Testing for every item to be updated from one place depending on their type which needs to be included on callback
  //So this works, but might need to do it for only when things are submitted into database
  const updateSingle = (updatedSingle) => {
    const selectMap = {
      "customers": company.customers,
      "locations": company.locations,
      "carriers": company.carriers,
      "loads": company.loads
    }
    const data = selectMap[updatedSingle.type]
    const detailKey = Object.keys(updatedSingle)[0]
    const detailValue = Object.values(updatedSingle)[0]

    const updateItem = data.map((item) => {
      if (item.id === updatedSingle.id) {
        const updatedItem = {
          ...item,
          [detailKey]: detailValue
        };
        return updatedItem;
      }
      return item;
    });

    setCompany({ ...company, [updatedSingle.type]: updateItem });

  }

  const handleOnBoardStripe = () => {
    if (company && company.stripe && company.stripe.length > 0) {


      if (company.stripe[0].stripe_subscription_id === null) {
        setOnBoard(true)
      }
      else {
        setOnBoard(false)
      }
    }
}

const handleOnBoard = () => {
  if (company && company.company_contract.length <= 0) {
    setOnBoard(true)
   
  }
  else {
    setOnBoard(false)
  }
}


  useEffect(() => {
    handleOnBoard()
  }, [company])

  const handleSubStatus = () => {
    if (company && company.stripe) {
      if (company.stripe[0].status === "Inactive" ||
        company.stripe[0].status === "Paused" ||
        company.stripe[0].status === "Canceled"
      ) {
        setActiveSub(true)

      }
      else {
        setActiveSub(true)
      }
    }
  }

  useEffect(() => {
    handleSubStatus()
  }, [company])


  useEffect(() => {
    if (logged) {
      const fetchUser = async () => {
        const response = await authFetch("https://fltbd.herokuapp.com/user/");
        if (response.status === 500) {
          logout();
        } else {
          const data = await response.json();
          if (data) {
            setUser(data);
            if (data.first_name) {
              setInitials(data.first_name.charAt(0) + '' + data.last_name.charAt(0));
            }
          } else {
            logout();
          }
        }
      };
  
      fetchUser();
    }
  }, [logged]);
  
  useEffect(() => {
    if (logged && user && user.company_id) {
      const fetchCompany = async () => {
        const response = await authFetch(`https://fltbd.herokuapp.com/api/companys/${user.company_id}`);
        const data = await response.json();
        if (data) {
          setCompany(prevState => ({
            ...prevState,
            ...data,
          }));
        }
      };
  
      fetchCompany();
    }
  }, [logged, user]);
  
  const fetchOptionalData = async (urlKey, stateKey) => {
    const response = await authFetch(`https://fltbd.herokuapp.com/api/${urlKey}`);
    const data = await response.json();
    if (data) {
      setCompany(prevState => ({
        ...prevState,
        [stateKey]: data,
      }));
    }
  };
  
  useEffect(() => {
    if (logged) {
      // Fetch carriers, loads and invoices in parallel
      Promise.all([
        fetchOptionalData('carriers', 'carriers'),
        fetchOptionalData('loads', 'loads'),
        fetchOptionalData('invoice', 'invoices')
      ]);
    }
  }, [logged]);
  


  return (
    <>

      <div id="wrapper">
        <Header activeSub={activeSub} {...company} {...user} initials={initials} />
        <div id="content-wrapper" class="d-flex flex-column">


          <Switch>



            <PrivateRoute exact path="/dashboard">
              {logged ?
                <DashboardContainer onBoard={onBoard} activeSub={activeSub}  {...company} {...user} title="RateConfirmation - Dashboard" />
                : <Redirect to='/login' />}
            </PrivateRoute>


            <PrivateRoute exact path="/customers">
              {logged ?
                <CustomersContainer
                  activeSub={activeSub} {...company} {...user}
                  updatedLocationsList={updatedLocationsList}
                  createNewLocation={createNewLocation} updateCustomersList={updateCustomersList} title="RateConfirmation - Customers" />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/customers/add" component={CustomersField} />


            <PrivateRoute path="/customer/:customer_id">
              {logged ?
                <CustomersItem
                  addContact={addContact}
                  removeContact={removeContact}
                  addNote={addNote}
                  removeNote={removeNote}
                  initials={initials}
                  {...company} {...user} updateCustomersList={updateCustomersList} updateCustomer={updateCustomer} updateSingle={updateSingle} />
                : <Redirect to='/login' />}
            </PrivateRoute>


            <PrivateRoute exact path="/carriers">
              {logged ?
                <CarriersContainer
                  activeSub={activeSub}
                  deletePending={deletePending}
                  updatePendingList={updatePendingList}
                  pendingWebhook={pendingWebhook}
                  {...company} {...user}
                  title="RateConfirmation - Carriers"
                />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/carriers/add">
              <CarriersAdd {...company} updateCarriersList={updateCarriersList} title="RateConfirmation - Add Carrier" />
            </PrivateRoute>

            <PrivateRoute path="/carrier/:carrier_id">
              {logged ?
                <CarriersItem
                  addContact={addContact}
                  removeContact={removeContact}
                  addNote={addNote}
                  removeNote={removeNote}
                  {...company} {...user}
                  updateCarriersList={updateCarriersList}
                  title="RateConfirmation - Carrier Profile" />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/locations">
              {logged ?
                <LocationsContainer {...company} {...user}
                  updatedLocationsList={updatedLocationsList}
                  title="RateConfirmation - Locations"
                />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/locations/add" component={LocationsAdd} />
            <PrivateRoute path="/location/:location_id">
              {logged ?
                <LocationsItem 
                  {...company} 
                  {...user} 
                  addContact={addContact}
                  removeContact={removeContact}
                  addNote={addNote}
                  removeNote={removeNote}
                  updatedLocationsList={updatedLocationsList} 
                  updateLocationInfo={updateLocationInfo} 
                  updateLocation={updateLocation} 
                  updateSingle={updateSingle} 
                  title="RateConfirmation - Edit Location" />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/loads">
              {logged ?
                <LoadsContainer onBoard={onBoard} activeSub={activeSub} {...company} {...user} updateLoadList={updateLoadList} title="RateConfirmation - Loads" />
                : <Redirect to='/login' />}
            </PrivateRoute>


            <PrivateRoute exact path="/loads/add" component={LoadsItem} />



            <PrivateRoute path="/loads/:load_id">
              {logged ?
                <LoadsItem 
                  addNote={addNote}
                  removeNote={removeNote}
                  {...company} {...user} updateLoadList={updateLoadList} db={db} title="RateConfirmation - Build/Edit Load" />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute path="/load_documents/:load_id">
              {logged ?
                <LoadsDocuments {...company} {...user} />
                : <Redirect to='/login' />}

            </PrivateRoute>

            <PrivateRoute exact path="/reports">
              <ReportsContainer {...user} {...company} />
            </PrivateRoute>


            <PrivateRoute exact path="/profile" component={ProfileContainer} />


            <PrivateRoute path="/settings" >
              {logged ?
                <SettingsContainer {...user} {...company}
                  updateCompany={updateCompany}
                  activeSub={activeSub}
                  updateEmployeeList={updateEmployeeList}
                  updateEmployee={updateEmployee}
                  parentCompanyTC={handleCompanyTC}
                  parentCompanyContract={handleCompanyContract} title="RateConfirmation - Settings" />
                : <Redirect to='/login' />
              }
            </PrivateRoute>

            <PrivateRoute path="/accounting" >
              {logged ?
                <AccountingContainer {...user} {...company}
                  createInvoice={createInvoice}
                  updateLoadList={updateLoadList}
                  updateInvoiceList={updateInvoiceList} />
                : <Redirect to='/login' />
              }
            </PrivateRoute>

            <PrivateRoute path="/quotes" >
              {logged ?
                <QuotesContainer {...user} {...company}
                />
                : <Redirect to='/login' />
              }
            </PrivateRoute>
            <PrivateRoute path="/game" >
              {logged ?
                <IncrementalGame {...user} {...company}
                />
                : <Redirect to='/login' />
              }
            </PrivateRoute>


            <PrivateRoute exact path="/equipment" >
              {logged ?
                <EquipmentContainer {...user} {...company}
                  updateTruckList={updateTruckList}
                  updateTrailerList={updateTrailerList}
                  title="RateConfirmation - Equipment"
                />
                : <Redirect to='/login' />
              }
            </PrivateRoute>
            <PrivateRoute path="/equipment/truck/:equipment_id" component={EquipmentTruckItem} />
            <PrivateRoute path="/equipment/trailer/:equipment_id" component={EquipmentTrailerItem} />

            <PrivateRoute exact path="/drivers">
              {logged ?
                <DriversContainer {...user} {...company} title="RateConfirmation - Drivers" />
                : <Redirect to='/login' />
              }
            </PrivateRoute>
            <PrivateRoute path="/driver/:driver_id">
              {logged ?
                <DriversItem {...user} {...company} />
                : <Redirect to='/login' />
              }
            </PrivateRoute>

            <Route path="/documents/rateconfirmation/:load_id">
              <RateConfirmationContainer />
            </Route>

            <Route path="/offer/:load_id">
              <OffersContainer />
            </Route>


            <Route exact path="/rateconfirmation/demo">
              <RateConfirmationDemo />
            </Route>

            <Route exact path="/verify/:rate_id"
              render={(props) => <VerifyRate {...props} />} />


            {/* HOME PAGE/LANDING ROUTE */}
            <LogInRoute exact path="/" component={HomeContainer} />

            {/* LOGIN ROUTE */}
            <Route exact path="/login">
              {!logged ?
                <Login title="RateConfirmation - Login" />
                : <Redirect to='/dashboard' />}
            </Route>

            {/* CONFIRM EMAIL ROUTE */}
            <Route path="/confirm-email/:confirmation_id">
              {!logged ?
                <ConfirmEmail />
                : <Redirect to='/dashboard' />}
            </Route>

            {/* SIGN UP ROUTE */}
            <Route exact path="/signup">
              {!logged ?
                <SignUp title="RateConfirmation - Sign Up" />
                : <Redirect to='/dashboard' />}
            </Route>


            {/* FORGOT PASSWORD ROUTE */}
            <Route exact path="/forgot-password">
              {!logged ?
                <ForgotPassword title="RateConfirmation - Forgot Password" />
                : <Redirect to='/dashboard' />}
            </Route>

            {/* RESET PASSWORD ROUTE */}
            <Route exact path="/reset-password/:forgot_id">
              {!logged ?
                <ResetPassword />
                : <Redirect to='/dashboard' />}
            </Route>


            {/* DOUBLE BROKER INFO - MIGHT NOT KEEP */}
            <Route exact path="/double-broker-list">
              <Redirect to='/resources/double-broker-list' />
            </Route>

            <Route exact path="/resources/double-broker-list">
              {/* CHANGE/REMOVE EQUALS TO SHOW DOUBLE BROKER PAGE */}
              {3 === 2 ?
                <DoubleBrokerContainer {...user} title="RateConfirmation - Double Broker List" />
                :
                <NotFound />
              }
            </Route>


            {/* CARRIER RECEIVES THIS ROUTE WHEN SENT CONTRACT */}
            <Route path="/initiate_contract/:contract_id"
              render={(props) => <InitiateContract {...props} />} />


            {/* CARRIER GETS SENT TO THIS ROUTE AFTER COMPLETING INITIATE ROUTE */}
            <Route path="/contract/:contract_id"
              render={(props) => <Contract {...props} />} />
            <Route exact path="/already_completed_contract" component={ContractRedirect} />




            {/* LEGAL STUFF */}
            <Route path="/privacy-policy"
              render={() => <LegalContainer title="RateConfirmation - Privacy Policy" page="Privacy Policy" />} />

            <Route path="/terms-conditions"
              render={() => <LegalContainer title="RateConfirmation - Terms & Conditions" page="Terms & Conditions" />} />


            <Route>
              <NotFound />
            </Route>
          </Switch>

        </div>

      </div>
      <Footer />
    </>


  )

}

