import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import styled from "styled-components"
import RateConfirmationItem from '../Documents/RateConfirmationItem';
import { Modal } from 'react-bootstrap';
import LoadingModal from '../Modals/LoadingModal';

const Styles = styled.div`
.not-found-container {
    margin: 15px auto;
    padding: 70px;
    max-width: 850px;
    background-color: #fff;
    border: 1px solid #ccc;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
  }
  
  @media (max-width: 767px) {
    .not-found-container {
      padding: 35px 20px 70px 20px;
      margin-top: 0px;
      border: none;
      border-radius: 0px;
    }
  }`

const OffersContainer = (props) => {
    const [state, setState] = useState({
        notFound: false,
        isGenerating: false,
        loading: true,
        show: true
    });
    const handleCallbackGenerating = (PDFGeneration) => {
        setState(prevState => ({ ...prevState, isGenerating: PDFGeneration }));
    }
    const fetchData = useCallback(async () => {
        const { load_id } = props.match.params;
        const result = await fetch(`https://fltbd.herokuapp.com/document/confirmation/load/${load_id}`);
        if (result.status === 404) {
            setState(prevState => ({ ...prevState, notFound: true, load_id: load_id }));
        } else {
            const data = await result.json();
            const actionsData = data.actions;
            const shippersData = actionsData.filter(action => action.action === "s");
            const receiversData = actionsData.filter(action => action.action === "r");
            const shippers = shippersData.sort((a, b) => a.order - b.order);
            const receivers = receiversData.sort((a, b) => a.order - b.order);
            setState(prevState => ({ ...prevState, shippers: shippers, receivers: receivers, ...data }));
        }
        setState(prevState => ({ ...prevState, loading: false, show: false }));
    }, [props.match.params]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    if (state.loading) {
        return (
            <Modal show={state.show} size="sm" centered>
                <LoadingModal />
            </Modal>
        )
    }
  
    if (state.notFound) {
        return (
            <Styles>
                <div className="container-fluid not-found-container"> 
                    <div className="d-flex justify-content-center"> 
                        <h2>This Document Does Not Exist</h2> 
                    </div> 
                    <div className="d-flex justify-content-center"> 
                        <h3>Please contact the broker regarding this</h3> 
                    </div> 
                </div> 
            </Styles>
        )
    }
    return (
       
            <RateConfirmationItem {...state} parentCallbackGenerating={handleCallbackGenerating} />
    )
}
export default withRouter(OffersContainer);