import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from 'axios'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { v4 as uuidv4 } from "uuid";
import { authFetch } from '../../auth';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


const LoadsReceivers = props => {
    const history = useHistory();

    const [state, setState] = useState({ receivers: [] });
    const [locations, setLocations] = useState();
    const [focus, setFocus] = useState(false)


    const getOrder = () => {
        if (props && props.actions) {
            const actionsInfo = props.actions.filter(action => action.action == "r")
            if (actionsInfo === null || actionsInfo.length === 0) {
                return 1
            }
            else {
                let sortActions = actionsInfo.sort((a, b) => a.order - b.order)
                const getLast = sortActions.slice(-1)
                const orderNumber = parseInt(getLast[0].order) + 1
                return orderNumber
            }
        }
        else {
            return
        }
    }

    const handleAddReceiver = async () => {
        props.handleLoading(true);
    
        const getUniqueID = uuidv4();
        const newDate = moment(new Date()).toDate();
        const newReceiver = {
            location_name: 'Choose Location',
            action: 'r',
            load_match: props.loadID,
            action_id: getUniqueID,
            date: moment(newDate).format("MM-DD-YYYY"),
            order: getOrder()
        };
    
        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/actions`, {
                method: 'PUT',
                body: JSON.stringify({
                    location_name: '',
                    action: 'r',
                    load_match: props.loadID,
                    action_id: getUniqueID,
                    date: moment(newDate).format("L"),
                    order: getOrder()
                }),
                headers: { 'Content-Type': 'application/json' },
            });
    
            if (!response.ok) {
                props.handleLoading(false);
    
                const errorData = await response.json();
                throw new Error(errorData.message || 'Server error');
            }
    
            const responseData = await response.json();
            if (responseData.status === 200) {
                setState({
                    receivers: [...state.receivers, newReceiver]
                });
                props.handleLoading(false);
    
                props.parentAddAction(newReceiver);
            } else {
                props.handleLoading(false);
    
                throw new Error(responseData.message || 'Unexpected error');
            }
        } catch (error) {
            props.handleLoading(false);
    
            console.error('Error adding receiver:', error);
            alert(`Failed to add receiver: ${error.message}`);
        }
    };

    const handleRemoveReceiver = (idx) => () => {
        props.handleLoading(true);
        const receiverToDelete = state.receivers[idx];

        authFetch(`https://fltbd.herokuapp.com/actions/${receiverToDelete.action_id}`, {
            method: 'DELETE',
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                // Remove the receiver from the state
                const updatedReceivers = state.receivers.filter((_, sidx) => idx !== sidx);
                setState({ ...state, receivers: updatedReceivers });
                props.parentRemoveAction(receiverToDelete);
                props.handleLoading(false);
            } else {
                console.error('Error:', data);
                props.handleLoading(false);
                alert(`Error: ${data.message}. ${data.error}`);
            }
        })
        .catch((error) => {
            console.error('Unexpected error:', error);
            props.handleLoading(false);
            alert('An unexpected error occurred. Contact support if the problem persists.');
        });
    };

    const handleReceiverChange = (idx) => (e) => {
        console.log(e.__isNew__);
    
        const receiverChange = state.receivers.map((receiver, sidx) => {
            if (idx !== sidx) return receiver;
    
            authFetch(`https://fltbd.herokuapp.com/actions/${receiver.action_id}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    location_name: e.location_name,
                    load_match: props.load_id,
                    street_address: e.street_address,
                    city: e.city,
                    state: e.state,
                    contact_name: e.contact_name,
                    zipcode: e.zipcode,
                    phone_number: e.phone_number,
                    action: 'r',
                    action_id: receiver.action_id,
                    order: idx + 1
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    // Optionally update state or show a success notification
                    setState(prevState => ({
                        ...prevState,
                        receivers: prevState.receivers.map((r, sidx) =>
                            sidx === idx ? {
                                ...r,
                                location_name: e.location_name,
                                street_address: e.street_address,
                                city: e.city,
                                state: e.state,
                                zipcode: e.zipcode,
                                contact_name: e.contact_name,
                                phone_number: e.phone_number,
                            } : r
                        )
                    }));
                    props.parentActionChange(e, receiver.action_id);
                } else {
                    console.error('Error:', data);
                    alert(`Error: ${data.error} - ${data.message}. ${data.detail}`);
                }
            })
            .catch((error) => {
                console.error('Unexpected error:', error);
                alert('An unexpected error occurred. Contact support if the problem persists.');
            });
    
            return {
                ...receiver,
                location_name: e.location_name,
                street_address: e.street_address,
                city: e.city,
                state: e.state,
                zipcode: e.zipcode,
                contact_name: e.contact_name,
                phone_number: e.phone_number,
            };
        });

    };


    useEffect(() => {
        if (props && props.actions) {
            const actionsInfo = props.actions.filter(action => action.action == "r")

            const options = actionsInfo.map(d => ({

                "location_name": d.location_name,
                "load_match": props.load_id,
                "street_address": d.street_address,
                "city": d.city,
                "state": d.state,
                "contact_name": d.contact_name,
                "zipcode": d.zipcode,
                "phone_number": d.phone_number,
                "action_id": d.action_id,
                "pickup_number": d.pickup_number,
                "extra_notes": d.extra_notes,
                "order": d.order,
                "date": d.date

            }))
            let sortActions = options.sort((a, b) => a.order - b.order)
            setState({ ...state, receivers: sortActions })
            //props.parentReceivers(options)
        }
    }, [props.actions])

    useEffect(() => {
        if (props && props.locations) {
            const options = props.locations.map(d => ({
                "location_name": d.location_name,
                "value": d.location_id,
                "label": d.location_name,
                "street_address": d.street_address,
                "city": d.city,
                "state": d.state,
                "zipcode": d.zipcode,
                "contact_name": d.contact_name,
                "phone_number": d.phone_number,
            }))
            setLocations(options)

        }
    }, [props], [props.locations])
    const handleChange = (idx) => (e) => {
        const updateReceiver = state.receivers.map((receiver, sidx) => {
            if (idx !== sidx)
                return receiver;
            
            props.parentActions(e, receiver.action_id)
            return { ...receiver, [e.target.name]: e.target.value }
        });
        setState({ ...state, receivers: updateReceiver })
        //props.parentReceivers({...state, receivers: updateReceiver})
    }

    const handleDateChange = (idx) => (date) => {
        console.log(moment(date).format("MM-DD-YYYY"))
        const newDate = state.receivers.map((receiver, sidx) => {
            if (idx !== sidx) return receiver;
    
            authFetch(`https://fltbd.herokuapp.com/actions/${receiver.action_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    date: moment(date).format("MM-DD-YYYY")
                }),
                headers: { 'Content-Type': 'application/json' },
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    // Optionally update state or show a success notification
                    setState(prevState => ({
                        ...prevState,
                        receivers: prevState.receivers.map((r, sidx) =>
                            sidx === idx ? { ...r, date: moment(date).format("MM-DD-YYYY") } : r
                        )
                    }));
                    const e = {
                        target: {
                            name: "date",
                            value: moment(date).format("MM-DD-YYYY")
                        }
                    };
                    props.parentActions(e, receiver.action_id);
                } else {
                    console.error('Error:', data);
                    alert(`Error: ${data.error} - ${data.message}. ${data.detail}`);
                }
            })
            .catch((error) => {
                console.error('Unexpected error:', error);
                alert('An unexpected error occurred. Contact support if the problem persists.');
            });
    
            return { ...receiver, date: moment(date).format("MM-DD-YYYY") };
        });
    
        setState({ ...state, receivers: newDate });

    };
    const handleFocus = (idx) => (e) => {
        setFocus(false)
        const updateReceiver = state.receivers.map((receiver, sidx) => {
            if (idx !== sidx)
                return receiver;
            authFetch(`https://fltbd.herokuapp.com/actions/${receiver.action_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    [e.target.name]: e.target.value
                }),
                headers: { 'Content-Type': 'application/json' },
            })

            return { ...receiver, [e.target.name]: e.target.value }
        });

    }

    return (
        <>
            <div>
                <div class="card">
                    <div class="card-body">
                        <form>
                            <button type="button" onClick={handleAddReceiver} className="small" class="btn btn-red float-right" disabled={props.archived}>Add Drop Off</button>
                        </form>
                        <h3 class="card-title">Receivers </h3>
                    </div>
                </div>
                {state && state.receivers?.map((receiver, idx) => (
    <div key={receiver.action_id} className="card"> {/* Key is applied here */}
        <div className="card-body">
            <div className="receiver alert alert-primary">
                {receiver.location_name === ""
                    ?
                    <label><b>{`Drop #${idx + 1}`}</b> - Select Location Below</label>
                    :
                    <label><b>{`Drop #${idx + 1}`}</b></label>
                }
                <Select options={locations} placeholder={receiver.location_name} onChange={handleReceiverChange(idx)} isDisabled={props.archived}/>
                <br />
                <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">Street Address</span>
                    </div>
                    <input
                        onBlur={handleFocus(idx)}
                        onFocus={() => setFocus(true)}
                        type="text"
                        value={receiver.street_address}
                        name="street_address"
                        className="form-control"
                        onChange={handleChange(idx)} 
                        disabled={props.archived}
                        />
                </div>
                <div className="row">
                    <div className="col">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">City</span>
                            </div>
                            <input
                                onBlur={handleFocus(idx)}
                                onFocus={() => setFocus(true)}
                                type="text"
                                value={receiver.city}
                                name="city"
                                className="form-control"
                                onChange={handleChange(idx)} 
                                disabled={props.archived}
                                />
                        </div>
                    </div>
                    <div className="col">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">State</span>
                            </div>
                            <input
                                onBlur={handleFocus(idx)}
                                onFocus={() => setFocus(true)}
                                type="text"
                                value={receiver.state}
                                name="state"
                                className="form-control"
                                onChange={handleChange(idx)} 
                                disabled={props.archived}
                                />
                        </div>
                    </div>
                    <div className="col">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Zipcode</span>
                            </div>
                            <input
                                onBlur={handleFocus(idx)}
                                onFocus={() => setFocus(true)}
                                type="text"
                                name="zipcode"
                                value={receiver.zipcode}
                                className="form-control"
                                onChange={handleChange(idx)} 
                                disabled={props.archived}
                                />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-default">Contact</span>
                            </div>
                            <input
                                onBlur={handleFocus(idx)}
                                onFocus={() => setFocus(true)}
                                type="text"
                                name="contact_name"
                                value={receiver.contact_name}
                                className="form-control"
                                onChange={handleChange(idx)} 
                                disabled={props.archived}
                                />
                        </div>
                    </div>
                    <div className="col">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-default">Phone Number</span>
                            </div>
                            <input
                                onBlur={handleFocus(idx)}
                                onFocus={() => setFocus(true)}
                                type="text"
                                value={receiver.phone_number}
                                name="phone_number"
                                className="form-control"
                                onChange={handleChange(idx)} 
                                disabled={props.archived}
                                />
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Extra Notes </span>
                        </div>
                        <textarea
                            onBlur={handleFocus(idx)}
                            onFocus={() => setFocus(true)}
                            className="form-control"
                            value={receiver.extra_notes}
                            name="extra_notes"
                            onChange={handleChange(idx)} 
                            disabled={props.archived}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">

                        <DatePicker
                            selected={moment(receiver.date).isValid() ? moment(receiver.date).toDate() : new Date()}
                            name="date"
                            className="form-control"
                            onChange={handleDateChange(idx)} 
                            disabled={props.archived}
                            />
                    </div>
                    <div className="col-4">
                    </div>
                    <div className="col-2">
                        <input type="text" className="form-control" name="order" value={receiver.order} onChange={handleChange(idx)} disabled={props.archived} />
                    </div>

                </div>

                <div className="row">
                    <div className="col-10">
                    </div>
                    <div className="col-2 mt-3">
                        <button
                            type="button"
                            onClick={handleRemoveReceiver(idx)}
                            className="btn btn-danger float-right small"
                            disabled={props.archived}
                            >
                            Remove
                        </button>
                    </div>
                </div>


            </div>
        </div>
    </div>

))}


            </div >

        </>
    )


}

export default LoadsReceivers