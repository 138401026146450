import React, { useState, useEffect, Component } from 'react'
import { authFetch } from '../../auth';
import { Alert } from "react-bootstrap";
import UseAlert from '../Alerts/UseAlerts';

export default function CarriersItemCarrierDetails(props) {
    const [state, setState] = useState('')
    const {
        alertState,
        showSuccessAlert,
        showFailAlert,
        showNoChangeAlert
    } = UseAlert();
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }
    
    const carrierPhoneChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value

        })
        props.parentCallbackCarrierPhone(e.target.value)
    }

    const handleDetails = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value

        })
        props.parentHandleChangeDetails(e)
    }


    useEffect(() => {
        setState(props)
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const response = await authFetch(`https://fltbd.herokuapp.com/api/carriers/${props.carrier_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                carrier_name: state.carrier_name,
                carrier_dba: state.carrier_dba || ' ',
                physical_address: state.physical_address,
                phone_number: state.phone_number,
                email: state.email,
            }),
            headers: { 'Content-Type': 'application/json' },
        });
    
        // Check if the response indicates no changes made
        if (response.status === 304 || response.status === 405) {
            setEdit(!edit);
            // Optionally, add an alert to notify no changes:
            // showNoChangeAlert('No changes were made as the data was the same.');
            return;
        }
    
        // Handle non-ok responses that may indicate errors
        if (!response.ok) {
            if (response.status === 500) {
                showFailAlert('Server Error: Please try again later or contact support.');
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }
    
        // Assuming a successful response, parse the JSON data
        const data = await response.json();
        // Handle specific data status expected in the JSON response
        if (data.status === 200) {
            
            showSuccessAlert('Success: Carrier Information Updated');
            setEdit(!edit);
    
            props.updateCarrier({
                carrier_id: props.carrier_id,  // Assuming `carrier_id` is needed similarly to `customer_id`
                carrier_name: state.carrier_name,
                carrier_dba: state.carrier_dba,
                physical_address: state.physical_address,
                phone_number: state.phone_number,
                email: state.email,
            });
        } else {
            console.log(`Update failed! reason: ${data.reason}`);
        }
    }
    return (
        <div class="card">

            <div class="card-header">
                <h3 class="card-title">Carrier Details</h3>

                <div id="edit" class="edit-button">
                    <a href="#edit" id="edit" onClick={changeEdit}> [edit]</a>

                </div>
            </div>
            
                <div class="card-body">
                {alertState.show && (
                    <Alert variant={`${alertState.type}`}>
                        {alertState.message}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                <div class="form-row">
                        <div class="col mb-3">
                    <label class="form-label" >Carrier Name</label>
                    <input type="text" class="form-control" value={state.carrier_name} name="carrier_name" onChange={handleDetails} readOnly={edit} />
                </div>
                </div>
                <div class="form-row">
                        <div class="col mb-3">
                    <label class="form-label" >Carrier DBA</label>
                    <input type="text" class="form-control" value={state.carrier_dba} name="carrier_dba" onChange={handleDetails} readOnly={edit} />
                </div>
                </div>
                <div class="form-row">
                        <div class="col mb-3">
                    <label class="form-label" >Carrier Physical Address </label>
                    <textarea class="form-control" value={state.physical_address} name="physical_address" onChange={handleDetails} readOnly={edit} />
                </div>
                </div>
                    <div class="form-row">
                       
                        <div class="col-md-6 mb-3">
                            <label class="form-label">MC Number</label>
                            <input type="text" class="form-control" value={state.carrier_mc} name="carrier_mc" readOnly />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">DOT Number</label>
                            <input type="text" class="form-control" value={state.carrier_dot} name="carrier_dot" readOnly />
                        </div>
                    </div>
                    <div class="form-row">
                    <div class="col-md-6 mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control" value={state.phone_number} name="phone_number" onChange={carrierPhoneChange} required readOnly={edit} />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" value={state.email} name="email" onChange={handleDetails} required readOnly={edit} />
                        </div>
                    
                    </div>
                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                         </form>
                </div>
           
        </div>
    )

}