import React, { useEffect, useState } from "react";
import {
    Route, Switch, Redirect, Router, useHistory
} from 'react-router-dom';
import { useAuth, authFetch, logout } from "../../auth";

import AdvancedQuote from "./AdvancedQuote";


export default function QuotesContainer(props) {
    const [data, setData] = useState([])



useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

    return(
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">

            <AdvancedQuote />
        </div>

    )
}