import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
} from "react-router-dom";

export default function ForgotPassword(props) {
    const [email, setEmail] = useState()
    const [sent, setSent] = useState()
    const [redirect, setRedirect] = useState()
    const [error, setError] = useState()
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    useEffect(() => {
        document.title = props.title || "";
      }, [props.title]);
    

    const onSubmitClick = (e) => {
        e.preventDefault()
        let opts = {
            "email": email.toLowerCase(),
        }
        fetch('https://fltbd.herokuapp.com/api/forgot-password', {
            method: 'PUT',
            body: JSON.stringify(opts),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then(r => r.json())
        .then(d => {
            if(d.status_code) {
                setError(d.message)
            }
            if(d.success === true) {
                setSent(true)
            }
        })


    }

    return (
        <div class="container-tight py-4">
            <div class="text-center mb-4">
            </div>
            <div class="text-center mb-4">

          <h1>Forgot Password</h1>
        </div>
            <form class="card card-md" action="." method="get">
                <div class="card-body">
                    
                    
                    {sent ?
                     <p class="colorwhite mb-4">We have sent you a link to your email to reset your password. Once you reset the password you will be able to <Link to="/login"><a href="#">login</a></Link> with your new details.</p>
                    :
                    <>
                    <p class="mb-4 colorwhite">Enter your email address and your password will be reset and emailed to you.</p>
                    <div class="mb-3">
                        <label class="form-label">Email Address</label>
                        <input type="email" class="form-control mb-2" placeholder="Enter Email" onChange={handleEmailChange} value={email} />
                        <small class="text-danger">{error}</small>
                    </div>
                    <div class="form-footer">
                        <a href="#" class="btn btn-primary w-100" onClick={onSubmitClick}>
                            Forgot Password
                        </a>
                    </div>
                    <hr />
                    <p className="colorwhite">Go back to <Link to="/login"><a href="#"><b>Login</b></a></Link></p>
                    
                    </>
                }
                </div>

            </form>
        </div>
    )

}