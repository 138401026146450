import React, { useMemo, useState, useEffect } from "react";
import reactDom from "react-dom";
import CustomersTable from './CustomersTable'
import CustomersHeader from './CustomersHeader'
import delCustomer from './CustomersTable'

import axios from 'axios'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
  useHistory
} from "react-router-dom";
import { useAuth, authFetch, logout } from "../../auth"
import CustomersField from "./CustomersField";
import Modal from 'react-bootstrap/Modal';

import LoadingModal from "../Modals/LoadingModal";




function CustomersContainer(props) {
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true);

    const handleNewCustomer = (NewCustomer) => {
        const dataCopy = [...customers]
        setCustomers([...dataCopy, NewCustomer])
        props.updateCustomersList([...dataCopy, NewCustomer])
    }

    
    const handleLoading = (Status) => {
      setLoading(Status)
    }
    

      useEffect(() => {
        if(props  &&  props.customers) {
            setCustomers(props.customers)
            setShow(false)
            setLoading(false)
        }
    }, [props.customers])
    
    useEffect(() => {
        document.title = props.title || "";
      }, [props.title]);
    
    return (
      <>
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          
            <CustomersHeader />
            <CustomersField createNewLocation={props.createNewLocation} handleLoading={handleLoading} parentNewCustomer={handleNewCustomer} />
            <CustomersTable customersData={customers} />
        </div>
                {loading && (
                  <Modal show={show} size="sm" centered>
                      <LoadingModal />
                  </Modal>
              )}
      </>
    )
    
}


export default CustomersContainer