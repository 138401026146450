import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../auth";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useRouteMatch,
    useParams
} from "react-router-dom";
import Select from 'react-select'
import DatePicker from "react-datepicker"
import moment from 'moment';
import CustomerInfoEdit from "./EditInvoice/CustomerInfoEdit";
import ItemsInfoEdit from "./EditInvoice/ItemsInfoEdit";




export default function AccountingInvoice(props) {
    const params = useParams();

    const [data, setData] = useState([])
    const [customerID, setCustomerID] = useState()
    const [edit, setEdit] = useState(false)

    const getCustomerID = (Customer) => {
        setCustomerID(Customer.id)
    }

    const changeEdit = () => {
        setEdit(!edit);
    }

    useEffect(() => {
        if (props && props.invoices) {
            const invoiceInfo = props.invoices.filter(invoice => invoice.invoice_id == params.invoice_id)
            setData(invoiceInfo[0])
            
        }

    }, [props.invoices])
    const handleCustomerChange = (Details) => {
        setCustomerID(Details.id)
        console.log(Details)
        setData(prevState => {
            return { ...prevState,  ...Details  };
        });
        if (Details.terms !== null && Details.terms.length > 0) {
            getDueDate(Details.terms, data.date)
        }
    }
    const handleChange = (e) => {

        setData({ ...data, [e.target.name]: e.target.value })
    }

    const updateCustomer = (Details) => {
        
        setData(prevState => {
            return { ...prevState,  ...Details  };
        });
        if (Details.terms !== null && Details.terms.length > 0) {
            getDueDate(Details.terms, data.date)
        }

    }
    const handleDetailChange = (e) => {
        if(e.label) {
            setData({...data, status: e.value})
        }
        else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }

    const handleDateChange = (date, name) => {
        const terms = data.terms

        if (name === "date") {
            setData({ ...data, [name]: date })
            getDueDate(terms, date)


        }
    }
    function getDueDate(e, date) {
        if (e == "Net 60") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(60, 'days').toDate() };
            })
        }
        if (e == "Net 45") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(45, 'days').toDate() };
            })
        }
        if (e == "Net 30") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(30, 'days').toDate() };
            })
        }
        if (e == "Net 15") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(15, 'days').toDate() };
            })
        }
        if (e == "Net 10") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(10, 'days').toDate() };
            })
        }
        if (e == "Due On Receipt") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(0, 'days').toDate() };
            })
        }

    }
    return (
        <div className="card">
            <div class="card-header">
            <h3 class="card-title">
                Invoice #{data ?
                            <> 
                            {data.invoice_number}&nbsp;
                            <a href={`https://fltbd-bucket.s3.us-east-2.amazonaws.com/invoice_${params.invoice_id}.pdf`} target="_blank">
            <button class="btn btn-icon btn-info" >
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-pdf"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 8v8h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-2z" /><path d="M3 12h2a2 2 0 1 0 0 -4h-2v8" /><path d="M17 12h3" /><path d="M21 8h-4v8" /></svg>                    </button>
            </a> 
                            </>
                            : 
                            
                            "loading..."}

            </h3>
                <div class="edit-button">
                    <a href="#" className="link-fix" onClick={changeEdit}> [edit]</a>

                </div>
            </div>
            <div className="card-body">
                
                        <CustomerInfoEdit {...data} {...props}
                            edit={edit}
                            getCustomerID={getCustomerID}
                            handleChange={handleChange}
                            handleDetailChange={handleDetailChange}
                            handleDateChange={handleDateChange}
                            handleCustomerChange={handleCustomerChange}
                            updateCustomer={updateCustomer}
                        />
                        <ItemsInfoEdit {...data} {...props}
                            updateInvoiceList={props.updateInvoiceList}
                            customer_id={customerID}
                            edit={edit}
                        />

                  
                
            </div>
        </div>
    )
}