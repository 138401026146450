import React, { useEffect, useState } from "react";
import {
    useHistory
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { authFetch } from "../../auth";
import { Alert } from "react-bootstrap";


const apiGoogleKey = process.env.REACT_APP_GOOGLE_KEY;

function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}

export default function CustomersField(props) {
    const [newCustomer, setNewCustomer] = useState({
        customer_name: '',
        contact_name: '',
        phone_number: '',
        email: '',
        customer_street_address: '',
        customer_state: '',
        customer_city: '',
        customer_zipcode: '',
        terms: ""
    });
    const [collapse, setCollapse] = useState(true);
    const [showingAlert, setShowingAlert] = useState(false);
    const [createLocation, setCreateLocation] = useState(false)
    const history = useHistory();

    useEffect(() => {
        const initializeGooglePlaces = () => {
            const autocomplete = new window.google.maps.places.Autocomplete(
                document.getElementById('inputStreetAddress'),
                { types: ['address'] }
            );

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const addressComponents = place.address_components;

                let streetAddress = '';
                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes('street_number')) {
                        streetAddress = component.long_name + ' ' + streetAddress;
                    } else if (types.includes('route')) {
                        streetAddress += component.long_name;
                    }
                });

                const updatedLocation = {
                    ...newCustomer,
                    customer_street_address: streetAddress,
                    customer_city: '',
                    customer_state: '',
                    customer_zipcode: ''
                };

                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes('locality')) {
                        updatedLocation.customer_city = component.long_name;
                    } else if (types.includes('administrative_area_level_1')) {
                        updatedLocation.customer_state = component.short_name;
                    } else if (types.includes('postal_code')) {
                        updatedLocation.customer_zipcode = component.long_name;
                    }
                });

                setNewCustomer(updatedLocation);
            });
        };

        const loadGoogleMapsScript = () => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAb9aeuBsPTn_G5m9CbssQUj9GJwSQZFyQ&libraries=places`;
            script.async = true;
            script.onload = initializeGooglePlaces;
            document.body.appendChild(script);
        };

        if (!window.google) {
            loadGoogleMapsScript();
        } else {
            initializeGooglePlaces();
        }
    }, [newCustomer]);

    const onChange = e => {
        setNewCustomer({
            ...newCustomer,
            [e.target.name]: e.target.value
        });
    }

    const handleInput = e => {
        setNewCustomer({
            ...newCustomer,
            [e.target.name]: formatPhoneNumber(e.target.value)
        });
    };

    const handleCheckboxChange = (event) => {
        setCreateLocation(event.target.checked);
    };

    const handleSubmit = e => {
        props.handleLoading(true);
        e.preventDefault();
        const uniqueID = uuidv4();
        authFetch(`https://fltbd.herokuapp.com/api/customers`, {
            method: 'PUT',
            body: JSON.stringify({
                create_location: createLocation,
                customer_id: uniqueID,
                customer_name: newCustomer.customer_name,
                contact_name: newCustomer.contact_name,
                phone_number: newCustomer.phone_number,
                email: newCustomer.email,
                customer_street_address: newCustomer.customer_street_address,
                customer_state: newCustomer.customer_state,
                customer_city: newCustomer.customer_city,
                customer_zipcode: newCustomer.customer_zipcode,
                terms: "Net 30"
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(r => r.json())
            .then(data => {
                if (data.status === 200) {
                    props.handleLoading(false);
                    onShowAlert();
                    props.parentNewCustomer({
                        customer_id: uniqueID,
                        customer_name: newCustomer.customer_name,
                        contact_name: newCustomer.contact_name,
                        phone_number: newCustomer.phone_number,
                        email: newCustomer.email,
                        customer_street_address: newCustomer.customer_street_address,
                        customer_state: newCustomer.customer_state,
                        customer_city: newCustomer.customer_city,
                        customer_zipcode: newCustomer.customer_zipcode,
                        terms: "Net 30"
                    });
                    if (createLocation) {
                        props.createNewLocation({
                            create_location: createLocation,
                            location_id: data.action,
                            location_name: newCustomer.customer_name,
                            contact_name: newCustomer.contact_name,
                            phone_number: newCustomer.phone_number,
                            street_address: newCustomer.customer_street_address,
                            city: newCustomer.customer_city,
                            state: newCustomer.customer_state,
                            zipcode: newCustomer.customer_zipcode
                        });
                    }
                    setCreateLocation(false);
                    setNewCustomer({
                        customer_name: '',
                        contact_name: '',
                        phone_number: '',
                        email: '',
                        customer_street_address: '',
                        customer_state: '',
                        customer_city: '',
                        customer_zipcode: '',
                        terms: ""
                    });
                    history.push({
                        pathname: `/customer/${uniqueID}`
                    });
                }
            });
    }

    const onShowAlert = () => {
        setShowingAlert(true,
            setTimeout(() => {
                setShowingAlert(false)
            }, 2000)
        );
    }

    

    return (
        <div className="card" >
            <div className="card-header" data-toggle="collapse"
                data-target="#addCustomer" onClick={() => setCollapse(!collapse)} style={{ cursor: "pointer" }}>
                <h3 className="card-title">Add New Customer</h3>
                <div className="edit-button">
                    {collapse ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="white" fill="white" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="18" y1="13" x2="12" y2="19" /><line x1="6" y1="13" x2="12" y2="19" /></svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="white" fill="white" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="18" y1="11" x2="12" y2="5" /><line x1="6" y1="11" x2="12" y2="5" /></svg>
                    }
                </div>
            </div>
            <div className="card-body collapse" id="addCustomer">
                <Alert variant="success" show={showingAlert} >
                    Customer Successfully Added.
                </Alert>
                <form onSubmit={handleSubmit}>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="createNewLocation"
                            checked={createLocation}
                            onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label colorwhite" htmlFor="createNewLocation">
                            Create New Location with Customer Details
                        </label>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputCustomerName" className="form-label">Customer Name</label>
                        <input type="text" className="form-control" placeholder="Enter Customer Name" value={newCustomer.customer_name} name="customer_name" onChange={onChange} required />
                    </div>

                    <div className="form-row">
                        <div className="col-md-4 mb-3">
                            <label className="form-label">Contact Name</label>
                            <input type="text" className="form-control" placeholder="Contact Name" value={newCustomer.contact_name} name="contact_name" onChange={onChange} required />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="validationTooltip02" className="form-label">Email</label>
                            <input type="text" className="form-control" id="validationTooltip02" placeholder="Enter Email" value={newCustomer.email} name="email" onChange={onChange} required />
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="validationTooltipUsername" className="form-label">Phone Number</label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="validationTooltipUsername" placeholder="Enter Phone Number"
                                    value={newCustomer.phone_number} name="phone_number" onChange={handleInput} required />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label className="form-label">Street Address</label>
                            <input type="text" className="form-control" id="inputStreetAddress" placeholder="Enter St Address(123 Fake St)"
                                value={newCustomer.customer_street_address} name="customer_street_address" onChange={onChange} required />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-4 mb-3">
                            <label className="form-label">City</label>
                            <input type="text" className="form-control" placeholder="Enter Customer City" value={newCustomer.customer_city}
                                name="customer_city" onChange={onChange} required />
                        </div>
                        <div className="form-group col-md-4 mb-3">
                        <label className="form-label" htmlFor="inputState">State</label>
                            <select className="form-control" value={newCustomer.customer_state} name="customer_state" onChange={onChange} required >
                                <option value="">Choose...</option>
                                <optgroup label="U.S. States/Territories">
                                    <option value="AK">AK - Alaska</option>
                                    <option value="AL">AL - Alabama</option>
                                    <option value="AR">AR - Arkansas</option>
                                    <option value="AZ">AZ - Arizona</option>
                                    <option value="CA">CA - California</option>
                                    <option value="CO">CO - Colorado</option>
                                    <option value="CT">CT - Connecticut</option>
                                    <option value="DC">DC - District of Columbia</option>
                                    <option value="DE">DE - Delaware</option>
                                    <option value="FL">FL - Florida</option>
                                    <option value="GA">GA - Georgia</option>
                                    <option value="HI">HI - Hawaii</option>
                                    <option value="IA">IA - Iowa</option>
                                    <option value="ID">ID - Idaho</option>
                                    <option value="IL">IL - Illinois</option>
                                    <option value="IN">IN - Indiana</option>
                                    <option value="KS">KS - Kansas</option>
                                    <option value="KY">KY - Kentucky</option>
                                    <option value="LA">LA - Louisiana</option>
                                    <option value="MA">MA - Massachusetts</option>
                                    <option value="MD">MD - Maryland</option>
                                    <option value="ME">ME - Maine</option>
                                    <option value="MI">MI - Michigan</option>
                                    <option value="MN">MN - Minnesota</option>
                                    <option value="MO">MO - Missouri</option>
                                    <option value="MS">MS - Mississippi</option>
                                    <option value="MT">MT - Montana</option>
                                    <option value="NC">NC - North Carolina</option>
                                    <option value="ND">ND - North Dakota</option>
                                    <option value="NE">NE - Nebraska</option>
                                    <option value="NH">NH - New Hampshire</option>
                                    <option value="NJ">NJ - New Jersey</option>
                                    <option value="NM">NM - New Mexico</option>
                                    <option value="NV">NV - Nevada</option>
                                    <option value="NY">NY - New York</option>
                                    <option value="OH">OH - Ohio</option>
                                    <option value="OK">OK - Oklahoma</option>
                                    <option value="OR">OR - Oregon</option>
                                    <option value="PA">PA - Pennsylvania</option>
                                    <option value="PR">PR - Puerto Rico</option>
                                    <option value="RI">RI - Rhode Island</option>
                                    <option value="SC">SC - South Carolina</option>
                                    <option value="SD">SD - South Dakota</option>
                                    <option value="TN">TN - Tennessee</option>
                                    <option value="TX">TX - Texas</option>
                                    <option value="UT">UT - Utah</option>
                                    <option value="VA">VA - Virginia</option>
                                    <option value="VT">VT - Vermont</option>
                                    <option value="WA">WA - Washington</option>
                                    <option value="WI">WI - Wisconsin</option>
                                    <option value="WV">WV - West Virginia</option>
                                    <option value="WY">WY - Wyoming</option>
                                </optgroup>
                                <optgroup label="Canadian Provinces">
                                    <option value="AB">AB - Alberta</option>
                                    <option value="BC">BC - British Columbia</option>
                                    <option value="MB">MB - Manitoba</option>
                                    <option value="NB">NB - New Brunswick</option>
                                    <option value="NF">NF - Newfoundland</option>
                                    <option value="NT">NT - Northwest Territories</option>
                                    <option value="NS">NS - Nova Scotia</option>
                                    <option value="NU">NU - Nunavut</option>
                                    <option value="ON">ON - Ontario</option>
                                    <option value="PE">PE - Prince Edward Island</option>
                                    <option value="QC">QC - Quebec</option>
                                    <option value="SK">SK - Saskatchewan</option>
                                    <option value="YT">YT - Yukon Territory</option>

                                </optgroup>
                            </select>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label className="form-label">Zipcode</label>
                            <input type="text" placeholder="Enter Customer Zipcode" className="form-control" value={newCustomer.customer_zipcode} name="customer_zipcode" onChange={onChange} required />
                        </div>
                    </div>

                    <button className="btn btn-success float-right" style={{ marginTop: "10px" }}>Create Customer</button>
                </form>
            </div>
        </div>
    );
}