import React, { Component, useState, useEffect } from 'react';
import { authFetch } from '../../auth';
import { v4 as uuidv4 } from "uuid";
import { useParams, withRouter, useHistory } from "react-router"


export default function CarriersAdd(props) {
    const history = useHistory()
    const [submitting, setSubmitting] = useState(false)
    const uniqueID = uuidv4()
    const [state, setState] = useState({ factor: "No", carrier_id: uniqueID })
    const [carriers, setCarriers] = useState([])
    const [collapse, setCollapse] = useState(true)


    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value

        })
    }
    const handleNewCarrier = (NewCarrier) => {
        const dataCopy = [...carriers]
        setCarriers([...dataCopy, NewCarrier])
        props.updateCarriersList([...dataCopy, NewCarrier])
    }

    const handleSubmit = e => {
        e.preventDefault();

        
        authFetch(`https://fltbd.herokuapp.com/api/carriers`, {
            method: 'PUT',
            body: JSON.stringify({
                carrier_id: state.carrier_id,
                carrier_name: state.carrier_name,
                carrier_dba: state.carrier_dba,
                physical_address: state.physical_address,
                phone_number: state.phone_number,
                carrier_mc: state.carrier_mc,
                carrier_dot: state.carrier_dot,
                carrier_email: state.carrier_email,
                billing_address: state.carrier_billing_address,
                federal_id: state.federal_id,
                factor: state.factor,
                factoring_company: state.factoring_company
            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(r => r.json())
            .then(g => {
                console.log(g)
                if (g.status===200) {
                    
                    handleNewCarrier({ ...state, id: g.id })
                }
            })
        setSubmitting(true)
        setTimeout(() => {
            history.push({
                pathname: `/carriers`,
            })
        }, 1500);



        
    }

    useEffect(() => {
        if (props && props.carriers) {
            setCarriers(props.carriers)
        }
    }, [props.carriers])
    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    return (

        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div class="d-sm-flex">
                <h1>Add Carrier</h1>
                
            </div>
            <form onSubmit={handleSubmit}>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">

                            <div class="card-header">
                                <h3 class="card-title">Carrier Details</h3>


                            </div>

                            <div class="card-body">
                                <label class="form-label" >Carrier Name</label>
                                <input type="text" class="form-control" value={state.carrier_name} name="carrier_name" onChange={handleChange} required />


                                <div class="form-row mt-3">

                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">MC Number</label>
                                        <input type="text" class="form-control" value={state.carrier_mc} name="carrier_mc" onChange={handleChange} />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">DOT Number</label>
                                        <input type="text" class="form-control" value={state.carrier_dot} name="carrier_dot" onChange={handleChange} required />
                                    </div>
                                </div>
                                <div class="form-row mt-3">
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">Phone</label>
                                        <input type="text" class="form-control" value={state.phone_number} name="phone_number" onChange={handleChange} required />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label" >Carrier Email</label>
                                        <input type="text" class="form-control" value={state.carrier_email} name="carrier_email" onChange={handleChange} />
                                    </div>


                                </div>



                            </div>

                        </div>
                    </div>

                  


                    <div class="col-md-12">
                        <div class="card">
                        <div class="card-header" data-toggle="collapse"
                data-target="#addCarrier" onClick={() => setCollapse(!collapse)} style={{ cursor: "pointer" }}>
                <h3 class="card-title">More Details</h3>
                <div class="edit-button">
                    {collapse ?
                    	<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="white" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="12" y1="5" x2="12" y2="19" /><line x1="18" y1="13" x2="12" y2="19" /><line x1="6" y1="13" x2="12" y2="19" /></svg>

                        :
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="white" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="12" y1="5" x2="12" y2="19" /><line x1="18" y1="11" x2="12" y2="5" /><line x1="6" y1="11" x2="12" y2="5" /></svg>

                    }
                </div>
            </div>

                            <div class="card-body collapse" id="addCarrier">
                                <label class="form-label" >Carrier DBA</label>
                                <input type="text" class="form-control" value={state.carrier_dba} name="carrier_dba" onChange={handleChange} />
                                <label class="form-label  mt-3" >Carrier Billing Address </label>
                                <textarea class="form-control" value={state.carrier_billing_address} name="carrier_billing_address" onChange={handleChange}  />
                                <label class="form-label mt-3" >Carrier Physical Address </label>
                                <textarea class="form-control" value={state.physical_address} name="physical_address" onChange={handleChange}  />
                                <label class="form-label mt-3" >Federal ID</label>
                                <input type="text" class="form-control" value={state.federal_id} name="federal_id" onChange={handleChange}  />
                                <label class="form-label mt-3" >Does the carrier factor their receivables? </label>
                                <div class="form-check colorwhite">
                                    <input class="form-check-input" value="Yes" name="factor" checked={state.factor === "Yes"} type="radio" onChange={handleChange} />
                                    <label class="form-check-label">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check colorwhite">
                                    <input class="form-check-input" value="No" name="factor" checked={state.factor === "No"} type="radio" onChange={handleChange} />
                                    <label class="form-check-label">
                                        No
                                    </label>
                                </div>
                                {state.factor == "Yes" ? <div>
                                    <label class="form-label" >Factoring Company </label>
                                    <input type="text" class="form-control colorwhite" value={state.factoring_company} name="factoring_company" onChange={handleChange} />
                                </div> : null}


                            </div>

                        </div>

                        <br />
                        {submitting ?
                            <button class="btn btn-success float-right" disabled>Submitting...</button>
                            :

                            <button type="submit" className="btn btn-primary float-right">Submit</button>
                        }

                    </div>


                </div>

            </form>
        </div>

    )
}

