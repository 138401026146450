import React, { Component, useEffect, useState } from "react"
import LoadsHeader from "./LoadsHeader"
import {
    Route, Switch, Redirect, Router
} from 'react-router-dom';
import LoadsTable from "./LoadsTable";
import { Button, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import OnboardModal from "../Modals/OnBoardModal";
import LoadingModal from "../Modals/LoadingModal";
import SubStatusModal from "../Modals/SubStatusModal";



const Styles = styled.div`
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
background-color: #222831;
color: #fff;
border: 1px solid wheat;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border:1px solid wheat;
}

.navbar-dark .navbar-nav .nav-link a:hover {
    color: rgba(255,255,255,.7);
  
}
.nav-link.hover {
    color: black;
}

a {
    color: white;
}
a:hover {
    color: white;
}

`
const applyGlobalFilter = (loads, filter) => {
    if (!filter) return loads;

    const filterLower = filter.toLowerCase();

    return loads.filter(load => {
        const matchesGeneralProperties = (
            (load.load_number && load.load_number.toString().toLowerCase().includes(filterLower)) ||
            (load.carrier_name && load.carrier_name.toLowerCase().includes(filterLower)) ||
            (load.customer_name && load.customer_name.toLowerCase().includes(filterLower)) ||
            (load.trailer && load.trailer.toLowerCase().includes(filterLower)) ||
            (load.ref_number && load.ref_number.toString().toLowerCase().includes(filterLower)) ||
            (load.gross_pay && load.gross_pay.toString().toLowerCase().includes(filterLower)) ||
            (load.carrier_pay && load.carrier_pay.toString().toLowerCase().includes(filterLower)) ||
            (load.status && load.status.toLowerCase().includes(filterLower))
        );

        const matchesActions = load.actions && load.actions.some(action => 
            (action.city && action.city.toLowerCase().includes(filterLower)) ||
            (action.state && action.state.toLowerCase().includes(filterLower)) ||
            (action.action && action.action.toLowerCase().includes(filterLower))
        );

        return matchesGeneralProperties || matchesActions;
    });
};
export default function LoadsContainer(props) {

    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true);
    const [allLoads, setAllLoads] = useState([])
    const [activeLoads, setActiveLoads] = useState([])
    const [billingReady, setBillingReady] = useState([])
    const [globalFilter, setGlobalFilter] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);


    useEffect(() => {
        if (props && props.loads) {
            const loads = props.loads
            setAllLoads(loads)
            const active = props.loads.filter(load =>
                load.status !== "Completed" &&
                load.status !== "Invoiced" &&
                load.status !== "Paid")
            setActiveLoads(active)
            const readyForBilling = props.loads.filter(load => load.status == "Completed")
            setBillingReady(readyForBilling)
            setShow(false)
            setLoading(false)

        }
    }, [props.loads])

    const updateLoads = (List, Tab) => {
        // not being used
        // this does work by giving the tabs props
        if (Tab == "activeLoads") {
            const active = List.filter(load => load.status !== "Completed")
            setActiveLoads(active)
        }
        if (Tab == "billingReady") {
            const readyForBilling = List.filter(load => load.status == "Completed")
            setBillingReady(readyForBilling)
        }
        if (Tab == "allLoads") {
            setAllLoads(List)
        }
    }
    const updateSingle = (row, e) => {
        const dataCopy = [...props.loads]
        const mappedData = dataCopy.map(g => (g.load_id === row.row.values.load_id ? Object.assign({}, g, { status: e.value }) : g))

        props.updateLoadList(mappedData)
    }
    const patchUpdate = (patchLoads, e) => {
        const toPatch = new Set(patchLoads);
        const dataCopy = [...props.loads]
        const mappedData = dataCopy.map(g => (toPatch.has(g.load_id) ? Object.assign({}, g, { status: e.value }) : g))
        props.updateLoadList(mappedData)
    }
    const deleteLoads = (e) => {
        const toDelete = new Set(e);
        const dataCopy = [...props.loads]
        const newArray = dataCopy.filter(obj => !toDelete.has(obj.load_id));
        console.log(newArray)
        props.updateLoadList(newArray)
    }
    const copyLoad = (load) => {
        props.updateLoadList([...props.loads, load])
    }
    const filteredActiveLoads = applyGlobalFilter(activeLoads, globalFilter);
    const filteredBillingReady = applyGlobalFilter(billingReady, globalFilter);
    const filteredAllLoads = applyGlobalFilter(allLoads, globalFilter);


    return (
        <>
            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                {props.onBoard ? <OnboardModal {...props} /> : null}
                {!props.activeSub ? <SubStatusModal {...props} /> : null}

                <LoadsHeader updateLoadList={props.updateLoadList} {...props} />
                <Styles>
            <Tabs defaultActiveKey="activeLoads" className="navbar navbar-expand-md navbar-dark navbar-overlap d-print-none" style={{borderBottom: "0"}} transition={false}>  
                <Tab eventKey="activeLoads"  title="Active">  
                    <LoadsTable {...props} data={filteredActiveLoads}  
                        globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} 
                        copyLoad={copyLoad}   
                        updateLoads={updateLoads}   
                        tab="activeLoads"   
                        updateSingle={updateSingle}   
                        patchUpdate={patchUpdate}  
                        deleteLoads={deleteLoads}  
                        />  
                </Tab>  
                <Tab eventKey="billingReady" title="Ready For Billing">  
                    <LoadsTable {...props} data={filteredBillingReady}   
                    globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} 
                    copyLoad={copyLoad}   
                    updateLoads={updateLoads} tab="billingReady" updateSingle={updateSingle} patchUpdate={patchUpdate} />  
                </Tab>  
                <Tab eventKey="allLoads" title="All Loads">  
                    <LoadsTable {...props}   
                    globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} 
                    copyLoad={copyLoad}   
                    data={filteredAllLoads} updateLoads={updateLoads} tab="allLoads" updateSingle={updateSingle} patchUpdate={patchUpdate}/>  
                </Tab>  
            </Tabs>
            </Styles>

            </div>
            {loading && (
                <Modal show={show} size="sm" centered>
                    <LoadingModal />
                </Modal>
            )}
        </>
    )
}
