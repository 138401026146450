import React, { useEffect, useState } from "react"
import { login, useAuth, logout } from "../../../auth"
import { useParams, withRouter, useHistory } from "react-router"
import styled from "styled-components"
import Footer from "../../Footer"
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../../Modals/LoadingModal"

const Styles = styled.div`
.initiate-container {
    margin: 15px auto;
    padding: 70px;
    max-width: 850px;
    background-color: #fff;
    border: 1px solid #ccc;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
  }
  
  @media (max-width: 767px) {
    .initiate-container {
      padding: 35px 20px 70px 20px;
      margin-top: 0px;
      border: none;
      border-radius: 0px;
    }
  }`

function CarrierDetails(props) {
    const history = useHistory()
    const [details, setDetails] = useState({})


    const handleDetails = e => {
        fetch(`https://fltbd.herokuapp.com/get-carrier-info/${props.dotNumber}`)
            .then(response => response.json())
            .then(data =>
                props.ParentCallBackDetails(data))

    }
    const handleClear = e => {
        props.ParentCallBackDetails({})

    }
    const handleContract = e => {
        if (props.firstName == undefined) {
            props.ParentCallBackError('Must Fill Out All Required')
            console.log('sorry will not work')
        }
        else {
            fetch(`https://fltbd.herokuapp.com/initiate-contract-api/${props.match.params.contract_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    carrier_name: props.details.carrier_name,
                    carrier_dba: props.details.carrier_dba,
                    carrier_phone: props.details.carrier_phone,
                    carrier_address: props.details.physical_address,
                    carrier_mailaddress: props.details.billing_address,
                    factor: props.details.factor,
                    factoring_name: props.details.factoring_name,
                    carrier_mc: props.details.carrier_mc,
                    carrier_dot: props.dotNumber,
                    signed_by: props.firstName + ' ' + props.lastName,
                    units: props.details.units,
                }),
                headers: { 'Content-Type': 'application/json' },
            })
            history.push({
                pathname: `/contract/${props.match.params.contract_id}`,
                state: props.details
            })
        }

    }
    if (props.details.carrier_name == "Company not found") {
        return (
            <div>
                <center>
                    <div className="alert alert-danger">Company Not Found</div>
                    <h4 className="colorwhite">Please Check Your DOT Number and Re-Enter</h4>

                    <button type="submit" className="btn btn-info" onClick={() => { handleClear(); handleDetails() }}>Try Again</button>
                </center>
            </div>
        )
    }
    else if (props.details.carrier_name == undefined) {
        return <button type="submit" className="btn btn-primary" onClick={handleDetails}>Submit</button>
    }
    return (
        <div>
            <center>
                <h2 className="colorwhite">
                    {props.details.carrier_name}
                </h2>
                <h4 className="colorwhite">Is this your Company?</h4>
                <button className="btn btn-success" onClick={handleContract}>Yes</button> <button className="btn btn-danger" onClick={handleClear}>No</button>
            </center>
        </div>
    )
}
function InitiateContract(props) {

    const history = useHistory()
    const [state, setState] = useState({})
    const [dotNumber, setDOTNumber] = useState()
    const [details, setDetails] = useState({})
    const [data, setData] = useState({})
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true);


    const contract_id = props.match.params.contract_id
    const document = `https://fltbd-bucket.s3.us-east-2.amazonaws.com/${contract_id}.pdf`

    useEffect(() => {
        fetch(`https://fltbd.herokuapp.com/documents/contract/${props.match.params.contract_id}`)
            .then(response => response.json())
            .then(data => {
                setState(data)
                setTimeout(() => {
                    setLoading(false)
                    setShow(false)
                }, 1000)

            })


    }, [])
    const handleDOT = (e) => {
        setDOTNumber(e.target.value)
    }
    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    }
    const handleLastName = (e) => {
        setLastName(e.target.value)
    }
    const handleDetails = e => {
        fetch(`https://fltbd.herokuapp.com/get-carrier-info/${dotNumber}`)
            .then(response => {
                response.json()
                console.log(response)
            })
            .then(data => setDetails(data))

    }

    const onChange = e => {
        setDetails({
            ...details, [e.target.name]: e.target.value
        })
    }
    const handleCallbackDetails = (details) => {
        setDetails({ ...details, details })
    }
    const handleCallbackError = (ErrorMsg) => {
        setError(ErrorMsg)
    }
    if (loading == true) {
        return (
            <Modal show={show} size="sm" centered>
                <LoadingModal />
            </Modal>
        )
    }

    if (state.status == 'Completed') {
        return (
            <Styles>
                <div class="container-fluid initiate-container">
                    <div class="d-flex justify-content-center">
                        <h2 className="">This Contract Has Been Completed</h2>

                    </div>
                    <div class="d-flex justify-content-center">
                        <a href={document}>
                            <h3 className="">Here is a link to the contract</h3>
                        </a>
                    </div>
                </div>
            </Styles>
        )
    }
    if (state.status == null) {
        return (
            <Styles>
                <div class="container-fluid initiate-container">
                    <div class="d-flex justify-content-center">
                        <h2 className="colorwhite">This Contract Does Not Exist</h2>

                    </div>
                    <div class="d-flex justify-content-center">
                        <h3 className="colorwhite">Please contact the broker regarding this</h3>
                    </div>
                </div>
            </Styles>
        )
    }
    else {
        return (
            <div class="container">
                <div class="card mt-5">
                    <div class="card-header text-center colorwhite">
                        <h2 className="colorwhite">You are filling out a contract with: <span className="colorwheat">{state.company_name}</span></h2>
                    </div>
                    <div class="card-body">
                        <div class="text-center mb-4">
                            <h3 className="maincolorheader">Fill out the details below and confirm</h3>
                        </div>

                        <form>
                            <div class="mb-3 row">
                                <label for="first_name" class="col-sm-2 col-form-label">First Name</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="first_name" name="first_name" onChange={handleFirstName} />
                                    <div class="error-message">{error}</div>
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="last_name" class="col-sm-2 col-form-label">Last Name</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="last_name" name="last_name" onChange={handleLastName} />
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="dot_number" class="col-sm-2 col-form-label">USDOT</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="dot_number" name="dot_number" onChange={handleDOT} />
                                </div>
                            </div>
                        </form>

                        <div class="d-flex justify-content-center">
                            <CarrierDetails
                                ParentCallBackDetails={handleCallbackDetails}
                                ParentCallBackError={handleCallbackError}
                                state={state}
                                details={details}
                                firstName={firstName}
                                lastName={lastName}
                                dotNumber={dotNumber}
                                {...props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(InitiateContract)