import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react"
import { useParams } from "react-router"
import DOMPurify from 'dompurify';
import StepZilla from 'react-stepzilla'
import styled from "styled-components"
import axios from "axios";
import ContractCarrierDetails from "./ContractCarrierDetails";
import ContractBillingDetails from "./ContractBillingDetails";
import ContractCarrierInformaton from "./ContractCarrierInformation";
import ContractBrokerContract from "./ContractBrokerContract";
import ContractCompleteContract from "./ContractCompleteContract";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../../Modals/LoadingModal"


const Styles = styled.div`
.initiate-container {
    margin: 15px auto;
    padding: 70px;
    max-width: 850px;
    background-color: #fff;
    border: 1px solid #ccc;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
  }
  
  @media (max-width: 767px) {
    .initiate-container {
      padding: 35px 20px 70px 20px;
      margin-top: 0px;
      border: none;
      border-radius: 0px;
    }
  }`


export default function Contract(props, ref) {


    const [state, setState] = useState({})
    const [details, setDetails] = useState({ carrier_name: '' })
    const [data, setData] = useState({})
    const [isGenerating, setIsGenerating] = useState(false)
    const [ip, setIP] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [show, setShow] = useState(true);



    const getData = async () => {
        const res = await axios.get('https://api.ipify.org?format=json')
        setIP(res.data.ip)
    }

    useEffect(() => {
        //passing getData method to the lifecycle method
        getData()

    }, [])


    const getContract = () => {
        setTimeout(() => {
            fetch(`https://fltbd.herokuapp.com/documents/contract/${props.match.params.contract_id}`)
                .then(response => {
                    if (response.status === 404) {
                        //history.go(0)
                        setNotFound(true)
                        //return null
                    }
                    return response.json()
                })

                .then(data => {
                    if (data.status === "Completed") {
                        console.log('Completed')
                    }
                    else {
                        console.log('Not Completed')
                    }
                    getData()
                    setDetails({
                        ...data,
                        physical_address: data.carrier_address,
                        billing_address: data.carrier_mailaddress,
                        trailers: {
                            hotshot: false,
                    flatbed: false,
                    stepdeck: false,
                    doubledrop: false,
                    doubles: false,
                    rgn: false,
                    lowboy: false,
                    dryvan: false,
                    reefer: false,
                    sprinter: false,
                    box: false,
                    other: false
                        }
                    })

                    setIsLoading(false)
                }
                );
        }, 5000)
    }
    useEffect(() => {

        if (details.carrier_name === '') {
            setIsLoading(true)
            getContract()
        }
        else {
            console.log('false')
        }
        fetch(`https://fltbd.herokuapp.com/documents/contract/${props.match.params.contract_id}`)
            .then(response => response.json())
            .then(data => setDetails({
                ...data,
                physical_address: data.carrier_address,
                billing_address: data.carrier_mailaddress,
                trailers: {
                    hotshot: false,
                    flatbed: false,
                    stepdeck: false,
                    doubledrop: false,
                    doubles: false,
                    rgn: false,
                    lowboy: false,
                    dryvan: false,
                    reefer: false,
                    sprinter: false,
                    box: false,
                    other: false
                }
            }));

    }, [])



    const handleCarrierName = (CarrierName) => {
        setDetails({ ...details, carrier_name: CarrierName })
    }
    const handleCarrierDBA = (CarrierDBA) => {
        setDetails({ ...details, carrier_dba: CarrierDBA })
    }
    const handleCarrierAddress = (CarrierAddress) => {
        setDetails({ ...details, physical_address: CarrierAddress })
    }
    const handleCarrierPhone = (CarrierPhone) => {
        setDetails({ ...details, carrier_phone: CarrierPhone })
    }
    const handleCarrierMailAddress = (CarrierMailAddress) => {
        setDetails({ ...details, billing_address: CarrierMailAddress })
    }
    const handleCarrierFederalID = (CarrierFederalID) => {
        setDetails({ ...details, federal_id: CarrierFederalID })
    }
    const handleCarrierAgree = (CarrierAgree) => {
        setDetails({ ...details, carrier_agree: CarrierAgree })
    }
    const handleCarrierFactor = (CarrierFactor) => {
        setDetails({ ...details, factor: CarrierFactor })
    }
    const handleCarrierFactoringCompany = (CarrierFactoringCompany) => {
        setDetails({ ...details, factoring_name: CarrierFactoringCompany })
    }
    const handleGeneratingContract = (GeneratingContract) => {
        setIsGenerating(true)
    }
    const handleCarrierTrailers = (trailerList) => {
        setDetails({ ...details, trailers: trailerList })
    }
    const handleAreas = (Areas) => {
        setDetails({ ...details, areas: Areas })
    }
    const steps = [
        {
            name: 'Contract Carrier Details', component: <ContractCarrierDetails
                ParentCarrierName={handleCarrierName}
                ParentCarrierDBA={handleCarrierDBA}
                ParentCarrierAddress={handleCarrierAddress}
                ParentCarrierPhone={handleCarrierPhone}
                details={details}
            />
        },
        {
            name: 'Contract Billing Details', component: <ContractBillingDetails
                details={details}
                ParentCarrierFederalID={handleCarrierFederalID}
                ParentCarrierMailAddress={handleCarrierMailAddress}
                ParentCarrierFactor={handleCarrierFactor}
                ParentCarrierFactoringCompany={handleCarrierFactoringCompany}
            />
        },
        {
            name: 'Contract Information Details', component: <ContractCarrierInformaton
                details={details}
                parentCarrierTrailers={handleCarrierTrailers}
                parentAreas={handleAreas}
                {...props}
            />
        },
        {
            name: 'Broker Contract', component: <ContractBrokerContract
                state={state}
                details={details}

            />
        },


        {
            name: 'Complete Contract', component: <ContractCompleteContract
                state={state}
                details={details}
                parentIsGenerating={handleGeneratingContract}
                {...props}
                ip={ip}

            />
        }

    ]

    const placeHolder = () => {
        return (
            <Modal show={show} size="sm" centered>
            <LoadingModal />
        </Modal>
        )
    }

    if (details.status == 'Completed') {
        const contract_id  = props.match.params.contract_id
        const document = `https://fltbd-bucket.s3.us-east-2.amazonaws.com/${contract_id}.pdf`
        async function exists(url) {
            const result = await fetch(url, { method: 'HEAD' });
            return result.ok;
        }
        if(!exists(document)){
            setIsGenerating(true)
        }
        return (
            <div class="container pd-x-0 pda-lg-x-10 pd-xl-x-0">
                <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Completed Contract</h3>
                </div>
                    <div class="card-body">
                    <p class="card-title">If you can't view the contract below DOWNLOAD <a href={document} target="_blank">HERE</a></p>
                        <embed width="100%" height="600px" src={document}></embed>
                    </div>
                </div>

            </div>
        )
    }
    if (isGenerating) {
        setTimeout(() => {
            setIsGenerating(false)
            fetch(`https://fltbd.herokuapp.com/documents/contract/${props.match.params.contract_id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.status === "Completed") {
                        setDetails({
                            ...data,
                            status: data.status,
                        })
                    }
                    else {
                        setIsGenerating(true)
                    }
                }
                );
        }, 3500);
        return (
            <Styles>
                <div class="container-fluid initiate-container">
                    <div class="d-flex justify-content-center">
                        <h2>We are generating your contract...</h2>

                    </div>

                </div>
            </Styles>
        )
    }
    if (notFound) {

        return (
            <Styles>
                <div class="container-fluid initiate-container">
                    <div class="d-flex justify-content-center">
                        <h2>Contract not found...</h2>

                    </div>

                </div>
            </Styles>
        )

    }
    return (

        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div class="d-sm-flex align-items-center justify-content-between">
                <div>
                    <h1>Carrier Packet</h1>
                </div>
            </div>

            {isLoading ? placeHolder() :
                <div className='card step-progress'>
                    <StepZilla
                        steps={steps} showSteps={false} nextButtonCls='btn btn-prev btn-primary btn-lg float-right next-btn' backButtonCls="btn btn-prev btn-primary btn-lg back-btn"
                    />
                </div>
            }





        </div>

    )
}
