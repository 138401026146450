import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip,
    Modal,
    Spinner,
    Button
} from "react-bootstrap";
import { authFetch } from '../../../auth';
import { v4 as uuidv4 } from "uuid";
import { Alert } from "react-bootstrap";
import UseAlert from '../../Alerts/UseAlerts';


const autoSendToolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        If this option is selected, we will send the rate confirmation to the carrier's email on file via our emailing system.
    </Tooltip>
);

const hideToolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        If this option is selected, the shippers & receivers details will be hidden until the carrier signs the rate confirmation.
    </Tooltip>
);

const LoadsConfirmRC = (props) => {



    const initialState = () => {
        return (props)
    }
    const history = useHistory();

    const {
        alertState,
        showSuccessAlert,
        showFailAlert,
        showNoChangeAlert
    } = UseAlert();

    const [state, setState] = useState(initialState);
    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showModal, setShow] = useState(false);
    const [hideDetails, setHideDetails] = useState()
    const [checked, setChecked] = useState(false)
    const [formIsValid, setFormIsValid] = useState(true)
    const [errors, setErrors] = useState({
        carrier: '',
        trailer: '',
        equipment: '',
        gross_pay: '',
        carrier_pay: '',
    });


    const handleChecked = () => {
        setChecked(!checked);
    };

    const handleClose = () => {
        setShow(false)
        setErrorMsg(null)
    };
    const handleShow = () => {
        setShow(true);
        setState(props);
    }

    const handleHideDetails = (e) => {
        setHideDetails(!hideDetails)
    }

    const currentRateConfirmation = (e) => {
        e.preventDefault();
        history.push(`/documents/rateconfirmation/${props.data.load_id}`)
    }
    const totalFees = props.data ? props.data.accessorial_fees ? props.data.accessorial_fees.reduce((acc, fee) => {
        const amount = fee && parseFloat(fee.amount); // add a null check here
        const quantity = fee && parseInt(fee.quantity); // add a null check here
        if (isNaN(amount) || isNaN(quantity)) {
            return acc;
        }
        return acc + amount * quantity;
    }, 0) : 0 : 0;

    const createRateConfirmation = (e) => {
        e.preventDefault();
        setLoading(true);
        const uniqID = uuidv4();
    
        authFetch('https://fltbd.herokuapp.com/documents/confirmation', {
            method: 'PUT',
            body: JSON.stringify({
                binding_id: uniqID,
                load_id: props.data.load_id,
                load_number: props.data.load_number,
                trailer: props.data.trailer,
                equipment: [props.data.equipment],
                oversize: props.data.oversize,
                carrier_name: props.data.carrier_name,
                cargo_value: props.data.cargo_value,
                line_haul: props.data.line_haul,
                accessorial_fees: [props.data.accessorial_fees],
                carrier_pay: ((totalFees || 0) + (parseFloat(props.data.line_haul) || 0)).toFixed(2),
                carrier_id: props.data.carrier_match,
                hide_details: hideDetails,
                auto_send: checked.toString()
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 400) {
                showFailAlert("Error");
                setTimeout(() => {
                    setLoading(false);
                }, 250);
                throw new Error('Validation failed'); // Stop executing further if there's an error
            }
            return response.json(); // Ensure that you return the response JSON if the status is not 400
        })
        .then((data) => {
            
    
            if (data) {
                const dataCopy = [...props.loads];
                const mappedData = dataCopy.map(g => (
                    g.load_id === props.data.load_id
                    ? {
                        ...g,
                        binding_id: uniqID,
                        load_id: props.data.load_id,
                        load_number: props.data.load_number,
                        trailer: props.data.trailer,
                        equipment: props.data.equipment,
                        oversize: props.data.oversize,
                        carrier_name: props.data.carrier_name,
                        cargo_value: props.data.cargo_value,
                        line_haul: props.data.line_haul,
                        accessorial_fees: props.data.accessorial_fees,
                        carrier_pay: ((totalFees || 0) + (parseFloat(props.data.line_haul) || 0)),
                        gross_pay: props.data.gross_pay,
                        actions: props.data.actions,
                        created_confirmation: true,
                        accepted: data.accepted
                    }
                    : g
                ));
                props.updateLoadList(mappedData);
                history.push(`/documents/rateconfirmation/${props.data.load_id}`);
            }
        })
        .catch((error) => {
            showFailAlert("There was an error. Please try again.")
            setTimeout(() => {
                setLoading(false);
            }, 250);
            console.error('Error:', error);
        });
    }


    //Validation is not working and might not be worth setting up yet.
    const handleValidation = (props) => {
        const newErrorState = { ...errors }
        let formIsValid = true
        //console.log(props)
        if (props.data.carrier_name === "Not Assigned") {
            formIsValid = false
            newErrorState.carrier = "You must select a carrier"
        }
        if (props.data.trailer === null) {
            formIsValid = false
            newErrorState.trailer = "You must select a trailer type"
        }
        if (!formIsValid) { // if any field is invalid - then we need to update our state
            setFormIsValid(false);
            setErrors(newErrorState);
        }

        return formIsValid

    }

    useEffect(() => {
        if (props && props.data) {
            handleValidation(props)
        }
    }, [props.data])


    return (
        <>


                <Link>
                    <Button variant='azure' onClick={handleShow}>
                        Create Rate Confirmation
                    </Button>
                </Link>
            

            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}


            >

                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                {props.accepted == true ?
                    <form>
                        <Modal.Body class="modal-body">
                        {alertState.show && (
                                <Alert variant={`${alertState.type}`}>
                                    {alertState.message}
                                </Alert>
                            )}

                            <div>This rate confirmation has already been signed by the carrier.
                                Would you like to overwrite the existing rate confirmation? <br />
                                Please select your option below:<br />
                            </div>
                            <br />
                            <div></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                Go Back
                            </Button>
                            <Button variant="success" onClick={currentRateConfirmation}>
                                Current Rate Confirmation
                            </Button>
                            <Button variant="danger" onClick={createRateConfirmation} >
                                Overwrite Existing Rate Confirmation
                            </Button>
                        </Modal.Footer>
                    </form>
                    :
                    <>
                        <Modal.Body class="modal-body">


                            {alertState.show && (
                                <Alert variant={`${alertState.type}`}>
                                    {alertState.message}
                                </Alert>
                            )}
                            <div>You are about to create a rate confirmation for Load #{props.loadNumber} </div>
                            <br />


                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="sendToCarrier" checked={checked} name="autosend" onChange={handleChecked} />
                                <label class="form-check-label" for="sendToCarrier">
                                    Auto-Send To Carrier
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={autoSendToolTip}
                                    >
                                        <small> [?]</small>
                                    </OverlayTrigger>
                                </label>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                Go Back
                            </Button>
                            {loading ?
                                <>
                                    <Button variant="success" disabled>
                                        Loading...

                                        <Spinner animation="border" variant="success" size="sm" />
                                    </Button>
                                </>
                                :

                                <Button variant="success" onClick={createRateConfirmation} >
                                    Create Rate Confirmation
                                </Button>

                            }


                        </Modal.Footer>
                    </>
                }
            </Modal>


        </>
    )
}

export default LoadsConfirmRC