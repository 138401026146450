import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination
} from "react-table";
import { matchSorter } from "match-sorter";
import axios from "axios";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
    useHistory
} from "react-router-dom"
import { useAuth, authFetch, logout } from "../../auth";
import { Dropdown, Form, Select } from "react-bootstrap";
import SettingsUserItem from "./SettingsUserItem";

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
        /* NOT  NEEDED 
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 0px solid black;
        */

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  input {
      width: 50%;
      
  }
  .card {
      box-shadow: none;
      margin-top: 0px;
  }


`
// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{" "}
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Search By Carrier Name, MC Number, or DOT Number"
                style={{
                    fontSize: "1.1rem",
                    border: "0"
                }}
            />
        </span>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {
    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}



function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        usePagination,
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case

    return (
        <>
        <div className="card" style={{border: "0"}}>
        <div className="table-responsive">
          <table className="table-responsive card-table table-vcenter" {...getTableProps()}>
                <thead>

                    <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{
                                textAlign: "left"
                            }}
                        >
                            <span className="colorwhite">Search: </span>
                            <input
                                type="text"
                                className="secondarycolorbg colorwhite"
                                value={globalFilter || ""}
                                placeholder="Search By Name, Email, or Role"
                                onChange={e => setGlobalFilter(e.target.value)}
                                style={{
                                    fontSize: "1.1rem",
                                    border: "0",
                                    width: "100%"
                                }}
      />
                        </th>
                    </tr>
                    {headerGroups.map((headerGroup) => (
                        <tr style={{backgroundColor:"#f6e2b3"}} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th className="reactTable-th" {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                    {/* Render the columns filter UI */}

                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td className="reactTable-td" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            </div>
            <div class="row m-3">
                    <div class="col">
                        <div className="pagination">
                            <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'< Previous'}
                            </button>
                            <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'Next >'}
                            </button>
                        </div>
                    </div>
                    <div class="col d-none d-sm-block colorwhite">
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <span>
                            | Go to page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                    </div>
                    <div class="col d-none d-sm-block">
                        <select
                            class="form-select"

                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>


                </div>
            <br />
        </div>
        </>
    );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

function SettingsUsersTable(props) {
    const columns = React.useMemo(
        () => [

                    {
                        Header: "Name",
                        accessor: "first_name"
                    },
                    {
                        Header: "Email",
                        accessor: "email",
                    },
                    {
                        Header: "Roles",
                        accessor: "roles",
                    },
                    {
                        Header: "Edit",
                        accessor: "user_id",
                        Cell: e => <Link to={`/settings/manage-user/${e.value}`}><button className="btn align-text-top btn-primary"> View/Edit </button></Link>
                    },
                   
        ],
        []
    );

    //const data = React.useMemo(() => makeData(500), []);
    const history = useHistory();
    const [state, setState] = useState()
    const [companyID, setCompanyID] = useState()

    const [data, setData] = useState([]);
    
      

    useEffect(() => {
        if(props  && props.employees) {
            setData(props.employees)
        }
    }, [props.employees])
     
    
  
    
    

    return (
        
        <Styles>
            <Table columns={columns} data={data} />
        </Styles>
    );
}

export default SettingsUsersTable;
