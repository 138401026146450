import React, { useEffect, useState } from "react";
import Select from 'react-select'
import DatePicker from "react-datepicker"

const CustomerInfo = (props) => {
    //below is required to so you don't get properties undefined for props.customers 
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);

    function customerChange(e) {
        console.log(e)
    
        // Check if billing_street_address is null or undefined, set to "123 St" if so
        if (e.billing_street_address == null) {
            e = {
                ...e, customer_billing_street_address: e.customer_street_address,
                customer_billing_city: e.customer_city,
                customer_billing_state: e.customer_state,
                customer_billing_zipcode: e.customer_zipcode,
                customer_billing_phone_number: e.phone_number,
                customer_billing_email: e.email
            };
        }
    
        // Update the state with possibly modified event e
        setState(prevState => {
            return { ...prevState, ...e };
        });
    
        // Call the parent component's handle function with possibly modified event e
        props.handleCustomerChange(e);
    }
    function handleTerms(e) {
        setState({ ...state, terms: e.value })

        props.updateCustomer({ ...state, terms: e.value })
    }
    function handleDateChange(date, name) {
        console.log(date, name)
        props.handleDateChange(date, name)
    }
    function handleChange(e) {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        props.updateCustomer({ ...state, [name]: value })

    }

    const statusOptions = [
        { value: 'Net 60', label: 'Net 60' },
        { value: 'Net 45', label: 'Net 45' },
        { value: 'Net 30', label: 'Net 30' },
        { value: 'Net 15', label: 'Net 15' },
        { value: 'Net 10', label: 'Net 10' },
        { value: 'Due On Receipt', label: 'Due On Receipt' },
    ]

    useEffect(() => {
        if (props && props.customers) {
            const options = props.customers.map(d => ({
                "id": d.id,
                "customer_name": d.customer_name,
                "customer_id": d.customer_id,
                "customer_street_address": d.customer_street_address,
                "customer_city": d.customer_city,
                "customer_state": d.customer_state,
                "customer_zipcode": d.customer_zipcode,
                "phone_number": d.phone_number,
                "email": d.email,
                "customer_billing_street_address": d.billing_street_address,
                "customer_billing_city": d.billing_city,
                "customer_billing_state": d.billing_state,
                "customer_billing_zipcode": d.billing_zipcode,
                "customer_billing_email": d.billing_email,
                "customer_billing_phone_number": d.billing_phone_number,
                "terms": d.terms,
            }))
            setState({ customers: options })
        }

    }, [props.customers])

    useEffect(() => {
        if (props && props.customer_name && props.customers) {
            const customer = props.customers.filter(customer => customer.customer_name == props.customer_name)

            // Check if billing_street_address is null or undefined, set to customer's details if so
            if (customer[0].billing_street_address == null) {
                customer[0] = { 
                    ...customer[0], 
                    customer_billing_street_address: customer[0].customer_street_address,
                    customer_billing_city: customer[0].customer_city,
                    customer_billing_state: customer[0].customer_state,
                    customer_billing_zipcode: customer[0].customer_zipcode,
                    customer_billing_phone_number: customer[0].phone_number,
                    customer_billing_email: customer[0].email  
                };
            }
            else {
                customer[0] = {
                    ...customer[0], customer_billing_street_address: customer[0].billing_street_address,
                    customer_billing_city: customer[0].billing_city,
                    customer_billing_state: customer[0].billing_state,
                    customer_billing_zipcode: customer[0].billing_zipcode,
                    customer_billing_phone_number: customer[0].phone_number,
                    customer_billing_email: customer[0].email
                };
            }
            

            setState(prevState => {
                return { ...prevState, ...customer[0] };
            });
            props.handleCustomerChange(customer[0]);

        }

    }, [props.customer_name, props.customers])
    const updateInvoiceNote = (e) => {
        setState({ ...state, invoice_note: e.target.value });
        props.handleChange(e)
        
    }


    return (
        <>
            <div className="row">
                <div class="col-6">
                    <div class="mb-3">
                        <label class="form-label colorwheat">Customer Name</label>
                        <Select
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.customer_name}

                            options={state.customers}
                            placeholder={props.customer_name}
                            name="customer_name" onChange={(e) => customerChange(e)} />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Invoice Note</label>
                        <textarea className="form-control" name="invoice_note" type="text" placeholder="This will show up on the invoice" onChange={(e) => updateInvoiceNote(e)} />

                    </div>
                    <div class="mb-3">
                        <label class="form-label colorwheat">Terms</label>
                        <Select options={statusOptions}
                            placeholder={props.customer ? props.customer.terms : ""}

                            value={state.terms}
                            name="customer_name"
                            onChange={(e) => handleTerms(e)}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label colorwheat">Invoice Date</label>
                                <DatePicker
                                    selected={props.date}
                                    name="date"
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy"
                                    onChange={(date) => handleDateChange(date, "date")}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label colorwheat">Due Date</label>
                                <DatePicker
                                    selected={props.due_date}
                                    name="due_date"
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy "
                                    onChange={(date) => handleDateChange(date, "due_date")}
                                />
                            </div>
                        </div>

                    </div>
                    
                </div>
                <div className="col-6">
    <label className="form-label colorwheat">Billing Details</label>

    <div className="input-group mb-3">
        <span className="input-group-text">Address</span>
        <input type="text" className="form-control" placeholder="Address" name="customer_billing_street_address" value={props.customer?.customer_billing_street_address ?? ''} onChange={handleChange} />
    </div>

    <div className="input-group mb-3">
        <span className="input-group-text">City</span>
        <input type="text" className="form-control" placeholder="City" name ="customer_billing_city" value={props.customer?.customer_billing_city ?? ''} onChange={handleChange} />
    </div>

    <div className="input-group mb-3">
        <span className="input-group-text">State</span>
        <input type="text" className="form-control" placeholder="State" name="customer_billing_state" value={props.customer?.customer_billing_state ?? ''} onChange={handleChange}/>
    </div>

    <div className="input-group mb-3">
        <span className="input-group-text">Zipcode</span>
        <input type="text" className="form-control" placeholder="Zipcode" name="customer_billing_zipcode" value={props.customer?.customer_billing_zipcode ?? ''} onChange={handleChange}/>
    </div>

    <label className="form-label mt-3 colorwheat">Billing Email</label>

    <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Email" name="customer_billing_email" value={props.customer?.customer_billing_email ?? ''} onChange={handleChange}/>
    </div>

</div>
            </div>

        </>
    )
}

export default CustomerInfo