import React, { useState, useEffect } from 'react';


const Subscription = (props) => (


  <section>
    <div class="">
      <div class="table-responsive">
        <table class="table table-vcenter table-nowrap card-table">
          <thead>
          <tr>

<td colspan="2">

  <div class="d-flex flex-column flex-md-row">

    <div class="w-100 w-md-50">

      <h2>Perfect for the new or small brokerage</h2>

      <div class="colorpalevioletred text-wrap">

        RateConfirmation.com is a Lightweight Freight Broker Transportation Management System.

      </div>

    </div>

    <div class="w-100 w-md-50 text-center">

      <div class="text-uppercase text-secondary font-weight-medium">Risk Free 15 day Trial</div>

      <div class="display-6 fw-bold my-3">$40/month</div>

      <form action="https://fltbd.herokuapp.com/api/stripe-create-checkout" method="POST">

        {/* Add a hidden field with the lookup_key of your Price */}

        <input type="hidden" name="lookup_key" value="sub" />

        <input type="hidden" name="stripe_customer_id" value={props.stripeCustomerID} />

        <input type="hidden" name="customer_email" value={props.email} />

        <button className="btn btn-primary mt-2 w-50" id="checkout-and-portal-button" type="submit">

          Start Trial

        </button>

      </form>

    </div>

  </div>

</td>

</tr>
          </thead>
          <tbody>
            <tr class="">
              <th colspan="4" class="subheader" style={{ backgroundColor: "wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Create and Send</th>
            </tr>
            <tr>
              <td>Rate Confirmations</td>
              <td class="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon text-green" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
              </td>
            </tr>
            <tr>
              <td>Contracts</td>
              <td class="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon text-green" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
              </td>


            </tr>

            <tr>
              <td>Invoices</td>
              <td class="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon text-green" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
              </td>


            </tr>
            <tr class="bg-light">
              <th colspan="4" class="subheader" style={{ backgroundColor: "wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Manage and Maintain</th>
            </tr>
            <tr>
              <td>Customers</td>
              <td class="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon text-green" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
              </td>


            </tr>
            <tr>
              <td>Leads</td>
              <td class="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon text-green" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
              </td>


            </tr>
            <tr>
              <td>Carriers</td>
              <td class="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon text-green" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
              </td>

            </tr>
            <tr class="bg-light">
              <th colspan="4" class="subheader" style={{ backgroundColor: "wheat", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Upcoming</th>
            </tr>
            <tr>
              <td>Integrations</td>
              <td class="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon text-green" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
              </td>


            </tr>

          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td>
                <form action="https://fltbd.herokuapp.com/api/stripe-create-checkout" method="POST">
                  {/* Add a hidden field with the lookup_key of your Price */}
                  <input type="hidden" name="lookup_key" value="sub" />
                  <input type="hidden" name="stripe_customer_id" value={props.stripeCustomerID} />
                  <input type="hidden" name="customer_email" value={props.email} />
                  <button className="btn btn-primary mt-2 w-100" id="checkout-and-portal-button" type="submit">
                    Start Trial
                  </button>
                </form>

              </td>


            </tr>
          </tfoot>
        </table>
      </div>
    </div>
   
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <div className="card">
      <div class="card-header">
        <h3 class="card-title">Thank you for using our product!</h3>
      </div>
      <div className="card-body">



      </div>
    </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);
const ManageSubScription = (props) => {
  return (
    <>
    <div className='card-body'>
      <h4 className="colorwhite">Your subscription status: &nbsp;

        {props.stripeStatus === "Inactive" || props.stripeStatus === "Paused" || props.stripeStatus === "Canceled" ?
          <span className="text-danger">Inactive</span>
          :
          props.stripeStatus === "Active" ?
            <span className="text-success">Active</span>
            :
            props.stripeStatus === "Trial" ?
              <span className="text-info">Trial</span>
              :
              null
        }

      </h4>
      {props.stripeStatus === "Canceled" ?
        <form action="https://fltbd.herokuapp.com/api/stripe-create-checkout" method="POST">
          <input
            type="hidden"
            id="customer-id"
            name="stripe_customer_id"
            value={props.stripeCustomerID}
          />
          <input
            type="hidden"
            id="customer-id"
            name="canceled"
            value={true}
          />
          <button className="btn btn-info btn-lg mt-2" id="checkout-and-portal-button" type="submit">
            Restart Subscription
          </button>
        </form>
        :
        <>
        <div class="colorpalevioletred text-wrap">

Thank you for your support, it means a lot. 

</div>
        
        <form action="https://fltbd.herokuapp.com/api/stripe-customer-portal" method="POST">
          <input
            type="hidden"
            id="customer-id"
            name="stripe_customer_id"
            value={props.stripeCustomerID}
          />
          <button className="btn btn-primary btn-lg mt-2" id="checkout-and-portal-button" type="submit">
            Manage Subscription
          </button>
        </form>
        
        </>
      }
      </div>
    </>
  )
}
export default function SettingsSubscription(props) {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const [stripeSubID, setStripeSubID] = useState()
  const [stripeCustomerID, setStripeCustomerID] = useState()
  const [stripeStatus, setStripeStatus] = useState()
  const [email, setEmail] = useState()


  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    if (props && props.stripe && props.email) {
      setStripeSubID(props.stripe[0].stripe_subscription_id)
      setStripeCustomerID(props.stripe[0].stripe_customer_id)
      setStripeStatus(props.stripe[0].status)
      setEmail(props.email)
    }
  }, [props.stripe], [props.email])


  if (!success && message === '') {
    return (
      <>
        <div className="card">
          <div class="card-header">
            <h3 class="card-title">Subscription</h3>
          </div>
          <div className="">

            {stripeStatus === null ?
              <Subscription email={email} stripeCustomerID={stripeCustomerID} /> :
              null
            }
            {stripeStatus !== null ?
              <ManageSubScription stripeStatus={stripeStatus} stripeSubID={stripeSubID} stripeCustomerID={stripeCustomerID} />
              :
              null
            }

          </div>
        </div>
      </>
    )
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }

}

