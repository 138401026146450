import React, { useState } from 'react';
import { authFetch } from '../../auth';
import ContactForm from '../Resources/ContactForm';
import ContactList from '../Resources/ContactList';

function CustomersContact(props) {
    // State to control the visibility of the contact form
    const [showForm, setShowForm] = useState(false);

    // Function to toggle the contact form visibility
    const toggleForm = (e) => {
        e.preventDefault(); // Prevent the default anchor behavior
        setShowForm(!showForm); // Toggle the visibility state
    };
    const toggleShow = () => {
        setShowForm(!showForm); // Toggle the visibility state
    };


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Contacts</h3>
                    <div className="edit-button">
                        {/* Update here to use onClick to toggle the form display */}
                        <a id="edit" href="#" onClick={toggleForm}>
                            {showForm ? '[Cancel]' : '[Add Contact]'}
                        </a>
                    </div>
                </div>
                <div className="card-body">
                    {/* Conditionally render the ContactForm */}
                    {showForm && <ContactForm
                    toggleShow={toggleShow}
                    addContact={props.addContact} 
                    updateCustomer={props.updateCustomer}
                    type={'customer'} type_id={props.type_id} />}
                 
                    {/* Always show the list of contacts */}
                    {props.contacts && <ContactList 
                                        type={'customer'}
                                        showForm={showForm}
                                        contacts={props.contacts} removeContact={props.removeContact}  />}
                    
                </div>

            </div>
        </>
    );
}

export default CustomersContact;