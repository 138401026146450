import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Select from 'react-select'
import axios from 'axios'
import { authFetch } from '../../auth';

const LoadsCustomer = (props) => {
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);
    const [refNumber, setRefNumber] = useState("false")
    const [timer, setTimer] = useState(null)
    useEffect(() => {

    }, [])
    const [focus, setFocus] = useState(false)

    const history = useHistory();

    const addRefNumber = () => {
        setRefNumber(!refNumber);
    }

    function customerChange(e) {
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                customer_name: e.label
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentLoadDetails({customer_name: e.label})
    }
   
      useEffect(() => {
        if (props && props.customers) {        
        const options = props.customers.map(d => ({
          "value": d.id,
          "label": d.customer_name,
          "customer_id": d.customer_id
        }))
        setState({customers: options})
        }
    }, [props.customers])

    function changeRefNumber(e) {
        clearTimeout(timer)
      
        props.parentLoadDetails({ref_number: e.target.value})
        
    }
    function handleFocus(e) {
        setFocus(false)
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                ref_number: e.target.value
            }),
            headers: { 'Content-Type': 'application/json' },
        })

    }
    const dropDownStyle = {
        control: (base) => ({
          ...base,
          height: 50,
          minHeight: 50,
          borderRadius: "5px"
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isSelected 
                  ? '#EEE'
                  : isFocused 
                      ? '#EEE'
                      : '#FFF',
            color: 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',
          };
        },
        placeholder: (styles) => ({
          ...styles,
          color: "black",
        }),
      };
   
    
   

    return (
       
        
                <div class="card">
                    <div class="card-body">
                        <div style={{display:"flex"}}>
                        <h3 class="card-title">Customer</h3>
                        <div className="edit-button">
                         
                            <input
                                onBlur={(e) => handleFocus(e)}
                                onFocus={() => setFocus(true)} 
                                className="form-control" 
                                name="ref_number" type="text" 
                                value={props.ref_number} 
                                placeholder="Ref #(Not Required)" 
                                onChange={(e) => changeRefNumber(e)} 
                                disabled={props.archived}
                            />
                           
                        </div>
                        </div> 
                        <div class="mb-3">
                            <label class="form-label colorwhite">Customer Name</label>
       
                            <Select styles={dropDownStyle} options={state.customers} placeholder={props.customer_name} name="customer_name" onChange={(e) => customerChange(e)}  isDisabled={props.archived} />   
                        </div>

                    </div>
                </div>

        

        
    )


}

export default LoadsCustomer