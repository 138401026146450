import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Select from 'react-select'
import axios from 'axios'
import { useAuth, authFetch } from "../../auth";


const LoadsCarrierDetails = (props) => {
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);
    const [type, setType] = useState("carriers")
    const [carrierProblem, setCarrierProblem] = useState()
    const [companyDriver, setCompanyDriver] = useState()

    const history = useHistory();
    function onChange(e) {
        setState({
            ...state,
            carrier_mc: e.carrier_mc,
            carrier_dot: e.carrier_dot,
            carrier_match: e.value,
            do_not_load: e.do_not_load,
        })
        setCompanyDriver(false)
        if (e.do_not_load) {
            setCarrierProblem("Carrier is on do not load list.")
        }
        if (!e.do_not_load) {
            setCarrierProblem("")
        }
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.loadID}`, {
            method: 'PATCH',
            body: JSON.stringify({
                carrier_name: e.label,
                carrier_match: e.value,
                company_driver: false
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        //props.dbCheck(e.carrier_mc)
        props.parentCarrierDetails({carrier_name: e.label,
                                    carrier_dot: e.carrier_dot,
                                    carrier_mc: e.carrier_mc,
                                    carrier_match: e.value,
                                    do_not_load: e.do_not_load,
                                })
        


    }
    function driverChange(e) {
        setState({
            ...state,
            carrier_mc: '',
            carrier_dot: '',
            carrier_match: '',
            
        })
        setCompanyDriver(true)
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                carrier_name: e.first_name,
                company_driver: true,
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentLoadDetails({carrier_name: e.first_name})
        props.parentLoadDetails({company_driver: true})


    }
    const dropDownStyle = {
        control: (base) => ({
          ...base,
          height: 50,
          minHeight: 50,
          borderRadius: "5px"
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isSelected 
                  ? '#EEE'
                  : isFocused 
                      ? '#EEE'
                      : null,
            color: 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',
          };
        },
        placeholder: (styles) => ({
          ...styles,
          color: "black",
        }),
      };
    
    useEffect(() => {
        if (props && props.carriers) {
        setCompanyDriver(props.company_driver)
        
        const options = props.carriers.map(d => ({
            "value": d.id,
            "label": d.carrier_name,
            "carrier_mc": d.carrier_mc,
            "carrier_dot": d.carrier_dot,
            "carrier_address": d.physical_address,
            "do_not_load": d.do_not_load, 
        }))

        const sortedOptions = options.sort((a, b) => a.label > b.label ? 1 : -1)
        setState({carriers: sortedOptions})
        }
    }, [props.carriers])
    if (props.type === "both") {
        return (

            <div class="card">
                {type === "carriers" ? 
                    <div class="card-body">
                        <h3 class="card-title">Carrier Details</h3>

                        <div class="mb-3">
                            <label class="form-label colorwhite">Select Carrier</label>
                            <span class="text-danger">{carrierProblem}</span>
                            <Select 
                            options={state.carriers}
                            placeholder={props.carrier_name} name="carrier_name" onChange={(e) => onChange(e)} />
                        </div>

                        <div class="row">
                            <div class="col mb-3 colorwhite">
                                Carrier MC: {props.carrier_mc}
                            </div>
                            <div class="col mb-3 colorwhite">
                                Carrier DOT: {props.carrier_dot}
                            </div>
                        </div>
                        <h3 class="card-title">Insurance</h3>
                        <p>GL</p>
                        <p>Cargo</p>
                        <button class="btn btn-primary float-right" onClick={() =>setType("drivers")}>Switch To Company Driver</button>
                    </div>
                    :  
                    <div class="card-body">
                        <h3 class="card-title">Driver Details</h3>

                        <div class="mb-3">
                            <label class="form-label">Select Driver</label>
                            <Select options={props.drivers} 
                                    getOptionLabel={option => option.first_name}
                                    getOptionValue={option => option.id}  name="carrier_name" onChange={(e) => driverChange(e)} />
                        </div>

                       
                        <button class="btn btn-primary float-right" onClick={() =>setType("carriers")}>Switch To Carriers</button>
                    </div>
                }
            </div>

        )
    }
    if (props.type === "carrier") {
        return (

            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">Driver Details</h3>
                    

                    <div class="mb-3">
                        <label class="form-label">Carrier Name</label>
                        <Select options={state.carriers} placeholder={props.carrier_name} name="carrier_name" onChange={(e) => onChange(e)} />
                    </div>

                    <div class="row">
                        <div class="col mb-3">
                            Carrier MC: {props.carrier_mc}
                        </div>
                        <div class="col mb-3">
                            Carrier DOT: {props.carrier_dot}
                        </div>
                    </div>
                    <h3 class="card-title">Insurance</h3>
                    <p>GL</p>
                    <p>Cargo</p>

                </div>

            </div>

        )
    }
    return (
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Carrier Details</h3>
                {props.doubleBroker ? 
                <span class="text-danger">On Double Broker List</span>
                : null
                }
                <span class="text-danger">{carrierProblem}</span>
                <div class="mb-3">
                    <label class="form-label colorwhite">Carrier Name</label>
                    <Select styles={dropDownStyle} options={state.carriers} placeholder={props.carrier_name} name="carrier_name" onChange={(e) => onChange(e)} isDisabled={props.archived} />
                </div>

                <div class="row">
                    <div class="col mb-3 colorwhite">
                        Carrier MC: {props.carrier_mc}
                    </div>
                    <div class="col mb-3 colorwhite">
                        Carrier DOT: {props.carrier_dot}
                    </div>
                </div>
                {3 == 2 ?
                <>
                <h3 class="card-title">Insurance</h3>
                <p>GL</p>
                <p>Cargo</p>
                </>
                :
                null}
            </div>
        </div>
    )


}

export default LoadsCarrierDetails