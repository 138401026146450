import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { authFetch } from "../../auth";
import { Alert } from "react-bootstrap";
import UseAlert from '../Alerts/UseAlerts';

const googleMapsApiKey = 'AIzaSyAb9aeuBsPTn_G5m9CbssQUj9GJwSQZFyQ'; // Replace with your Google Maps API key

export default function LocationsField(props) {
    const [newLocation, setNewLocation] = useState({
        location_name: '',
        branch_number: '',
        contact_name: '',
        phone_number: '',
        street_address: '',
        city: '',
        state: '',
        zipcode: ''
    });
    const [collapse, setCollapse] = useState(true);
    const { alertState, showSuccessAlert, showFailAlert } = UseAlert();

    useEffect(() => {
        const initializeGooglePlaces = () => {
            const autocomplete = new window.google.maps.places.Autocomplete(
                document.getElementById('inputAddress'),
                { types: ['address'] }
            );
    
            // Add an event listener to update the state
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const addressComponents = place.address_components;
    
                // Extract address components
                let streetAddress = '';
    
                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes('street_number')) {
                        streetAddress = component.long_name + ' ' + streetAddress;
                    } else if (types.includes('route')) {
                        streetAddress += component.long_name;
                    }
                });
    
                // Extract and set the values
                const updatedLocation = {
                    location_name: newLocation.location_name,
                    branch_number: newLocation.branch_number,
                    contact_name: newLocation.contact_name,
                    phone_number: newLocation.phone_number,
                    street_address: streetAddress,
                    city: '',
                    state: '',
                    zipcode: ''
                };
    
                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes('locality')) {
                        updatedLocation.city = component.long_name;
                    } else if (types.includes('administrative_area_level_1')) {
                        updatedLocation.state = component.short_name;
                    } else if (types.includes('postal_code')) {
                        updatedLocation.zipcode = component.long_name;
                    }
                });
    
                setNewLocation(updatedLocation);
            });
        };
    
        // Load Google Maps API script
        const loadGoogleMapsScript = () => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAb9aeuBsPTn_G5m9CbssQUj9GJwSQZFyQ&libraries=places`;
            script.async = true;
            script.onload = initializeGooglePlaces;
            document.body.appendChild(script);
        };
    
        if (!window.google) {
            loadGoogleMapsScript();
        } else {
            initializeGooglePlaces();
        }
    }, [newLocation]);

    const handleChange = e => {
        setNewLocation({ ...newLocation, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const uniqueID = uuidv4();
        const newLocElement = {
            location_id: uniqueID,
            ...newLocation,
        };

        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/api/locations`, {
                method: 'PUT',
                body: JSON.stringify(newLocElement),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Authorization error: JWT token not found in headers');
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                const json = await response.json();
                if (json.status === 200) {
                    showSuccessAlert("Success: Location Created Successfully.")
                    props.parentNewLocation(newLocElement);
                    setNewLocation({
                        location_name: '',
                        branch_number: '',
                        contact_name: '',
                        phone_number: '',
                        street_address: '',
                        city: '',
                        state: '',
                        zipcode: ''
                    });
                } else {
                    showFailAlert("Error: An Error Occured.");
                }
            }
        } catch (error) {
            showFailAlert(`Error: ${error.message}`);
        }
    }

    return (
        <div className="card">
            <div className="card-header" data-toggle="collapse" data-target="#addLocation" onClick={() => setCollapse(!collapse)} style={{ cursor: "pointer" }}>
                <h3 className="card-title">Add New Location</h3>
                <div className="edit-button">
                    {collapse ? 
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="white" fill="white" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="18" y1="13" x2="12" y2="19" />
                            <line x1="6" y1="13" x2="12" y2="19" />
                        </svg> : 
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="white" fill="white" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="18" y1="11" x2="12" y2="5" />
                            <line x1="6" y1="11" x2="12" y2="5" />
                        </svg> 
                    }
                </div>
            </div>
            <div className="card-body collapse" id="addLocation">
                {alertState.show && (
                    <Alert variant={`${alertState.type}`}>
                        {alertState.message}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="form-label" htmlFor="inputLocationName">Location Name</label>
                        <input type="text" className="form-control" placeholder="Enter Company Name" value={newLocation.location_name} name="location_name" onChange={handleChange} required />
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="inputContactName">Contact Name</label>
                            <input type="text" className="form-control" id="inputContactName" placeholder="Contact Name" value={newLocation.contact_name} name="contact_name" onChange={handleChange} required />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="inputPhoneNumber">Phone Number</label>
                            <input type="tel" className="form-control" id="inputPhoneNumber" placeholder="555-555-5555" value={newLocation.phone_number} name="phone_number" onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="inputAddress">Address</label>
                        <input type="text" className="form-control" id="inputAddress" placeholder="Enter Street Address, example: 1234 Main St" value={newLocation.street_address} name="street_address" onChange={handleChange} required />
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="inputCity">City</label>
                            <input type="text" className="form-control" id="inputCity" placeholder="Enter City" value={newLocation.city} name="city" onChange={handleChange} required />
                        </div>
                        <div className="form-group col-md-4">
                            <label className="form-label" htmlFor="inputState">State</label>
                            <select className="form-control" value={newLocation.state} name="state" onChange={handleChange} required>
                                <option value="">Choose...</option>
                                <optgroup label="U.S. States/Territories">
                                    <option value="AK">AK - Alaska</option>
                                    <option value="AL">AL - Alabama</option>
                                    <option value="AR">AR - Arkansas</option>
                                    <option value="AZ">AZ - Arizona</option>
                                    <option value="CA">CA - California</option>
                                    <option value="CO">CO - Colorado</option>
                                    <option value="CT">CT - Connecticut</option>
                                    <option value="DC">DC - District of Columbia</option>
                                    <option value="DE">DE - Delaware</option>
                                    <option value="FL">FL - Florida</option>
                                    <option value="GA">GA - Georgia</option>
                                    <option value="HI">HI - Hawaii</option>
                                    <option value="IA">IA - Iowa</option>
                                    <option value="ID">ID - Idaho</option>
                                    <option value="IL">IL - Illinois</option>
                                    <option value="IN">IN - Indiana</option>
                                    <option value="KS">KS - Kansas</option>
                                    <option value="KY">KY - Kentucky</option>
                                    <option value="LA">LA - Louisiana</option>
                                    <option value="MA">MA - Massachusetts</option>
                                    <option value="MD">MD - Maryland</option>
                                    <option value="ME">ME - Maine</option>
                                    <option value="MI">MI - Michigan</option>
                                    <option value="MN">MN - Minnesota</option>
                                    <option value="MO">MO - Missouri</option>
                                    <option value="MS">MS - Mississippi</option>
                                    <option value="MT">MT - Montana</option>
                                    <option value="NC">NC - North Carolina</option>
                                    <option value="ND">ND - North Dakota</option>
                                    <option value="NE">NE - Nebraska</option>
                                    <option value="NH">NH - New Hampshire</option>
                                    <option value="NJ">NJ - New Jersey</option>
                                    <option value="NM">NM - New Mexico</option>
                                    <option value="NV">NV - Nevada</option>
                                    <option value="NY">NY - New York</option>
                                    <option value="OH">OH - Ohio</option>
                                    <option value="OK">OK - Oklahoma</option>
                                    <option value="OR">OR - Oregon</option>
                                    <option value="PA">PA - Pennsylvania</option>
                                    <option value="PR">PR - Puerto Rico</option>
                                    <option value="RI">RI - Rhode Island</option>
                                    <option value="SC">SC - South Carolina</option>
                                    <option value="SD">SD - South Dakota</option>
                                    <option value="TN">TN - Tennessee</option>
                                    <option value="TX">TX - Texas</option>
                                    <option value="UT">UT - Utah</option>
                                    <option value="VA">VA - Virginia</option>
                                    <option value="VT">VT - Vermont</option>
                                    <option value="WA">WA - Washington</option>
                                    <option value="WI">WI - Wisconsin</option>
                                    <option value="WV">WV - West Virginia</option>
                                    <option value="WY">WY - Wyoming</option>
                                </optgroup>
                                <optgroup label="Canadian Provinces">
                                    <option value="AB">AB - Alberta</option>
                                    <option value="BC">BC - British Columbia</option>
                                    <option value="MB">MB - Manitoba</option>
                                    <option value="NB">NB - New Brunswick</option>
                                    <option value="NF">NF - Newfoundland</option>
                                    <option value="NT">NT - Northwest Territories</option>
                                    <option value="NS">NS - Nova Scotia</option>
                                    <option value="NU">NU - Nunavut</option>
                                    <option value="ON">ON - Ontario</option>
                                    <option value="PE">PE - Prince Edward Island</option>
                                    <option value="QC">QC - Quebec</option>
                                    <option value="SK">SK - Saskatchewan</option>
                                    <option value="YT">YT - Yukon Territory</option>

                                </optgroup>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="inputZip">ZIP Code</label>
                            <input type="text" className="form-control" id="inputZip" placeholder="Enter ZIP Code" value={newLocation.zipcode} name="zipcode" onChange={handleChange} />
                        </div>
                    </div>
                    <button className="btn btn-success float-right">Create Location</button>
                </form>
            </div>
        </div>
    );
}