import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';
import { Alert } from "react-bootstrap";
import UseAlert from '../Alerts/UseAlerts';

export default function CarriersItemBillingDetails(props) {

    const [state, setState] = useState('')
    const {
        alertState,
        showSuccessAlert,
        showFailAlert,
        showNoChangeAlert
    } = UseAlert();

    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }

    const carrierBillingAddressChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value

        })
        props.parentCallbackBillingAddress(e.target.value)
    }
    const carrierFederalIDChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value

        })
        props.parentCallbackFederalID(e.target.value)
    }
    const carrierFactorChange = e => {

        setState({
            ...state,
            [e.target.name]: e.target.value

        })
        props.parentCallbackFactor(e.target.value)
    }
    const carrierFactoringCompanyChange = e => {

        setState({
            ...state,
            [e.target.name]: e.target.value

        })
        props.parentCallbackFactoringCompany(e.target.value)
    }

    useEffect(() => {
        if (props) {
            setState(props)
        }
    }, [props])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await authFetch(`https://fltbd.herokuapp.com/api/carriers/${props.carrier_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                billing_address: state.billing_address,
                federal_id: state.federal_id,
                factor: state.factor,
                factoring_company: state.factoring_company
            }),
            headers: { 'Content-Type': 'application/json' },
        });

        // Check if the response indicates no changes made
        if (response.status === 304 || response.status === 405) {
            setEdit(!edit);
            // Optionally, add an alert to notify no changes:
            // showNoChangeAlert('No changes were made as the data was the same or update not allowed.');
            return;
        }

        // Handle non-ok responses that may indicate errors
        if (!response.ok) {
            if (response.status === 500) {
                showFailAlert('Server Error: Please try again later or contact support.');
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }

        // Assuming a successful response, parse JSON data
        const data = await response.json();
        // Handle specific data status expected in the JSON response
        if (data.status === 200) {
            showSuccessAlert('Success: Carrier Information Updated');
            setEdit(!edit);
            props.updateCarrier({
                carrier_id: props.carrier_id,
                billing_address: state.billing_address,
                federal_id: state.federal_id,
                factor: state.factor,
                factoring_company: state.factoring_company
            });
        } else {
            console.log(`Update failed! reason: ${data.reason}`);
        }
    }
    return (
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Billing Details</h3>
                <div class="edit-button">
                    <a href="#edit" id="edit" onClick={changeEdit}> [edit]</a>
                </div>
            </div>

            <div class="card-body">
                {alertState.show && (
                    <Alert variant={`${alertState.type}`}>
                        {alertState.message}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <div class="form-row">
                        <div class="col mb-3">
                            <label class="form-label" >Carrier Billing Address </label>
                            <textarea class="form-control" value={state.billing_address} name="billing_address" onChange={carrierBillingAddressChange} readOnly={edit} />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col mb-3">
                            <label class="form-label" >Federal ID</label>
                            <input type="text" class="form-control" value={state.federal_id} name="federal_id" onChange={carrierFederalIDChange} readOnly={edit} />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col mb-3">
                            <label class="form-label" >Does the carrier factor their receivables? </label>
                            <div class="form-check">
                                <input class="form-check-input" value="Yes" checked={state.factor === "Yes"} type="radio" onChange={carrierFactorChange} disabled={edit} />
                                <label class="form-check-label colorwhite">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" value="No" checked={state.factor === "No"} type="radio" onChange={carrierFactorChange} disabled={edit} />
                                <label class="form-check-label colorwhite">
                                    No
                                </label>
                            </div>
                            {state.factor == "Yes" ? <div>
                                <label class="form-label" >Factoring Company </label>
                                <input type="text" class="form-control" value={state.factoring_company} name="factoring_company" onChange={carrierFactoringCompanyChange} readOnly={edit} />
                            </div> : null}
                            {!edit ?
                                <div>
                                    <br />
                                    <button type="submit" className="btn btn-success float-right">Make Changes</button>
                                </div>
                                : null}
                        </div>
                    </div>
                </form>
            </div>

        </div>

    )

}