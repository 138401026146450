import React, { useEffect, useState } from "react";
import styled from "styled-components"
import Footer from "../Footer";
import HomeMasthead from "./HomeMasthead";
import HomeJumbotron from "./HomeJumbotron";
import fltbd_rc_mobile_phone_2 from "../../assets/img/fltbd_rc_mobile_phone_2.png"
import freightbrokerai_mobile from "../../assets/img/freightbrokerai_mobile.png"
import rateconfirmation_mobile from "../../assets/img/rateconfirmation_mobile.png"

import multi_docs from "../../assets/img/multi_docs.svg"
import del_truck from "../../assets/img/del_truck.svg"
import web_cursor from "../../assets/img/web_cursor.svg"
import web_chart from "../../assets/img/web_chart.svg"
import web_lever from "../../assets/img/web_lever.svg"
import web_stopclock from "../../assets/img/web_stopclock.svg"
import web_flash from "../../assets/img/web_flash.svg"
import web_brain from "../../assets/img/web_brain.svg"
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Jump from 'react-reveal/Jump';
import Rotate from 'react-reveal/Rotate';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
} from "react-router-dom";


export default function HomeContainer() {
  return (
    <>

      <section class="jumbotron text-center" style={{ paddingBottom: "2rem" }}>
        <div class="container">


          <Pulse>
            <h1 class="jumbotron-header">A TMS That <span class="maincolorheader">Delivers.</span></h1>
          </Pulse>
          <p>

            <Link to="/signup"><button class="btn btn-vk btn-lg m-2">Get Started For Free</button></Link>
          </p>
        </div>
      </section>
      
      
      <div style={{ backgroundColor: "none" }}>
        <div class="build-loads-container">
          <div class="row">
            <div class="col-md-7">
              <Fade left>
                <h2 className="colorwheat">Simply Simple.</h2>
                <p className="lead font-color-white">Eliminating unnecessary features or slop that is prevalent in most TMS's in the market.</p>
              </Fade>
              <Fade left>
                <h2 className="colorwheat">Build Loads. Build Success.</h2>
                <p className="lead font-color-white">Easily create rate confirmations and streamline your operations with minimal clicks.</p>
              </Fade>
              <Fade left>
                <h2 className="colorwheat">Your Contract. Your Conditions.</h2>
                <p className="lead font-color-white">Customize your contract and setting your own terms and conditions.</p>
              </Fade>
              <Fade left>
                <h2 className="colorwheat">Send Electonically. </h2>
                <p className="lead font-color-white">Send your rate confirmations and contracts via email directly from the site.</p>
              </Fade>
              <Fade left>
                <div className="card" style={{ marginTop: "0rem" }}>
                  <div className="card-body colorwhite" style={{ padding: "1.5rem" }}>
                    You have received a rate confirmation from: <br /> <span className="colorwheat"><b>Jordan Brokers Inc.</b></span>
                    <div className="d-flex justify-content-center" style={{ padding: "1.5rem", textAlign: "center" }}>
                      <a href="/rateconfirmation/demo" target="_blank">
                        <button className="btn btn-primary">View Rate Confirmation</button>
                      </a>
                    </div>
                    <div className="d-none d-sm-block" style={{ borderTop: "1px solid #EAEAEC", marginTop: "15px" }}>

                      <div style={{ paddingTop: "25px" }}>
                        <p className="">If you’re having trouble with the button above, copy and paste the URL below into your web browser.</p>
                        <Link className="link-fix" to="/rateconfirmation/demo"> https://www.rateconfirmation.com/rateconfirmation/demo</Link>
                      </div>

                    </div>
                  </div>
                </div>
              </Fade>

            </div>
            <Fade right>
              <div className="col-md-5 d-flex justify-content-center d-none d-sm-block">
                <img src={rateconfirmation_mobile} />
              </div>
            </Fade>
          </div>
        </div>


      </div>



    </>


  )

}