import { data } from 'jquery';
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { authFetch } from '../../auth';


class LoadsHeader extends Component {

    handleSubmit = e => {
        e.preventDefault();
        const uniqID = uuidv4()
        authFetch('https://fltbd.herokuapp.com/api/loads', {
            method: 'PUT',
            body: JSON.stringify({
                load_id: uniqID,
                status: 'Not Assigned',
                carrier_name: 'Not Assigned'

            }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    load_id: data.load_id,
                    status: data.status,
                    trailer: data.trailer
                })
                //this shows the json back, success and load_number in the DB
                const dataCopy = [...this.props.loads,];
                const loadData = {
                    load_number: data.load_number,
                    load_id: uniqID,
                    status: 'Not Assigned',
                    carrier_name: 'Not Assigned',
                    actions: []
                }
                
                this.props.updateLoadList([
                    ...dataCopy, loadData
                   
                ])

            }

            )

        this.props.history.push(`/loads/${uniqID}`)
    }
    render() {

        return (
            <div class="d-sm-flex align-items-center justify-content-between">
                <div>

                    <h1>Loads</h1>
                </div>
                <div class="d-md-block">

                    <form onSubmit={this.handleSubmit}>
                        <button class="btn btn-lg pd-x-15 btn-azure btn-uppercase mg-l-5" disabled={this.props.onBoard || !this.props.activeSub}>New Load</button>
                    </form>

                </div>
            </div>
        )
    }
}

export default withRouter(LoadsHeader)