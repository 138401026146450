import { useState } from 'react';

function UseAlert(timeoutDuration = 2500) {
  const [alertState, setAlertState] = useState({
    show: false,
    message: '',
    type: '' // 'success', 'fail', 'noChange'
  });
  const showAlert = (message, type) => {
    setAlertState({ show: true, message, type });
    setTimeout(() => {
      setAlertState({ show: false, message: '', type: '' });
    }, timeoutDuration);
  };

  return {
    alertState,
    showSuccessAlert: (message) => showAlert(message, 'success'),
    showFailAlert: (message) => showAlert(message, 'danger'),
    showNoChangeAlert: (message) => showAlert(message, 'noChange')
  };
}

export default UseAlert;