
import React, { useMemo, useState, useEffect } from "react";
import { useHistory, withRouter, useParams } from "react-router-dom";
import { authFetch, logout } from '../../auth'
import LocationsItemLocationDetails from './LocationsItemLocationDetails';
import LocationsItemLocationAddress from './LocationsItemLocationAddress';
import LocationDelete from "./Modals/LocationDelete";
import NotFound from "../Home/NotFound";
import LocationsInfo from "./LocationsInfo";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";
import LocationsContact from "./LocationsContact";
import LocationsNotes from "./LocationsNotes";

export default function LocationsItem(props) {

    const params = useParams();
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true);
    const [data, setData] = useState()
    const [notFound, setNotFound] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        if (props && props.locations) {
            const locationInfo = props.locations.filter(location => location.location_id == params.location_id)
            setData(locationInfo[0])
            if (locationInfo[0] === undefined) {
                setNotFound(true)
            }
            setShow(false)
            setLoading(false)
        }
    }, [props.locations])

    const handleLocationDetails = (Details) => {
        const detailKey = Object.keys(Details)[0]
        const detailValue = Object.values(Details)[0]
        setData({ ...data, [detailKey]: detailValue })
    }


    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    if (notFound) {
        return (
            <NotFound />
        )
    }
    return (
        <>
            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                <div class="d-sm-flex">
                    <h1>Manage Location</h1>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <LocationsItemLocationDetails
                            updateSingle={props.updateSingle}
                            updateLocation={props.updateLocation}
                            parentLocationDetails={handleLocationDetails}
                            {...data}

                        />
                        <LocationsItemLocationAddress
                            updateLocation={props.updateLocation}
                            updateSingle={props.updateSingle}
                            parentLocationDetails={handleLocationDetails}
                            {...data}

                        />
                        {data && <LocationsNotes
                        addNote={props.addNote}
                        removeNote={props.removeNote}
                        type_id={data.location_id}
                        notes={data.notes}
                        contacts={data.contacts}
                        />
                        }
                    </div>

                    <div className="col-md-4">
                    {data && <LocationsContact
                    addContact={props.addContact}
                    removeContact={props.removeContact} 
                    updateCustomer={props.updateCustomer}
                    contacts={data.contacts} location_id={data.location_id} />}

                        <div className="card">
                            <div className="card-header" style={{ border: "none" }}>
                                <h3 className="card-title">Map</h3>
                            </div>
                            <div className="card-body">
                                {loading ? null :
                                <iframe
                                    width="100%"
                                    style={{border:"0",}}
                                    loading="lazy"
                                    allowfullscreen
                                    referrerpolicy="no-referrer-when-downgrade"
                                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAb9aeuBsPTn_G5m9CbssQUj9GJwSQZFyQ
                                    &q=${data.street_address},${data.city}+${data.state}`}>
                                </iframe>
}
                            </div>
                        </div>
                        <LocationsInfo {...props} {...data} />
                        {props.roles == "agent" ?
                            <button className="btn btn-danger btn-uppercase mt-3 float-right" disabled>Delete Location</button>
                            :
                            <LocationDelete {...props} {...data} />
                        }
                    </div>
                </div>
            </div>
            {loading && (
            <Modal show={show} size="sm" centered>
                <LoadingModal />
            </Modal>
        )}
        </>
    )

}