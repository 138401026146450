/* global H */
import React, { useState, useEffect, useRef, useCallback } from 'react';

function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

const apiGoogleKey = process.env.REACT_APP_GOOGLE_KEY;

const GoogleMapsAutocomplete = () => {
    const originRef = useRef(null);
    const destinationRef = useRef(null);
    const [origin, setOrigin] = useState({});
    const [destination, setDestination] = useState({});
  
    useEffect(() => {
      const loadGoogleMapsScript = (callback) => {
        const existingScript = document.getElementById('googleMaps');
  
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${apiGoogleKey}&libraries=places`;
          script.id = 'googleMaps';
          document.body.appendChild(script);
  
          script.onload = () => {
            if (callback) callback();
          };
        } else {
          if (callback) callback();
        }
      };
  
      loadGoogleMapsScript(() => {
        if (window.google) {
          const originAutocomplete = new window.google.maps.places.Autocomplete(originRef.current);
          const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationRef.current);
  
          originAutocomplete.addListener('place_changed', () => handlePlaceChanged(originAutocomplete, 'origin'));
          destinationAutocomplete.addListener('place_changed', () => handlePlaceChanged(destinationAutocomplete, 'destination'));
        } else {
          console.error("Google Maps JavaScript API library not loaded.");
        }
      });
    }, []);
  
    const handlePlaceChanged = (autocomplete, type) => {
      const place = autocomplete.getPlace();
      console.log(place);
  
      const addressComponents = parseAddressComponents(place.address_components);
      const locationData = {
        ...addressComponents,
        name: place.name || ''
      };
  
      if (type === 'origin') {
        setOrigin(locationData);
      } else if (type === 'destination') {
        setDestination(locationData);
      }
    };
  
    const parseAddressComponents = (components) => {
      const address = {};
  
      components.forEach(component => {
        const types = component.types;
  
        types.forEach(type => {
          if (type === "street_number") {
            address.streetNumber = component.long_name;
          } else if (type === "route") {
            address.street = component.long_name;
          } else if (type === "locality") {
            address.city = component.long_name;
          } else if (type === "administrative_area_level_1") {
            address.state = component.short_name;
          } else if (type === "postal_code") {
            address.zipcode = component.long_name;
          } else if (type === "country") {
            address.country = component.long_name;
          }
        });
      });
  
      return address;
    };
  
    return (
      <div>
        <input ref={originRef} placeholder="Enter origin" />
        <input ref={destinationRef} placeholder="Enter destination" />
        
        <div>
          <h3>Origin</h3>
          {origin.name && <p className="colorwhite">Name: {origin.name}</p>}
          <p className="colorwhite">Street: {origin.streetNumber} {origin.street}</p>
          <p className="colorwhite">City: {origin.city}</p>
          <p className="colorwhite">State: {origin.state}</p>
          <p className="colorwhite">Zipcode: {origin.zipcode}</p>
          <p className="colorwhite">Country: {origin.country}</p>
        </div>
  
        <div>
          <h3>Destination</h3>
          {destination.name && <p className="colorwhite">Name: {destination.name}</p>}
          <p className="colorwhite">Street: {destination.streetNumber} {destination.street}</p>
          <p className="colorwhite">City: {destination.city}</p>
          <p className="colorwhite">State: {destination.state}</p>
          <p className="colorwhite">Zipcode: {destination.zipcode}</p>
          <p className="colorwhite">Country: {destination.country}</p>
        </div>
      </div>
    );
  };

const DisplayMapHere = () => {
    const apiKey = process.env.REACT_APP_HERE_KEY;
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [originSuggestions, setOriginSuggestions] = useState([]);
    const [destinationSuggestions, setDestinationSuggestions] = useState([]);
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [routingService, setRoutingService] = useState(null);

    useEffect(() => {
        if (window.H && apiKey) {
            const platform = new window.H.service.Platform({ apikey: apiKey });
            const defaultLayers = platform.createDefaultLayers();
            const mapInstance = new window.H.Map(
                mapRef.current,
                defaultLayers.vector.normal.map,
                {
                    center: { lat: 52.5, lng: 13.4 },
                    zoom: 8,
                    pixelRatio: window.devicePixelRatio || 1
                }
            );
            new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(mapInstance));
            window.H.ui.UI.createDefault(mapInstance, defaultLayers);
            setMap(mapInstance);
            setRoutingService(platform.getRoutingService());
        }
    }, [apiKey]);

    const fetchSuggestions = useCallback(async (query, type) => {
        const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${query}&apiKey=${apiKey}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data && data.items) {
                if (type === 'origin') {
                    setOriginSuggestions(data.items);
                } else {
                    setDestinationSuggestions(data.items);
                }
            }
        } catch (error) {
            console.error('Failed to fetch suggestions:', error);
        }
    }, [apiKey]);

    const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, 300), [fetchSuggestions]);

    const handleInputChange = (event, type) => {
        const value = event.target.value;
        if (type === 'origin') {
            setOrigin(value);
        } else {
            setDestination(value);
        }
        debouncedFetchSuggestions(value, type);
    };

    const selectAddress = (item, type) => {
        const location = item.address.label;
        if (type === 'origin') {
            setOrigin(location);
            setOriginSuggestions([]);
        } else {
            setDestination(location);
            setDestinationSuggestions([]);
        }
    };

    const calculateRoute = useCallback(async () => {
        if (!map || !routingService || !origin || !destination) return;

        const searchParams = async (query) => {
            const url = `https://geocode.search.hereapi.com/v1/geocode?q=${query}&apiKey=${apiKey}`;
            const response = await fetch(url);
            return response.json();
        };

        try {
            const [originData, destinationData] = await Promise.all([searchParams(origin), searchParams(destination)]);

            if (!originData.items || originData.items.length === 0 || !destinationData.items || destinationData.items.length === 0) {
                console.error('Geocode lookup failed to return results.');
                return;
            }

            const originCoords = originData.items[0].position;
            const destinationCoords = destinationData.items[0].position;

            const routingParameters = {
                'routingMode': 'fast',
                'transportMode': 'car',
                'origin': `${originCoords.lat},${originCoords.lng}`,
                'destination': `${destinationCoords.lat},${destinationCoords.lng}`,
                'return': 'polyline'
            };

            routingService.calculateRoute({ transportMode: 'car' }, routingParameters, (result) => {
                if (result.routes.length) {
                    const route = result.routes[0];
                    let linestring = new H.geo.LineString();

                    route.sections.forEach((section) => {
                        let lines = H.geo.LineString.fromFlexiblePolyline(section.polyline).getLatLngAltArray();

                        for (let i = 0; i < lines.length; i += 3) {
                            linestring.pushLatLngAlt(lines[i], lines[i + 1], lines[i + 2]);
                        }
                    });

                    const polyline = new H.map.Polyline(linestring, {
                        style: { lineWidth: 5, strokeColor: 'blue' }
                    });

                    map.getObjects().forEach(obj => map.removeObject(obj));
                    map.addObject(polyline);
                    map.getViewModel().setLookAtData({ bounds: polyline.getBoundingBox() });
                }
            }, (error) => {
                console.error('Error calculating route:', error);
            });
        } catch (error) {
            console.error('Error fetching geocode data:', error);
        }
    }, [map, routingService, origin, destination, apiKey]);

    useEffect(() => {
        if (origin && destination) {
            calculateRoute();
        }
    }, [origin, destination, calculateRoute]);

    return (
        <div>
            <div>
                <input
                    type="text"
                    className="colorwhite form-control"
                    value={origin}
                    onChange={(e) => handleInputChange(e, 'origin')}
                    placeholder="Enter origin"
                />
                {originSuggestions.map(item => (
                    <div className="suggestion colorwhite" key={item.id} onClick={() => selectAddress(item, 'origin')}>{item.title}</div>
                ))}
            </div>
            <div>
                <input
                    type="text"
                    className="colorwhite form-control"
                    value={destination}
                    onChange={(e) => handleInputChange(e, 'destination')}
                    placeholder="Enter destination"
                />
                {destinationSuggestions.map(item => (
                    <div className="suggestion colorwhite" key={item.id} onClick={() => selectAddress(item, 'destination')}>{item.title}</div>
                ))}
            </div>
            <div id="mapContainer" ref={mapRef} style={{ height: '400px', width: '100%' }}></div>
        </div>
    );
};
  
const AdvancedQuote = () => {
    const [quote, setQuote] = useState({
        Customer: '',
        QuoteNumber: '',
        Origin: '',
        Destination: '',
        Stops: '',
        Commodity: '',
        Length: '',
        Width: '',
        Height: '',
        Weight: '',
        Notes: '',
        RPM: '',
        Permits: '',
        Insurance: '',
        Miles: '',
        Escorts: '',
        FSC: '',
        WeightFee: '',
        LineHaul: '',
        Police: '',
        StopOff: '',
        FSCCPM: '',
        Survey: '',
        DHRate: '',
        Utility: '',
        CarrierPay: '',
        GrandTotal: '',
        Tarp: '',
        AddWeight: '',
        Deadhead: '',
        BasePrice: '',
    });

    useEffect(() => {
        console.log('State changed:', quote);
    }, [quote]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setQuote((prevQuote) => ({ ...prevQuote, [name]: value }));
    };

    return (
        <>
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
           <GoogleMapsAutocomplete />
            <form>
                <div className="form-group">
                    <label htmlFor="QuoteNumber" className="form-label">Quote #:</label>
                    <input type="text" name="QuoteNumber" value={quote.QuoteNumber} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Customer" className="form-label">Customer:</label>
                    <input type="text" name="Customer" value={quote.Customer} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Origin" className="form-label">Origin:</label>
                    <input type="text" name="Origin" value={quote.Origin} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Destination" className="form-label">Destination:</label>
                    <input type="text" name="Destination" value={quote.Destination} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Stops" className="form-label">Stops:</label>
                    <input type="text" name="Stops" value={quote.Stops} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Commodity" className="form-label">Commodity:</label>
                    <input type="text" name="Commodity" value={quote.Commodity} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Length" className="form-label">Length:</label>
                    <input type="text" name="Length" value={quote.Length} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Width" className="form-label">Width:</label>
                    <input type="text" name="Width" value={quote.Width} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Height" className="form-label">Height:</label>
                    <input type="text" name="Height" value={quote.Height} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Weight" className="form-label">Weight:</label>
                    <input type="text" name="Weight" value={quote.Weight} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="Notes" className="form-label">Notes:</label>
                    <input type="text" name="Notes" value={quote.Notes} onChange={handleInputChange} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="RPM" className="form-label">RPM:</label>
                    <input type="text" name="RPM" value={quote.RPM} onChange={handleInputChange} className="form-control" />
                </div>
                {/* Additional fields would follow the same pattern */}
            </form>
        </div>
        </>
    );
};

export default AdvancedQuote;