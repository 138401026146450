import React, { useState, useEffect } from 'react';
import { authFetch } from '../../auth';
import Select from 'react-select';

function NoteForm(props) {
    const [formData, setFormData] = useState({
        note_id: '',
        type: props.type,
        type_id: props.type_id,
        content: '',
        category: '', // new field for category
        tagged_contacts: [] // new field for tagged contacts
    });

    const categories = [
        { value: '', label: 'Select Category' },
        { value: 'neutral', label: 'Neutral' },
        { value: 'positive', label: 'Positive' },
        { value: 'negative', label: 'Negative' }
    ];

    const contactOptions = (props.contacts || []).map(contact => ({
        value: contact.id,
        label: `${contact.contact_name}`
    }));

    const selectStyles = {
        control: (base, state) => ({
            ...base,
            backgroundColor: '#31363F', // Background color when dropdown is active
            color: 'white',
            borderColor: state.isFocused ? '#007bff' : '#31363F', // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px #007bff' : base.boxShadow, // Change box-shadow when focused
            '&:hover': {
                borderColor: '#007bff', // Border color on hover
            },
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#31363F', // Background color of dropdown menu
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#0056b3' : state.isFocused ? '#007bff' : '#31363F', // Background color of options
            color: 'white', // Text color of options
            '&:hover': {
                backgroundColor: '#007bff', // Background color on hover
            },
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#31363F',
            border: '1px solid white',
            color: 'white',
        }),
        multiValueLabel: (base) => ({
            ...base,
            backgroundColor: '#31363F',
            
            color: 'white',
        }),
    };
    

    useEffect(() => {
        if (props.type_id) {
            setFormData(prevFormData => ({
                ...prevFormData,
                type_id: props.type_id
            }));
        }
    }, [props.type_id]);

    useEffect(() => {
        if (formData.note_id !== '') {
            props.addNote(formData);
        }
    }, [formData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await authFetch("https://fltbd.herokuapp.com/api/notes", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...formData,
                    // Send tagged_contacts as a list of objects without extra stringification
                    tagged_contacts: [formData.tagged_contacts]
                }),
            });
    
            if (response.ok) {
                const result = await response.json();
                console.log(result);
                // Update formData with result.note_id
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    note_id: result.note_id,
                    created_at: result.created_at,
                    category: result.category,
                    user: {
                        user_id: result.user_id,
                        first_name: result.first_name,
                        last_name: result.last_name,
                    },
                }));
                props.toggleShow();
    
                setFormData({
                    type: props.type,
                    type_id: props.type_id,
                    content: "",
                    category: "",
                    tagged_contacts: [],
                });
            } else {
                const errorText = await response.text();
                console.error("Failed to create contact:", response.status, errorText);
                alert("Failed to create contact: " + errorText);
            }
        } catch (error) {
            console.error("Failed to submit contact:", error);
            alert("Failed to submit contact due to exception: " + error);
        }
    };

    const handleChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }));
    };

    const handleSelectChange = (selectedOptions) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            tagged_contacts: selectedOptions
        }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <select
                className="form-control mt-2"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
            >
                {categories.map(category => (
                    <option key={category.value} value={category.value}>
                        {category.label}
                    </option>
                ))}
            </select>
            <textarea
                className="form-control mt-2"
                name="content"
                value={formData.content}
                onChange={handleChange}
                placeholder="Note Text Here"
                required
            />
            <Select
                className="form-control mt-2"
                isMulti
                name="tagged_contacts"
                options={contactOptions}
                value={formData.tagged_contacts}
                onChange={handleSelectChange}
                placeholder="Tag Contacts..."
                styles={selectStyles}
            />
            <button className="btn btn-azure float-right mb-5 mt-3" type="submit">Save Note</button>
        </form>
    );
}

export default NoteForm;