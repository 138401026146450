import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip,
    Modal,
    Button
} from "react-bootstrap";





const OnboardModal = (props) => {

    const initialState = () => {
        return (props)
    }
    const history = useHistory();

    const [state, setState] = useState(initialState);
    const [showModal, setShow] = useState(true);
    const [checkList, setChecklist] = useState([])



    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setState(props);
    }





    return (
        <>


            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                

            >

                <Modal.Header style={{  }} closeButton>
                    <Modal.Title className='colorwhite'>Set Up Check List</Modal.Title>
                </Modal.Header>

                <>
                    <Modal.Body class="modal-body" style={{  }}>
                        <p className='colorwhite'>You are almost set up... </p>
                        
                        {3 === 3 ? null : <> 
                            <br />
                        <div className="mb-3">
                            <h3 className='maincolorheader'>Mandatory:</h3>
                            {!props.stripe[0].stripe_subscription_id
                                ?
                                <div class="list-group-item">
                                    <div class="row">

                                        <div class="col ">
                                            <h1 className='colorwheat'><Link  to="/settings/subscription"><b>Start Free Trial</b></Link></h1>
                                            <div class="colorwhite mt-n1">Start your 15 day trial. No credit card required.</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="list-group-item" style={{ textDecoration: "line-Through" }}>
                                    <div class="row">
                                        <div class="col ">
                                            <Link  to="/settings/subscription" class="text-body d-block"><b>Start Free Trial</b></Link>
                                            <div class="text-muted  mt-n1">Start your 15 day trial. No credit card required. </div>
                                        </div>
                                    </div>
                                </div>
                            }




                        </div>
                        </>}
                        <h3 className='maincolorheader'>Optional:</h3>
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col ">
                                    <h2 className='colorwhite'>Load Settings</h2>
                                    <Link className="link-fix" to="/settings/load-number-settings"><b>Load Number</b></Link>
                                    <div class="colorwhite mt-n1">Change your starting load number</div>
                                </div>
                            </div>
                        </div>

                        <div class="list-group-item">
                            <div class="row">
                                <div class="col ">
                                    <h2 className='colorwhite'>Document Settings</h2>
                                    <div class="mt-n1 ">
                                        <Link  className="link-fix" to="/settings/confirmation-settings"><b>Rate Confirmation Terms & Conditions</b></Link>
                                        <br />
                                        <div class="colorwhite  mt-n1">Add your terms and conditions to your rate confirmation - this will be visible on every rate confirmation.</div>

                                        <br />
                                        <Link  className="link-fix" to="/settings/contract-settings"><b>Carrier Contract Agreement</b></Link>
                                        <br />
                                        <div class="colorwhite  mt-n1">Add your broker to carrier agreement terms - this will be visible on every carrier contract. </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="list-group-item">
                            <div class="row">
                                <div class="col ">
                                    <h2 className='colorwhite'>Add New:</h2>
                                    <div class="mt-n1 ">
                                        <Link  className="link-fix" to="/customers"><b>Customers</b></Link>
                                        <br />
                                        <Link  className="link-fix" to="/carriers"><b>Carriers</b></Link>
                                        <br />
                                        <Link  className="link-fix" to="/locations"><b>Locations</b></Link>
                                     

                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </Modal.Body>
                    <Modal.Footer style={{   }}>
                        <Button variant="primary" onClick={handleClose}>
                            Do It Later
                        </Button>

                    </Modal.Footer>
                </>

            </Modal>


        </>
    )
}

export default OnboardModal