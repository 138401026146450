import React, { useEffect, useState, PropTypes } from "react";
import {
  Route, Switch, Redirect, Router, useHistory
} from 'react-router-dom';
import ReportsHeader from "./ReportsHeader";
import DatePicker from "react-datepicker"
import ReportsTable from "./ReportsTable";
import Select, { createFilter, components } from 'react-select'
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";


export default function ReportsContainer(props) {

  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(true)
  const [loads, setLoads] = useState();
  const [state, setState] = useState({});
  const [filter, setFilter] = useState()
  const [customer, setCustomer] = useState()
  const [carrier, setCarrier] = useState()
  const [equipment, setEquipment] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [showPayColumns, setShowPayColumns] = useState(true);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const resetFilter = (e) => {
    setStartDate(null)
    setEndDate(null)
    setLoads(props.loads)
    setCustomer(null)
    setCarrier(null)
    setEquipment(null)
    setShowPayColumns(true)
  }

  useEffect(() => {
    if (props && props.loads) {
      setLoads(props.loads)
      setFilter(props.loads)
    }
  }, [props.loads])
  const equipmentList = [
    { value: 29999999971, label: 'ALL EQUIPMENT', "type": "equipment" },
    { value: 'F', label: 'F - Flatbed', "type": "equipment" },
    { value: 'SD', label: 'SD - Stepdeck', "type": "equipment" },
    { value: 'HS', label: 'HS - Hotshot', "type": "equipment" },
    { value: 'RGN - Removeable Gooseneck', label: 'RGN - Removeable Gooseneck', "type": "equipment" },
    { value: 'DD - Double Drop', label: 'DD - Double Drop', "type": "equipment" },
    { value: 'DBL - Doubles', label: 'DBL - Doubles', "type": "equipment" },
    { value: 'Other', label: 'Other', "type": "equipment" }

  ]
  useEffect(() => {

    if (props && props.customers && props.carriers) {
      const customers = props.customers.map(d => ({
        "value": d.id,
        "label": d.customer_name,
        "customer_id": d.customer_id,
        "type": "customer"
      }))
      const sortedCustomers = customers.sort((a, b) => a.label > b.label ? 1 : -1)
      const addAllCustomers = [{ type: "customer", "value": 39999999971, label: "ALL CUSTOMERS" }, ...sortedCustomers]

      const carriers = props.carriers.map(d => ({
        "value": d.id,
        "label": d.carrier_name,
        "carrier_mc": d.carrier_mc,
        "carrier_dot": d.carrier_dot,
        "carrier_address": d.physical_address,
        "do_not_load": d.do_not_load,
        "type": "carrier"
      }))

      const sortedCarriers = carriers.sort((a, b) => a.label > b.label ? 1 : -1)

      const addAllCarriers = [{ type: "carrier", "value": 29999999971, label: "ALL CARRIERS" }, ...sortedCarriers]

      setState({ ...state, customers: addAllCustomers, carriers: addAllCarriers })
      setShow(false)
      setLoading(false)
    }
  }, [props.customers, props.carriers])


  const onChange = (e) => {
    if (e.type === "carrier") {
      setCarrier(e)
    }
    if (e.type === "customer") {
      setCustomer(e)
    }
    if (e.type === "equipment") {
      setEquipment(e)
      console.log(e)
    }

  }

  const filterCarrier = (data) => {
    return data.filter((load) => load.carrier_name.includes(carrier.label))
  }
  const filterCustomer = (data) => {
    return data.filter((load) => load.customer_name === customer.label)
  }
  const filterEquipment = (data) => {
    return data.filter((load) => load.trailer.includes(equipment.value))
  }
  const filterDate = (data) => {
    const test = data.filter((load) => {
      if (load.actions.length > 0) {
        let checkAction = moment(load.actions[0].date).format("L")
        let checkStart = moment(startDate).format("L")
        let checkEnd = moment(endDate).format("L")

        var d1 = checkStart.split("/");
        var d2 = checkEnd.split("/");
        var c = checkAction.split("/");

        var from = new Date(d1);  // -1 because months are from 0 to 11
        var to = new Date(d2);
        var check = new Date(c);

        return (check > from && check < to);
      }
    })
    return test
  }

  useEffect(() => {

    if (loads) {
      let result = loads
      // Filter by carrier
      if (carrier) {
        if (carrier.label !== "ALL CARRIERS") {
          result = filterCarrier(result)
        }
      }
      // Filter by customer
      if (customer) {
        if (customer.label !== "ALL CUSTOMERS") {
          result = filterCustomer(result)
        }
      }
      // Filter by Equipment Type(trailers, but can be sprinter van, etc)
      if (equipment) {
        if (equipment.label !== "ALL EQUIPMENT") {
          result = filterEquipment(result)
        }
      }
      // Filter action dates between startDate & endDate
      if (startDate && endDate) {
        result = filterDate(result)
      }


      setFilter(result)
    }

  }, [loads, carrier, customer, equipment, startDate, endDate]);

  const Option = ({ children, ...props }) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = Object.assign(props, { innerProps: rest });
    return (
      <components.Option
        {...newProps}
        className="custom-option"
      >
        {children}
      </components.Option>
    );
  };


  return (
    <>
      <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
        <ReportsHeader />
        <div className="row">
          <div className="col-lg-3">
            <div class="card" style={{}}>
              <div className="card-header">
                <h3 className="card-title">Filters</h3>
              </div>
              <div class="card-body">
              <div class="col-12 mt-2">
                  <div class="form-label">Show Pay Columns</div>
                  <div>
                    <label className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={showPayColumns}
                        onChange={() => setShowPayColumns(true)}
                      />
                      <span className="form-check-label font-color-white">Show</span>
                    </label>
                    <label className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={!showPayColumns}
                        onChange={() => setShowPayColumns(false)}
                      />
                      <span className="form-check-label font-color-white">Hide</span>
                    </label>
                  </div>
                </div>

                <div className="">
                  <div className="">
                    <div class="col-12">
                      <div class="form-label">Customer</div>
                      <Select
                        options={state.customers} value={customer} name="customer_name" onChange={(e) => onChange(e)} />
                    </div>
                    <div class="col-12 mt-2">
                      <div class="form-label">Carrier</div>
                      <Select
                        components={{ Option }}
                        classNamePrefix="custom-select"
                        options={state.carriers} value={carrier} name="carrier_name" onChange={(e) => onChange(e)} />
                    </div>
                    <div class="col-12 mt-2">
                      <div class="form-label">Equipment Type</div>
                      <Select options={equipmentList} value={equipment} name="equipment" onChange={(e) => onChange(e)} />

                    </div>
                    <div class="col-12 mt-2">
                      <div class="form-label">Start Date</div>
                      <DatePicker
                        className="form-control"
                        selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div class="col-12 mt-2">
                      <div class="form-label">End Date</div>
                      <DatePicker
                        className="form-control"
                        selected={endDate} onChange={(date) => setEndDate(date)} />
                    </div>
                  </div>
                </div>
                <button className="btn btn-danger float-right mt-3" onClick={resetFilter}>Reset</button>
              </div>
            </div>
          </div>

          <div className="col-lg-9">

            <ReportsTable data={filter} showPayColumns={showPayColumns}/>

          </div>
        </div>
      </div>
      {loading && (
            <Modal show={show} size="sm" centered>
                <LoadingModal />
            </Modal>
        )}
    </>
  )

}
