import React, { useState } from 'react'
import { authFetch } from '../../auth'
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal'

function ContactList(props) {
  const { contacts } = props
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedContactId, setSelectedContactId] = useState(null)
  const [show, setShow] = useState(false)

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase())
  }

  const filteredContacts = contacts.filter(contact => {
    return (
      contact.contact_name.toLowerCase().includes(searchTerm) ||
      contact.email.toLowerCase().includes(searchTerm) ||
      (contact.title && contact.title.toLowerCase().includes(searchTerm))
    )
  })

  const handleRemoveContact = async () => {
    const response = await authFetch(`https://fltbd.herokuapp.com/api/contact/${selectedContactId}`, {
      method: 'DELETE'
    });

    const responseData = await response.json()

    if (response.status === 200) {
      props.removeContact(selectedContactId)
      setShow(false)
    } else {
      console.error(responseData.message)
    }
    setSelectedContactId(null)
  }

  const handleShow = () => setShow(true)

  if (contacts.length === 0 && !props.showForm) {
    return (
      <div>
        <p className="colorwhite">You don't have any contacts for this {props.type}.</p>
      </div>
    )
  }

  return (
    <div>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Search by name, email, or title"
        value={searchTerm}
        onChange={handleSearchChange}
      />

<div className="list-group list-group-flush list-group-hoverable">
  {filteredContacts.map((contact, index) => ( 
    <div key={`${contact.id}-${index}`} className="list-group-item"> 
      <div className="row align-items-center"> 
        
        <div className="col colorwhite"> 
          <a href="#" className="text-reset d-block"> 
            {contact.contact_name} 
            {contact.title && ` - ${contact.title}`}
          </a> 
          <div className="d-block text-secondary text-truncate mt-n1">{contact.email}</div> 
        </div> 
        <div className="col-auto"> 
        
          <a href="#" className="list-group-item-actions">
          <button className="btn btn-danger" type="button" onClick={() => { setSelectedContactId(contact.contact_id); handleShow() }}> 
            Remove 
          </button>           </a>
        </div> 
      </div> 
    </div>
  ))}
</div>
      

      {/* Delete Confirmation Modal */}
      {show && (
        <DeleteConfirmationModal
          title="Confirm Delete"
          message="Are you sure you want to delete?"
          onConfirm={handleRemoveContact}
          setShow={setShow}
          show={show}
        />
      )}
    </div>
  )
}

export default ContactList
